import { ContainerBorder } from '../../../../../screens/ProjectsList/components/ClientProjects/styles';
import SectionHeader from '../../../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import ClientUploadBoxContent from './ClientUploadBoxContent';

const ClientUploadBox = ({ anonUpload = false }: { anonUpload?: boolean }) => {
	return (
		<ContainerBorder
			style={{
				...(ContainerBorderStyles as any),
			}}>
			<SectionHeader>Upload New Files</SectionHeader>
			<ClientUploadBoxContent anonUpload={anonUpload} />
		</ContainerBorder>
	);
};

const ContainerBorderStyles = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
};

export default ClientUploadBox;
