import { useState, useContext, useEffect } from 'react';
import { Container } from '../components/DeliverableDropzone';
import { ProjectContext } from '../../../../context/ProjectProvider';
import styled from 'styled-components';
import Carousel from '../../Carousel';
import { endsWith } from 'lodash';
import { CarouselIframe } from '../../Carousel/Styled';

const playStr = '&play=1';

export default function MatterportBox({ downloadOnly }: { downloadOnly: boolean }) {
	const { project } = useContext(ProjectContext);

	const [validVrLinks, setValidVrLinks] = useState<string[]>([]);

	useEffect(() => {
		async function getValidLinks(): Promise<void> {
			if (project?.matterport?.length) {
				let arr: string[] = [];
				for (const vrlink of project.matterport) {
					// if (
					// 	vrlink.startsWith('https://my.matterport.com/show/') ||
					// 	vrlink.startsWith('https://vr.roboticimaging.ai/show/') ||
					// 	vrlink.startsWith('https://www.dronedeploy.com/')
					// ) {
					if (!endsWith(vrlink, playStr)) arr.push(vrlink + playStr);
					else arr.push(vrlink);
					// }
				}
				setValidVrLinks(arr);
			} else {
				setValidVrLinks([]);
			}
		}
		getValidLinks();
	}, [project?.matterport]);

	return downloadOnly && !validVrLinks.length ? null : (
		<Container>
			{validVrLinks.length ? (
				<MatterportContainer id="deliverable-matterport">
					<Carousel lazy>
						{validVrLinks.map((vrLink: string, i: number) => (
							<CarouselIframe
								title="virtual-walkthrough"
								src={vrLink}
								key={i}
								allow="xr-spatial-tracking"
								allowFullScreen
							/>
						))}
					</Carousel>
				</MatterportContainer>
			) : (
				<span>No VR Links to Display</span>
			)}
		</Container>
	);
}

const MatterportContainer = styled.div`
	width: 100%;
	height: 100%;
`;
