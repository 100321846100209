import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ClickableSpan from '../../../../../ClickableSpan';
import MultiDateTimeInput from '../../../../../field-components/MultiDateTimeInput';
import { isEqual } from 'lodash';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function MultiDateTimeCell({
	row,
	rowEditing,
	field,
	title,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
	field: 'captureTimestamps';
	title: string;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const project = row.original;
	const projectId = project.id as string;
	const dates = project[field] || [];
	const displayStr =
		dates.map(ts => dayjs(ts).tz(dayjs.tz.guess()).format('MM/DD/YYYY')).join(', ') ||
		'-';

	const [datesArr, setDatesArr] = useState(dates);
	const [showDialog, setShowDialog] = useState(false);

	const handleOpen = () => {
		setShowDialog(true);
		setDatesArr(dates);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleSubmit = async () => {
		handleClose();
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Saving changes...',
			hideDuration: null,
		});

		// Update state.
		const newData = datesArr.length
			? datesArr.map(date => dayjs(date).hour(12).minute(0).second(0).toDate())
			: undefined;
		setStatusProjects(prev => {
			return prev.map(proj => {
				if (proj.id !== projectId) return proj;
				else {
					return {
						...proj,
						[field]: newData,
					};
				}
			});
		});

		// Update firestore document.
		await updateSingleProjectField(projectId, newData, field);

		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});
	};

	return !rowEditing ? (
		<span>{displayStr}</span>
	) : (
		<>
			<ClickableSpan onClick={handleOpen}>{displayStr}</ClickableSpan>

			<Dialog
				open={showDialog}
				onClose={handleClose}
				onClick={event => event.stopPropagation()}>
				<DialogTitle>{`Editing ${title}`}</DialogTitle>

				<DialogContent>
					<MultiDateTimeInput dates={datesArr} setDates={setDatesArr} />
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>

					{!isEqual(dates, datesArr) ? (
						<Button onClick={handleSubmit}>Submit</Button>
					) : null}
				</DialogActions>
			</Dialog>
		</>
	);
}
