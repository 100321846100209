import { styled } from '@mui/system';
import { FC, useState } from 'react';
import theme from '../../../styles/theme';
import { BlockMenuButtonOptions, BlockMenuButtonProps } from './BlockMenuButton.types';
import { Divider, Menu, MenuItem } from '@mui/material';
import MenuButton from '../MenuButton/MenuButton';
import MenuItemContent from '../MenuItem/MenuItem';
import { ChevronRight, ContentCut, ListAlt } from '@mui/icons-material';

const BlockMenuButton: FC<BlockMenuButtonProps> = ({
	disable,
	onChange,
	small = false,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleItemClick = (item: BlockMenuButtonOptions) => {
		if (!disable?.includes(item)) {
			onChange?.(item);
			handleClose();
		}
	};

	return (
		<>
			<MenuButton onClick={handleClick} open={open} small={small}>
				Add block
			</MenuButton>

			<CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<CustomMenuItem
					onClick={() => handleItemClick('section')}
					disabled={disable?.includes('section')}>
					<MenuItemContent
						title="Section"
						description="Add sections to organize form fields. Sections appear as navigation items in the form outline."
						startIcon={<ListAlt />}
						endIcon={<ChevronRight />}
					/>
				</CustomMenuItem>
				<Divider />
				<CustomMenuItem
					onClick={() => handleItemClick('input')}
					disabled={disable?.includes('input')}>
					<MenuItemContent
						title="Input"
						description="Add form inputs to collect information. Inputs can only be added inside of a form section."
						startIcon={<ContentCut />}
						endIcon={<ChevronRight />}
					/>
				</CustomMenuItem>
			</CustomMenu>
		</>
	);
};

const CustomMenu = styled(Menu)`
	top: 8px;

	.MuiPaper-root {
		background-color: ${theme.palette.secondary.main};
	}
`;

const CustomMenuItem = styled(MenuItem)`
	padding: 0;
	white-space: normal;
`;

export default BlockMenuButton;
