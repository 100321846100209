import {
	DropAnimation,
	MeasuringStrategy,
	Modifier,
	defaultDropAnimation,
	type UniqueIdentifier,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FlattenedItem, TreeItem } from './types';
import { FormNode } from '../../../ProjectUtilityFormV2/utils/types';

function sortSiblingFormNodes(formNodes: FormNode[]) {
	return formNodes.sort((a, b) => (a.order || 0) - (b.order || 0));
}

function buildTreeItem(
	formNode: FormNode,
	formNodesByParentId: Record<string, FormNode[]>
): TreeItem {
	const formNodeChildren = sortSiblingFormNodes(formNodesByParentId[formNode.id] || []);
	const treeItemChildren: TreeItem[] = [];
	for (const child of formNodeChildren) {
		treeItemChildren.push(buildTreeItem(child, formNodesByParentId));
	}
	return {
		id: formNode.id,
		children: treeItemChildren,
		node: formNode,
	};
}

export function formNodesToTreeItems(formNodes: FormNode[]): TreeItem[] {
	const formNodesByParentId: Record<string, FormNode[]> = {};
	for (const formNode of formNodes) {
		if (!formNode.parentId) {
			formNodesByParentId['root'] = [...(formNodesByParentId['root'] || []), formNode];
		} else {
			formNodesByParentId[formNode.parentId] = [
				...(formNodesByParentId[formNode.parentId] || []),
				formNode,
			];
		}
	}
	const orderedRootNodes = sortSiblingFormNodes(formNodesByParentId['root'] || []);
	const treeItems = orderedRootNodes.map(formNode =>
		buildTreeItem(formNode, formNodesByParentId)
	);
	return treeItems;
}

function getDragDepth(offset: number, indentationWidth: number) {
	return Math.round(offset / indentationWidth);
}

export function getProjection(
	items: FlattenedItem[],
	activeId: UniqueIdentifier,
	overId: UniqueIdentifier,
	dragOffset: number,
	indentationWidth: number
) {
	// return { depth: 0, maxDepth: 0, minDepth: 0, parentId: null };
	const overItemIndex = items.findIndex(({ id }) => id === overId);
	const activeItemIndex = items.findIndex(({ id }) => id === activeId);
	const activeItem = items[activeItemIndex];
	const newItems = arrayMove(items, activeItemIndex, overItemIndex);
	const previousItem = newItems[overItemIndex - 1];
	const nextItem = newItems[overItemIndex + 1];
	const dragDepth = getDragDepth(dragOffset, indentationWidth);
	const projectedDepth = activeItem.depth + dragDepth;
	const maxDepth = getMaxDepth({
		previousItem,
	});
	const minDepth = getMinDepth({ nextItem });
	let depth = projectedDepth;

	if (projectedDepth >= maxDepth) {
		depth = maxDepth;
	} else if (projectedDepth < minDepth) {
		depth = minDepth;
	}

	return { depth, maxDepth, minDepth, parentId: getParentId() };

	function getParentId() {
		if (depth === 0 || !previousItem) {
			return null;
		}

		if (depth === previousItem.depth) {
			return previousItem.parentId;
		}

		if (depth > previousItem.depth) {
			return previousItem.id;
		}

		const newParent = newItems
			.slice(0, overItemIndex)
			.reverse()
			.find(item => item.depth === depth)?.parentId;

		return newParent ?? null;
	}
}

function getMaxDepth({ previousItem }: { previousItem: FlattenedItem }) {
	if (previousItem) {
		return previousItem.depth + 1;
	}

	return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedItem }) {
	if (nextItem) {
		return nextItem.depth;
	}

	return 0;
}

export function flattenTree(
	items: TreeItem[],
	parentId: UniqueIdentifier | null = null,
	depth = 0
): FlattenedItem[] {
	return items.reduce<FlattenedItem[]>((acc, item, index) => {
		return [
			...acc,
			{ ...item, parentId, depth, index },
			...flattenTree(item.children, item.id, depth + 1),
		];
	}, []);
}

export function buildTree(flattenedItems: FlattenedItem[]): TreeItem[] {
	const rootTreeItems: TreeItem[] = [];
	const nodesById: Record<string, TreeItem> = {};
	const items = flattenedItems.map(item => ({ ...item, children: [] }));

	for (const item of items) {
		const parentId = item.node.parentId;
		const parent = nodesById[parentId] ?? findItem(items, parentId);

		nodesById[item.id] = {
			id: item.id,
			children: item.children,
			node: { ...item.node, level: item.depth + 1 },
		};
		if (parent) {
			parent.children.push(item);
		} else {
			rootTreeItems.push(item);
		}
	}

	return rootTreeItems;

	// const root: TreeItem = { id: 'root', children: [] };
	// const nodes: Record<string, TreeItem> = { [root.id]: root };
	// const items = flattenedItems.map(item => ({ ...item, children: [] }));

	// for (const item of items) {
	// 	const { id, children, node } = item;
	// 	const parentId = item.parentId ?? root.id;
	// 	const parent = nodes[parentId] ?? findItem(items, parentId);

	// 	nodes[id] = { id, children, node };
	// 	parent.children.push(item);
	// }

	// return root.children;
}

export function findItem(items: TreeItem[], itemId: UniqueIdentifier) {
	return items.find(({ id }) => id === itemId);
}

export function findItemDeep(
	items: TreeItem[],
	itemId: UniqueIdentifier
): TreeItem | undefined {
	for (const item of items) {
		const { id, children } = item;

		if (id === itemId) {
			return item;
		}

		if (children.length) {
			const child = findItemDeep(children, itemId);

			if (child) {
				return child;
			}
		}
	}

	return undefined;
}

export function removeItem(items: TreeItem[], id: UniqueIdentifier) {
	const newItems = [];

	for (const item of items) {
		if (item.id === id) {
			continue;
		}

		if (item.children.length) {
			item.children = removeItem(item.children, id);
		}

		newItems.push(item);
	}

	return newItems;
}

export function setProperty<T extends keyof TreeItem>(
	items: TreeItem[],
	id: UniqueIdentifier,
	property: T,
	setter: (value: TreeItem[T]) => TreeItem[T]
) {
	for (const item of items) {
		if (item.id === id) {
			item[property] = setter(item[property]);
			continue;
		}

		if (item.children.length) {
			item.children = setProperty(item.children, id, property, setter);
		}
	}

	return [...items];
}

function countChildren(items: TreeItem[], count = 0): number {
	return items.reduce((acc, { children }) => {
		if (children.length) {
			return countChildren(children, acc + 1);
		}

		return acc + 1;
	}, count);
}

export function getChildCount(items: TreeItem[], id: UniqueIdentifier) {
	const item = findItemDeep(items, id);

	return item ? countChildren(item.children) : 0;
}

export function removeChildrenOf(items: FlattenedItem[], ids: UniqueIdentifier[]) {
	const excludeParentIds = [...ids];

	return items.filter(item => {
		if (item.parentId && excludeParentIds.includes(item.parentId)) {
			if (item.children.length) {
				excludeParentIds.push(item.id);
			}
			return false;
		}

		return true;
	});
}

export const measuring = {
	droppable: {
		strategy: MeasuringStrategy.Always,
	},
};

export const dropAnimationConfig: DropAnimation = {
	keyframes({ transform }) {
		return [
			{ opacity: 1, transform: CSS.Transform.toString(transform.initial) },
			{
				opacity: 0,
				transform: CSS.Transform.toString({
					...transform.final,
					x: transform.final.x + 5,
					y: transform.final.y + 5,
				}),
			},
		];
	},
	easing: 'ease-out',
	sideEffects({ active }) {
		active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
			duration: defaultDropAnimation.duration,
			easing: defaultDropAnimation.easing,
		});
	},
};

export const adjustTranslate: Modifier = ({ transform }) => {
	return {
		...transform,
		y: transform.y - 25,
	};
};
