import styled from 'styled-components';
import carbonFiber from './Carbon-Fiber-Optimized.png';
import { CheckCircle } from '@mui/icons-material';

interface TeamCardContainerProps {
	isSelected?: boolean;
	inSelectionMode?: boolean;
	isCondensed?: boolean;
}

export const TeamCardContainer = styled.div<TeamCardContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	border-radius: 30px;
	background-image: url(${carbonFiber});
	background-size: 100%;
	padding: 20px;
	box-shadow: inset 5px 5px 10px -5px rgba(89, 89, 89, 0.6),
		inset -5px 0px 10px -5px rgba(89, 89, 89, 0.6);
	transition: 0.1s;
	border: ${({ isSelected }) => (isSelected ? '2px solid #ffb310' : '1px solid #323639')};
	min-height: 170px;
	width: 280px;
	min-width: 250px;
	margin: auto;
	will-change: transform;
	overflow: hidden;

	&:hover {
		transform: scale(1.05);
		background-size: 102%;
		box-shadow: inset 5px 5px 15px -5px rgba(89, 89, 89, 0.7),
			inset -5px 0px 15px -5px rgba(89, 89, 89, 0.7);
		cursor: pointer;
	}

	${({ inSelectionMode }) =>
		inSelectionMode &&
		`&:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.2); // Dimming effect
                z-index: 1;
            }`}
`;

interface Props {
	isCondensed?: boolean;
}

export const TeamName = styled.h3<Props>`
	margin: 0;
	color: white;
	text-align: left;
	font-weight: normal;
	font-size: 20px;
	line-height: 25px;
`;

export const TeamInfo = styled.div<Props>`
	display: flex;
	margin: 5px 0;
	color: white;
	gap: 5px;
	font-size: 15px;
`;

export const AdminChip = styled.div<Props>`
	align-self: flex-end;
	bottom: 2px;
	right: 25px;
	background-color: white;
	padding: 5px 10px;
	border-radius: 50px;
	font-size: 12px;
	justify-content: flex-end;
`;

export const StyledCheck = styled(CheckCircle)`
	position: 'absolute';
	color: '#ffb310';
	font-size: '40px';
	z-index: 2;
	top: '50%';
	left: '50%';
	transform: 'translate(-50%, -50%)';
`;

export const CondensedTeamCardContainer = styled(TeamCardContainer)`
	flex-direction: row;
	min-height: 100px;
	height: 100px;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
`;

export const CondensedTeamName = styled.h3<Props>`
	margin: 0;
	color: white;
	text-align: left;
	font-weight: normal;
	font-size: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
`;

export const CondensedOrgName = styled.h3<Props>`
	margin: 0;
	color: #ffb310;
	text-align: left;
	font-weight: bold;
	font-size: 11px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
`;

export const CondensedInfo = styled.div<Props>`
	color: white;
	font-size: 12px;
`;

export const StatsRow = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
`;

export const Group = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
`;
