import { FieldLabel } from '../../../../../screens/ProjectInfo';
import LinkWithCopyButton from '../../../../reusable-components/LinkWithCopyButton';
import { ProjectData } from '../../../../../types';
import { getAnonDownloadLink } from '../../../../../utils';

export default function DownloadLink({ project }: { project: ProjectData }) {
	const downloadLink = getAnonDownloadLink(project);

	return (
		<div className="infoRow">
			<FieldLabel label={'Download Link'} />
			<LinkWithCopyButton link={downloadLink || ''} title={'Public download'} />
		</div>
	);
}
