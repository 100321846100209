import { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../../context/AuthProvider';
import { PendingUser, SharedUser } from '../../types';
import { Avatar, IconButton } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ListItemText from '@mui/material/ListItemText';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import { pendingProjectShareReminder } from '../../../../../../../firebase';
import { memberDelete } from '../../../../../../../firebase/firestore/projects';
import { UserRowContainer, UserRow } from './styled-components';

interface SharedUsersProps {
	sharedUsers: SharedUser[];
	pendingUsers: PendingUser[];
	getSetMembers: () => Promise<void>;
}

export default function SharedUsers({
	sharedUsers,
	pendingUsers,
	getSetMembers,
}: SharedUsersProps) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const [disableActions, setDisableActions] = useState(false);

	const handleReminder = async (pendingUser: PendingUser) => {
		setDisableActions(true);
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: `Sending reminder email to ${pendingUser.email}...`,
			hideDuration: null,
		});

		try {
			await pendingProjectShareReminder({ email: pendingUser.email });
			await getSetMembers();
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: 'Successfully sent reminder email!',
			});
		} catch (err) {
			console.error(err);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Error sending reminder email to ${pendingUser.email}...`,
			});
		}
		setDisableActions(false);
	};

	const handleDelete = async (sharedUser: SharedUser) => {
		if (project) {
			setDisableActions(true);
			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: `Removing ${sharedUser.email} from this project...`,
				hideDuration: null,
			});

			try {
				await memberDelete(sharedUser.email, project.id as string);
				await getSetMembers();
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `${sharedUser.email} has been removed from this project!`,
				});
			} catch (err) {
				console.error(err);
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Error removing ${sharedUser.email} from this project...`,
				});
			}
			setDisableActions(false);
		}
	};

	return (
		<UserRowContainer isContractor={user?.isContractor}>
			{sharedUsers.length + pendingUsers.length < 1 ? (
				<h2>No shared users yet...</h2>
			) : (
				<>
					{pendingUsers.map(pendingUser => {
						const lastSharedDate = pendingUser.lastShared
							? new Date(pendingUser.lastShared._seconds * 1000)
							: null;
						return (
							<UserRow key={pendingUser.email}>
								<Avatar>
									<AutorenewIcon />
								</Avatar>

								<ListItemText
									primary={pendingUser.email}
									secondary={`Pending${
										lastSharedDate
											? ` - Last shared on ${lastSharedDate.toLocaleDateString()}`
											: null
									}`}
								/>

								<IconButton
									title={`Send reminder email to ${pendingUser.email}`}
									onClick={() => handleReminder(pendingUser)}
									disabled={disableActions}
									size="large">
									<NotificationsActiveIcon />
								</IconButton>
							</UserRow>
						);
					})}
					{sharedUsers.map(sharedUser => (
						<UserRow key={sharedUser.email}>
							<Avatar>
								<PersonIcon />
							</Avatar>

							<ListItemText primary={sharedUser.email} secondary={sharedUser.userType} />

							{user?.isAdmin ? (
								<IconButton
									title={`Remove ${sharedUser.email} from this project`}
									onClick={() => handleDelete(sharedUser)}
									disabled={disableActions}
									size="large">
									<DeleteIcon />
								</IconButton>
							) : null}
						</UserRow>
					))}
				</>
			)}
		</UserRowContainer>
	);
}
