import { useState, useEffect, useContext, useCallback } from 'react';
import { Team, TimeValue } from '../types';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { ButtonsContainer } from '../components/styled-components/buttons';
import { checkPermissions } from '../utils';
import { AuthContext } from '../context/AuthProvider';
import { OrganizationsContext } from '../context/OrganizationsProvider';
import { uploadLogo } from '../utils/organization-file-upload';
import { getOrgAdminStatus } from '../firebase';
import { SnackContext } from '../context/SnackProvider';
import { Sidebar } from '../components/reusable-components/Sidebar';
import { MobileDropdown } from '../components/reusable-components/MobileDropdown';
import Layout from '../components/reusable-components/Layout';
import Name from '../components/screen-components/OrganizationPage/field-components/Name';
import DeleteTeamDialog from '../components/screen-components/OrganizationPage/DeleteTeamDialog';
import AddTeamDialog from '../components/screen-components/OrganizationPage/AddTeamDialog';
import styled from 'styled-components';
import AdminNotes from '../components/screen-components/OrganizationPage/field-components/AdminNotes';
import OrgNotes from '../components/screen-components/OrganizationPage/field-components/OrgNotes';
import TeamGrid from '../components/screen-components/OrganizationPage/grid-components/TeamGrid';
import TeamSearchBar from '../components/screen-components/OrganizationPage/TeamsSearchbar';
import LogoContainer from '../components/reusable-components/LogoContainer/LogoContainer';
import SkeletonGrid from '../components/screen-components/OrganizationPage/grid-components/SkeletonGrid';
import PaymentTerms from '../components/screen-components/OrganizationPage/field-components/PaymentTerms';
import HostingTerms from '../components/screen-components/OrganizationPage/field-components/HostingTerms';

export default function OrganizationPage() {
	const { organizationId }: any = useParams();
	const {
		loadOrganization,
		loadingOrganization,
		organization,
		setOrganization,
		updateOrganization,
		updatingOrganization,
		updateLogoUrl,
	} = useContext(OrganizationsContext);
	const { teams = [] } = useContext(OrganizationsContext);
	const [openTeamDialog, setOpenTeamDialog] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [teamsToBeDeleted, setTeamsToBeDeleted] = useState<Team[] | undefined>();
	const [searchQuery, setSearchQuery] = useState('');
	const { user, firebaseAuthData } = useContext(AuthContext);
	const isAdmin = checkPermissions(user, 'admin');
	const [adminNotes, setAdminNotes] = useState('');
	const [orgNotes, setOrgNotes] = useState('');
	const [paymentTerms, setPaymentTerms] = useState<number | undefined>(
		organization?.paymentTerms
	);
	const [hostingTerms, setHostingTerms] = useState<TimeValue | undefined>(
		organization?.hostingTerms
	);
	const [isOrgAdmin, setIsOrgAdmin] = useState(false);
	const defaultTitle = 'Loading Organization...';
	const [isSelectionMode, setIsSelectionMode] = useState(false);
	const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
	const showButton = isAdmin || isOrgAdmin;
	const { setSnackbarProps } = useContext(SnackContext);

	const checkOrgAdminStatus = useCallback(async () => {
		if (user && organizationId) {
			try {
				const response = await getOrgAdminStatus({
					userId: firebaseAuthData?.uid,
					organizationId,
				});
				const isAdmin = (response.data as any).isOrgAdmin;
				setIsOrgAdmin(isAdmin);
				console.log(isAdmin);
			} catch (error) {
				console.error('Error fetching organization admin status:', error);
				setIsOrgAdmin(false);
			}
		}
	}, [user, organizationId, firebaseAuthData?.uid]);

	const userIsTeamMember = (teamId: string) => {
		if (firebaseAuthData) {
			const team = teams.find(t => t.id === teamId);
			return team?.userIds?.includes(firebaseAuthData?.uid) ?? false;
		} else {
			return false;
		}
	};

	const handleUnauthorizedAccess = () => {
		setSnackbarProps({
			open: true,
			message: `Permission denied. Contact your organization's admin to obtain access to this team.`,
			severity: 'warning',
			hideDuration: null,
		});
	};

	const toggleSelectionMode = () => {
		setIsSelectionMode(!isSelectionMode);
		setSelectedTeams([]);
	};

	const handleTeamSelect = (teamId: string) => {
		setSelectedTeams(prevSelectedTeams => {
			const newSelectedTeams = prevSelectedTeams.includes(teamId)
				? prevSelectedTeams.filter(id => id !== teamId)
				: [...prevSelectedTeams, teamId];

			console.log('Selected Teams:', newSelectedTeams);
			return newSelectedTeams;
		});
	};

	const handleDeleteSelected = async () => {
		if (selectedTeams.length > 0) {
			const teamsToDelete = teams.filter(team => selectedTeams.includes(team.id));
			setTeamsToBeDeleted(teamsToDelete);
		}
	};

	useEffect(() => {
		checkOrgAdminStatus();
	}, [user, organizationId, checkOrgAdminStatus]);

	const filteredTeams =
		teams?.filter(team => team.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
		[];

	useEffect(() => {
		if (organizationId) {
			loadOrganization(organizationId);
		}
	}, [loadOrganization, organizationId]);

	useEffect(() => {
		if (organization) {
			setAdminNotes(organization.adminNotes || '');
			setOrgNotes(organization.orgNotes || '');
			setPaymentTerms(organization.paymentTerms);
			setHostingTerms(organization.hostingTerms);
		}
	}, [organization]);

	useEffect(() => {
		if (organization && organization.name) {
			document.title = `${organization.name} | Robotic Imaging`;
		} else {
			document.title = defaultTitle;
		}
	}, [organization]);

	const handleLogoUpload = useCallback(
		async (files: File[]) => {
			if (files.length > 0) {
				try {
					// Pass the old logo if it exists
					const oldLogo = organization?.logo?.[0];
					const downloadUrl = await uploadLogo(
						files[0],
						organization?.id as string,
						setOrganization,
						oldLogo
					);
					updateLogoUrl(downloadUrl);
				} catch (error) {
					console.error('Error uploading logo', error);
				}
			}
		},
		[organization, setOrganization, updateLogoUrl]
	);

	const handleSubmit = async () => {
		setIsActive(false);
		if (organization) {
			const updatedOrganization = {
				...organization,
				adminNotes,
				orgNotes,
				paymentTerms,
				hostingTerms,
			};
			await updateOrganization(updatedOrganization);
			setSnackbarProps({
				open: true,
				message: `Success! Organization has been updated.`,
				severity: 'success',
				hideDuration: 5000,
			});
		}
	};
	// handles admin notes change
	const handleAdminNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAdminNotes(e.target.value);
	};

	const handleOrgNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setOrgNotes(e.target.value);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		if (organization && name && value)
			setOrganization({ ...organization, [name]: value });
	};

	const sidebarContent = (
		<>
			<Name handleChange={handleChange} isActive={isActive} />
			<OrgNotes handleChange={handleOrgNotesChange} isActive={isActive} />
			{isAdmin && (
				<AdminNotes handleChange={handleAdminNotesChange} isActive={isActive} />
			)}
			<PaymentTerms
				isActive={isActive}
				handleChange={setPaymentTerms}
				paymentTerm={paymentTerms}
			/>
			<HostingTerms
				isActive={isActive}
				handleChange={setHostingTerms}
				timeValue={hostingTerms}
			/>

			{updatingOrganization ? (
				<LoadingContainer>
					<CircularProgress />
				</LoadingContainer>
			) : (
				<ButtonsContainer>
					<Button
						className="button"
						type="submit"
						variant="contained"
						onClick={() => setIsActive(prev => !prev)}>
						{!isActive ? 'Edit' : 'Cancel'}
					</Button>
					{isActive ? (
						<Button
							className="button"
							type="submit"
							variant="contained"
							onClick={handleSubmit}>
							Submit
						</Button>
					) : null}
				</ButtonsContainer>
			)}
		</>
	);

	return (
		<Layout title={organization?.name ? `${organization.name}` : defaultTitle}>
			{!loadingOrganization && teams ? (
				<>
					<OrganizationPageContainer>
						<Sidebar
							backButtonTo="/organizations"
							backButtonTitle="Back to Organizations"
							photoComponent={
								<LogoContainer
									handleLogoUpload={handleLogoUpload}
									isAdmin={isAdmin}
									showUploadButton={true}
								/>
							}>
							{sidebarContent}
						</Sidebar>
						<MobileDropdown title="Organization Info">{sidebarContent}</MobileDropdown>
						<MobileLogoContainer>
							<LogoContainer
								handleLogoUpload={handleLogoUpload}
								isAdmin={isAdmin}
								showUploadButton={true}
							/>
						</MobileLogoContainer>

						<TeamContainer>
							<TeamSearchBar
								searchQuery={searchQuery}
								setSearchQuery={setSearchQuery}
								onAddTeam={() => setOpenTeamDialog(true)}
								showButton={showButton}
								name={'Team'}
							/>
							<TeamContainerHeaderContainer>
								<TeamContainerHeader>Teams</TeamContainerHeader>
								{isAdmin || isOrgAdmin ? (
									<SelectionButtonsContainer>
										{!isSelectionMode ? (
											<SelectionButton onClick={toggleSelectionMode}>
												Select
											</SelectionButton>
										) : (
											<>
												<SelectionButton onClick={handleDeleteSelected}>
													Delete
												</SelectionButton>
												<SelectionButton onClick={toggleSelectionMode}>
													Cancel
												</SelectionButton>
											</>
										)}
									</SelectionButtonsContainer>
								) : null}
							</TeamContainerHeaderContainer>
							<div className="grid-container">
								<TeamGrid
									teams={filteredTeams}
									inSelectionMode={isSelectionMode}
									selectedTeams={selectedTeams}
									onSelectTeam={handleTeamSelect}
									userIsAdmin={isAdmin}
									userIsOrgAdmin={isOrgAdmin}
									userIsTeamMember={userIsTeamMember}
									onUnauthorizedAccess={handleUnauthorizedAccess}
								/>
							</div>
							<DeleteTeamDialog
								teamsToBeDeleted={teamsToBeDeleted}
								setTeamsToBeDeleted={setTeamsToBeDeleted}
							/>
							<AddTeamDialog
								open={openTeamDialog}
								setOpen={setOpenTeamDialog}
								organizationId={organizationId}
							/>
						</TeamContainer>
					</OrganizationPageContainer>
				</>
			) : (
				<>
					<OrganizationPageContainer>
						<SkeletonSidebar />
						<TeamContainer>
							<SkeletonGrid />
						</TeamContainer>
					</OrganizationPageContainer>
				</>
			)}
		</Layout>
	);
}

const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 19px;
	height: 70px;
`;

const MobileLogoContainer = styled.div`
	display: none;
	@media (max-width: 768px) {
		display: block;
	}
	max-height: 100px;
`;

const OrganizationPageContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 92vh;

	@media (max-width: 768px) {
		flex-direction: column;
	}

	@media (max-width: 400px) {
		width: 100%;
		margin: 0;
		padding: 0;
	}
`;

const SkeletonSidebar = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 250px;
	padding: 20px;
	background-color: black;
	border-right: 1px solid rgba(89, 89, 89, 0.4);
	height: 100%;
`;

export const TeamContainerHeaderContainer = styled.div`
	width: 95%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	min-width: 230px;
	padding-left: 10px;
	padding-right: 2px;
	align-items: center;

	@media (max-width: 1000px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const TeamContainerHeader = styled.h3`
	display: flex;
	color: white;
	text-align: center;
	font-weight: normal;
	font-size: 2rem;
	width: 100%;
	align-self: center;
	justify-content: center;
`;

export const TeamContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
	align-items: center;
	background-color: #111114;
	height: 100%;

	@media (max-width: 1020px) {
		width: 100%;
	}

	.grid-container {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
	}

	@media (max-width: 400px) {
		padding: 10px;
	}
`;

export const SelectionButton = styled(Button)`
	font-size: 1rem;
	background-color: #ffb310;
	color: black;
	flex-shrink: 0;
	border-radius: 50px;
	margin-left: auto;
	align-items: center;
	height: 40px;
	text-transform: none;
	padding: 15px;
	text-align: center;
	right: 0;

	&:hover {
		background-color: #ffb310;
	}
`;

export const SelectionButtonsContainer = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	gap: 5px;

	@media (max-width: 888px) {
		position: static;
		transform: none;
		top: 10%;
		transform: translateY(-90%);

		align-self: flex-end;
	}
`;
