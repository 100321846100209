import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import StringTextField from '../StringTextField';

type SingleStringCellProps = {
	row: Row<ProjectData>;
	rowEditing: boolean;
	field: 'storeNumber';
};

export default function SingleStringCell({
	row,
	rowEditing,
	field,
}: SingleStringCellProps) {
	const projectId = row.original.id as string;
	const value = row.original?.[field];

	return !rowEditing ? (
		<span>{value || '-'}</span>
	) : (
		<StringTextField projectId={projectId} defaultValue={value || ''} field={field} />
	);
}
