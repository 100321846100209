import { FC, useState } from 'react';
import { Checkbox } from '@mui/material';

type FormCheckboxProps = {
	onClick: (value: boolean) => void;
	initialValue?: boolean;
	disabled?: boolean;
};

const FormCheckbox: FC<FormCheckboxProps> = ({ onClick, disabled, initialValue }) => {
	const [checked, setChecked] = useState(initialValue);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(e.target.checked);
		onClick(e.target.checked);
	};

	return (
		<Checkbox
			onChange={onChange}
			checked={checked}
			color="primary"
			style={{ padding: 0 }}
			disabled={disabled}
		/>
	);
};

export default FormCheckbox;
