import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { useContext } from 'react';
import { SnackContext } from '../../../context/SnackProvider';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';

export default function RemoveProjectDialog({
	projectsToBeRemoved,
	setProjectsToBeRemoved,
	teamId,
}: {
	projectsToBeRemoved: string[] | undefined;
	setProjectsToBeRemoved: React.Dispatch<React.SetStateAction<string[] | undefined>>;
	teamId: string;
}) {
	const { removeProjectFromTeam, removingProjectFromTeam } =
		useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClose = () => {
		setProjectsToBeRemoved(undefined);
	};

	const handleDelete = async () => {
		for (const projectToBeRemoved of projectsToBeRemoved || []) {
			if (projectToBeRemoved) {
				const result = await removeProjectFromTeam(projectToBeRemoved, teamId);
				if (result) {
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: `Successfully removed projects from team!`,
					});
				}
			}
		}

		handleClose();
	};

	return (
		<Dialog open={!!projectsToBeRemoved} onClose={handleClose}>
			<DialogTitle>Remove projects</DialogTitle>
			{removingProjectFromTeam ? (
				<DialogContent>
					<LoadingScreen message="Removing projects from the team..." textColor="black" />
				</DialogContent>
			) : (
				<DialogContent>
					<span>Are you sure you want to remove these projects from this team?</span>
				</DialogContent>
			)}
			{!removingProjectFromTeam && (
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Ok</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}
