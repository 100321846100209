import { Autocomplete, Button, Chip, ClickAwayListener, TextField } from '@mui/material';
import styled from 'styled-components';
import { ProjectData } from '../../../../types';
import { useContext, useState } from 'react';
import { SnackContext } from '../../../../context/SnackProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function ModelerMultiDateTime({
	field,
	editProject,
	setEditProject,
}: {
	field: 'captureTimestamps';
	editProject: ProjectData | null;
	setEditProject: React.Dispatch<React.SetStateAction<ProjectData | null>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);

	const [focused, setFocused] = useState(false);
	const [dateStr, setDateStr] = useState('');

	const dates = editProject?.captureTimestamps || [];

	const handleDelete = (idx: number) => {
		setEditProject(prev => {
			if (prev) {
				const tmp = [...(prev[field] || [])];
				tmp?.splice(idx, 1);

				return {
					...prev,
					[field]: tmp.length ? tmp : undefined,
				};
			} else return prev;
		});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = e.target.value;
		setDateStr(newValue);
	};

	const handleAddDate = () => {
		if (dateStr) {
			const newDate = dayjs(dateStr).hour(12).minute(0).second(0).toDate();
			setEditProject(prev => {
				if (prev) {
					const prevArr = prev[field] || [];
					return {
						...prev,
						[field]: [...prevArr, newDate],
					};
				} else return prev;
			});
		} else {
			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: 'Invalid date selected!',
			});
		}
	};

	return focused ? (
		<ClickAwayListener onClickAway={() => setFocused(false)}>
			<Container>
				<DateContainer>
					{dates.length ? (
						dates.map((date, idx) => {
							return (
								<Chip
									key={idx}
									label={dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY')}
									onDelete={() => handleDelete(idx)}
								/>
							);
						})
					) : (
						<span>No dates selected...</span>
					)}
				</DateContainer>

				<InputContainer>
					<Input
						type="date"
						value={dateStr}
						onChange={handleChange}
						label="New date"
						InputLabelProps={{ shrink: true }}
						variant="standard"
					/>

					<Button onClick={handleAddDate}>Add</Button>
				</InputContainer>
			</Container>
		</ClickAwayListener>
	) : (
		<Autocomplete
			multiple
			disableClearable
			options={[]}
			value={dates.map(ts => dayjs(ts).tz(dayjs.tz.guess()).format('MM/DD/YYYY'))}
			onFocus={() => setFocused(true)}
			renderInput={props => (
				<TextField
					{...props}
					label="Site Visit Date(s)"
					InputLabelProps={{ shrink: !!dates.length }}
					variant="standard"
				/>
			)}
			renderTags={(value, getTagProps) =>
				value.map((val, idx) => (
					<Chip {...getTagProps({ index: idx })} label={val} onDelete={undefined} />
				))
			}
		/>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	background-color: antiquewhite;
`;

const DateContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
	flex-wrap: wrap;

	padding: 10px;
	background-color: gray;
	max-height: calc(100vh / 4);
	margin: 10px;
	overflow-y: auto;

	> span {
		color: white;
	}
`;

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 10px;
`;

const Input = styled(TextField)`
	flex-grow: 1;
`;
