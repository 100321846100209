import React, { useEffect, useRef, useState } from 'react';
import TableSearchbar from '../../reusable-components/DataTable/TableSearchbar';
// import Spinner from '../../reusable-components/Spinner';

export interface TeamSearchBarProps {
	searchQuery: string;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	onAddTeam?: () => void;
	showButton?: boolean;
	showSpinner?: boolean;
	onDebounceStart?: () => void;
	onDebounceEnd?: () => void;
	placeholder?: string;
	name: string;
}

const TeamSearchBar: React.FC<React.PropsWithChildren<TeamSearchBarProps>> = ({
	searchQuery,
	setSearchQuery,
	onAddTeam,
	showButton,
	showSpinner,
	onDebounceStart = () => {},
	onDebounceEnd = () => {},
	placeholder,
	name,
}) => {
	const [localQuery, setLocalQuery] = useState<string>(searchQuery);

	const debounce = useRef<number | null>(null);
	// const [debouncing, setDebouncing] = useState(false);

	useEffect(() => {
		if (typeof debounce.current === 'number') window.clearTimeout(debounce.current);
		debounce.current = window.setTimeout(() => {
			debounce.current = null;
			setSearchQuery(localQuery);
			// setDebouncing(false);
		}, 500);
	}, [localQuery, setSearchQuery]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// setDebouncing(true);
		onDebounceStart();

		const value = e.target.value;
		setLocalQuery(value);

		if (value === searchQuery) {
			// setDebouncing(false);
			onDebounceEnd();
		}
	};

	const clearQuery = () => {
		setSearchQuery('');
		setLocalQuery('');
	};

	return (
		<>
			<TableSearchbar
				query={localQuery}
				handleInputChange={handleInputChange}
				clearQuery={clearQuery}
				onAdd={onAddTeam}
				name={name}
				showButton={showButton}
				placeholder={placeholder}
			/>
			{/* {localQuery || showSpinner
				? showSpinner || (debouncing && <Spinner size={48} />)
				: null} */}
		</>
	);
};

export default TeamSearchBar;
