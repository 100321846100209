import { useContext, useEffect } from 'react';
import { V2FormGalleryContext } from '../V2FormGalleryProvider';
import ErrorScreen from '../../../components/reusable-components/ErrorScreen';
import LoadingScreen from '../../../components/reusable-components/LoadingScreen';
import V2FormGallery from './V2FormGallery';
import { useParams } from 'react-router-dom';

export default function V2FormGalleryRenderer() {
	const { errorMessage, loadingMessage, formRes, fetchV2Form } =
		useContext(V2FormGalleryContext);

	const { formId } = useParams();

	useEffect(() => {
		fetchV2Form(formId || '');
	}, [fetchV2Form, formId]);

	return errorMessage ? (
		<ErrorScreen message={errorMessage} />
	) : loadingMessage ? (
		<LoadingScreen message={loadingMessage} />
	) : formRes ? (
		<V2FormGallery />
	) : null;
}
