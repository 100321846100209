import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../../styles/theme';

const StyledTextField = styled(TextField)`
	width: 100%;

	${theme.breakpoints.up('lg')} {
		min-width: 400px;
	}

	input {
		color: ${({ theme }) => theme.palette.newText.primary};

		&::placeholder {
			line-height: 1.6;
		}
		&.Mui-disabled {
			border: 1px solid rgba(118, 118, 118, 0.3);
			border-radius: 5px;
		}
	}

	fieldset {
		border-color: ${({ theme }) => theme.palette.grey[800]};
	}

	label {
		color: ${({ theme }) => theme.palette.grey[300]};
		line-height: 1.6;
	}

	&:hover {
		> :not(.Mui-focused) {
			fieldset {
				border-color: ${({ theme }) => theme.palette.common.white};
			}
		}
	}

	.MuiFormHelperText-root {
		color: ${({ theme }) => theme.palette.grey[300]};
	}
`;

export default StyledTextField;
