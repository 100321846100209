import { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import AppText from '../../components/reusable-components/AppText';
import FormikTextField from '../../components/reusable-components/FormikTextField';
import { AuthContext } from '../../context/AuthProvider';
import ForgotPasswordDialog from '../../components/screen-components/Login/ForgotPasswordDialog';
import AppLink from '../../components/reusable-components/AppLink';
import styled from 'styled-components';
import BackgroundVideo from '../../components/reusable-components/BackgroundVideo';
import logoSrc from '../../assets/circleLogo.png';
import LoadingScreen from '../../components/reusable-components/LoadingScreen';
import { TermsAndPrivacy } from '../../components/screen-components/Login/TermsAndPrivacy';
import '../../styles/Login.css';

const validationsForm = {
	email: yup.string().email('Enter a valid email').required('Email is required'),
	password: yup
		.string()
		// .min(6, 'Password must contain at least 6 characters')
		.required('Password is required'),
};

export default function Login() {
	const { handleOnLogin, loginError, loadingUser, authLoadingText } =
		useContext(AuthContext);
	const [showingAddFolder, setShowingAddFolder] = useState(false);

	useEffect(() => {
		document.title = 'Login | Robotic Imaging';
	}, []);

	return (
		<>
			<Nav>
				<a href="https://www.roboticimaging.com/">
					<img
						src={logoSrc}
						alt=""
						style={{
							height: '40px',
							marginRight: '15px',
						}}
					/>
				</a>
			</Nav>
			<BackgroundVideo />
			<ForgotPasswordDialog
				showingDialog={showingAddFolder}
				setShowingDialog={setShowingAddFolder}
			/>
			<AuthContainer>
				{!loadingUser ? (
					<>
						<RealityBackground>
							<div className="reality">Reality Capture at Scale</div>
						</RealityBackground>
						<Formik
							initialValues={{
								email: '',
								password: '',
							}}
							validationSchema={yup.object().shape(validationsForm)}
							onSubmit={async values => {
								await handleOnLogin(values);
							}}>
							{({ errors, touched, handleSubmit, isSubmitting }) => (
								<AuthForm onSubmit={handleSubmit}>
									<AppText variant={'h5'} text="Login" />

									<FormikTextField
										label="Email"
										name="email"
										type="email"
										helperText={touched.email ? errors.email : ''}
										error={touched.email && Boolean(errors.email)}
										autoComplete="username"
									/>

									<FormikTextField
										label="Password"
										name="password"
										type="password"
										helperText={touched.password ? errors.password : ''}
										error={touched.password && Boolean(errors.password)}
										autoComplete="current-password"
									/>

									{loginError && <AppText text={loginError} error />}

									<AuthActions>
										<Button
											type="submit"
											color="primary"
											variant="contained"
											disabled={isSubmitting}>
											Login
										</Button>
										<ClickableText onClick={() => setShowingAddFolder(true)}>
											Forgot password?
										</ClickableText>
										<AppLink to="/register" text="Don't have an account?" />
									</AuthActions>
								</AuthForm>
							)}
						</Formik>
						<TermsAndPrivacy />
					</>
				) : (
					<LoadingScreen message={authLoadingText} />
				)}
			</AuthContainer>
		</>
	);
}

export const AuthContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
`;

export const AuthForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;

	min-width: 200px;
	max-height: calc(100% - 40px);
	overflow: auto;

	background-color: black;
	padding: 30px;
	border-radius: 20px;

	> .MuiTextField-root > .MuiInputBase-root > input:-webkit-autofill {
		box-shadow: 0 0 0 30px #232323 inset;
		-webkit-text-fill-color: #ffb310;
	}
`;

const ClickableText = styled.span`
	cursor: pointer;
	color: #ffb310;
	&:hover {
		text-decoration: underline;
	}
`;

const RealityBackground = styled.div`
	background-color: black;
	padding: 30px;
	border-radius: 20px;
	text-align: center;
	margin: 15px;
`;

export const AuthActions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
`;

export const Nav = styled.nav`
	background: transparent;
	display: flex;
	height: 50px;
	align-items: center;
	padding-left: 20px;
	margin-top: 15px;
	position: fixed;
`;
