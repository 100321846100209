import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {
	collection,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	query,
	where,
} from 'firebase/firestore';
import { ContainerProps } from '../types';
import { ReportData } from '../types/reports';
import { TeamData } from '../components/screen-components/ProjectInfo/components/field-components/SharedTeams/types';
import { FormTemplate } from '../components/screen-components/ProjectUtilityFormV2/utils/types';
import { getTeams } from '../firebase';
import { AuthContext } from './AuthProvider';

export type UserHomeContextType = {
	loadData: (userId: string) => void;
	loadingReports: boolean;
	loadingTemplates: boolean;
	loadingTeams: boolean;
	reports: ReportData[] | undefined;
	teams: TeamData[];
	templates: FormTemplate[] | undefined;
	refreshTemplates: () => void;
	refreshReports: () => void;
	refreshTeams: (userId: string) => void;
};

export const UserHomeContext = createContext<UserHomeContextType>(
	{} as UserHomeContextType
);

export const UserHomeProvider = ({ children }: ContainerProps) => {
	const [reports, setReports] = useState<ReportData[] | undefined>();
	const [loadingReports, setLoadingReports] = useState(true);
	const [teams, setTeams] = useState<TeamData[]>([]);
	const [templates, setTemplates] = useState<FormTemplate[] | undefined>();
	const [loadingTemplates, setLoadingTemplates] = useState(true);
	const [userId, setUserId] = useState('');
	const [loadingTeams, setLoadingTeams] = useState(true);
	const { user } = useContext(AuthContext);

	const loadTeams = useCallback(async (userId: string) => {
		setLoadingTeams(true);

		const teamIds: string[] =
			(await getDoc(doc(getFirestore(), 'users', userId))).data()?.teamIds || [];

		const teamPromises = teamIds.map(teamId =>
			getDoc(doc(getFirestore(), 'teams', teamId))
		);

		const teamDocs = await Promise.all(teamPromises);

		const teamData = teamDocs.map(doc => ({ ...doc.data(), id: doc.id } as TeamData));

		// If the user is an admin team, fetch all teams for the organization
		const adminOrgIds = teamData
			.filter(team => team.isAdmin)
			.map(team => team.organizationId);

		const orgTeamPromises = adminOrgIds.map(organizationId =>
			getTeams({ organizationId })
		);

		const orgTeamCollections = await Promise.all(orgTeamPromises);

		const orgTeams = orgTeamCollections
			.flatMap(result => {
				const collection = result.data as TeamData[];
				return collection;
			})
			.flat();

		// Merge the admin teams with the fetched teams without duplicates
		const mergedTeams = [
			...teamData,
			...orgTeams.filter(
				team => !teamData.some(existingTeam => existingTeam.id === team.id)
			),
		];

		setTeams(mergedTeams);
		setLoadingTeams(false);
	}, []);

	const loadData = useCallback(
		(userId: string) => {
			setUserId(userId);
			setLoadingReports(true);
			setLoadingTemplates(true);
			loadTeams(userId);
		},
		[loadTeams]
	);

	const loadReportsForAllTeams = useCallback(async () => {
		if (!teams || !user) return;
		setLoadingReports(true);

		const teamIds: string[] = teams?.map(team => team.id) || [];

		if (!teamIds.length) {
			setReports([]);
			setLoadingReports(false);
			return;
		}

		const reportPromises = teamIds.map(async teamId => {
			const teamDoc = await getDoc(doc(getFirestore(), 'teams', teamId));
			return await getDocs(
				query(
					collection(getFirestore(), 'reports'),
					where('teamId', '==', teamId),
					where('organizationId', '==', teamDoc.data()?.organizationId)
				)
			);
		});

		const reportCollections = await Promise.all(reportPromises);

		const reportsList = reportCollections
			.flatMap(collection =>
				collection.docs.map(doc => ({ ...doc.data(), id: doc.id } as ReportData))
			)
			.sort(
				(a, b) =>
					(b.createdAt?.toDate().getTime() || 0) - (a.createdAt?.toDate().getTime() || 0)
			);

		setReports(reportsList);
		setLoadingReports(false);
	}, [teams, user]);

	const loadTemplatesForAllTeams = useCallback(async () => {
		setLoadingTemplates(true);
		if (!teams || !user) return;

		const teamIds: string[] = teams?.map(team => team.id) || [];

		if (!teamIds.length) {
			setReports([]);
			setLoadingReports(false);
			return;
		}

		const templatePromises = teamIds.map(async teamId => {
			if (user?.teamIds?.includes(teamId)) {
				return await getDocs(
					query(
						collection(getFirestore(), 'utility_forms_v2_templates'),
						where('teamIds', 'array-contains', teamId)
					)
				);
			} else {
				const teamDoc = await getDoc(doc(getFirestore(), 'teams', teamId));
				return await getDocs(
					query(
						collection(getFirestore(), 'utility_forms_v2_templates'),
						where('organizationIds', 'array-contains', teamDoc.data()?.organizationId)
					)
				);
			}
		});

		const templateCollections = await Promise.all(templatePromises);

		const templatesList = templateCollections
			.flatMap(collection =>
				collection.docs.map(doc => ({ ...doc.data(), id: doc.id } as FormTemplate))
			)
			.filter(
				(template, index, self) => index === self.findIndex(t => t.id === template.id)
			)
			.sort((a, b) => a.name.localeCompare(b.name));

		setTemplates(templatesList);
		setLoadingTemplates(false);
	}, [teams, user]);

	useEffect(() => {
		if (userId && teams) {
			loadReportsForAllTeams();
			loadTemplatesForAllTeams();
		}
	}, [loadReportsForAllTeams, loadTemplatesForAllTeams, teams, userId]);

	return (
		<UserHomeContext.Provider
			value={{
				loadData,
				loadingReports,
				loadingTemplates,
				loadingTeams,
				reports,
				teams,
				templates,
				refreshTemplates: loadTemplatesForAllTeams,
				refreshReports: loadReportsForAllTeams,
				refreshTeams: loadTeams,
			}}>
			{children}
		</UserHomeContext.Provider>
	);
};
