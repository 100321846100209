import { useState, useContext, useEffect } from 'react';
import DeliverableDropzone from '../components/DeliverableDropzone';
import { ProjectContext } from '../../../../context/ProjectProvider';
import styled from 'styled-components';
import Carousel from '../../Carousel';
import DeliverableButtonsOverlay from '../components/DeliverableButtonsOverlay';
import { CarouselIframe } from '../../Carousel/Styled';

export default function AutodeskBox({
	downloadOnly,
	anonUpload,
}: {
	downloadOnly: boolean;
	anonUpload: boolean;
}) {
	const { project } = useContext(ProjectContext);

	const [validAutodeskLinks, setValidAutodeskLinks] = useState<string[]>([]);
	const [showAutodeskFilesDialog, setShowAutodeskFilesDialog] = useState(false);

	const locked = downloadOnly && project?.projectStatus === 'Pending Payment';

	useEffect(() => {
		async function getValid2DLinks(): Promise<void> {
			const links = [...(project?.twoDLinks || []), ...(project?.threeDLinks || [])];

			let arr: string[] = [];
			for (const link of links) {
				if (link.startsWith('http://') || link.startsWith('https://')) {
					arr.push(link);
				}
			}
			setValidAutodeskLinks(arr);
		}
		getValid2DLinks();
	}, [project?.threeDLinks, project?.twoDLinks]);

	return downloadOnly &&
		!validAutodeskLinks.length &&
		!project?.autodesk?.length ? null : (
		<DeliverableDropzone
			deliverableKey="autodesk"
			showingDialog={showAutodeskFilesDialog}
			setShowingDialog={setShowAutodeskFilesDialog}
			downloadOnly={downloadOnly}
			anonUpload={anonUpload}>
			{validAutodeskLinks.length && !locked ? (
				<CarouselContainer id="deliverable-autodesk">
					<Carousel lazy>
						{validAutodeskLinks.map((link: string, i: number) => (
							<CarouselIframe
								title="2d-iframe"
								src={link}
								key={i}
								allow="xr-spatial-tracking"
							/>
						))}
					</Carousel>
					<DeliverableButtonsOverlay
						deliverableKey="autodesk"
						downloadOnly={downloadOnly}
						setShowingDialog={setShowAutodeskFilesDialog}
					/>
				</CarouselContainer>
			) : null}
		</DeliverableDropzone>
	);
}

const CarouselContainer = styled.div`
	width: 100%;
	height: 100%;
`;
