import { styled } from '@mui/system';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import theme from '../../../styles/theme';
import { NavigationMenuProps } from './NavigationMenu.types';
import { FlattenedItem } from './Tree/types';
import { SortableTree } from './Tree/SortableTree';
import { FormNode } from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import Spinner from '../../reusable-components/Spinner';
import { MenuReorderContext } from '../../screen-components/Form/Form';
import { UTurnLeft } from '@mui/icons-material';
import { flattenTree } from './Tree/utilities';
import { cloneDeep, isEqual } from 'lodash';
import { SnackContext } from '../../../context/SnackProvider';
import usePrevious from '../../../utils/usePrevious';
import { TemplateContext } from '../../../context/TemplateProvider';

type TemplateData = {
	id: string;
	order: number;
	level: number;
	parentId: string;
};

const NavigationMenu: FC<NavigationMenuProps> = ({ items, onClick, style, title }) => {
	const { updateTemplateOrder, isTemplate } = useContext(TemplateContext);
	const { selectedItem } = useContext(MenuReorderContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [reorderCalled, setReorderCalled] = useState<boolean>(false);
	const [templateData, setTemplateData] = useState<TemplateData[] | []>([]);
	const previousValue = usePrevious(getOrderInfo(flattenTree(items)));
	const activeDataRef = useRef<any>(null);
	const overDataRef = useRef<any>(null);
	const previousRef = useRef<any[]>([]);

	const onReorder = async (active: FormNode, over: FormNode, items: FlattenedItem[]) => {
		setTemplateData([]);
		setReorderCalled(true);
		if (
			!showUndo &&
			!!previousValue &&
			!isEqual(getOrderInfo(flattenTree(items)), previousValue)
		) {
			setShowUndo(true);
		}
		activeDataRef.current = active;
		overDataRef.current = over;
		try {
			const updatePromises = items
				.filter((item: FlattenedItem) => active.parentId === (item.parentId ?? ''))
				.map((item: FlattenedItem) => {
					const level = item.depth;
					const parentId = String(item.parentId ?? ''); // Convert parentId to string
					const order = item.index;
					const data = {
						order,
						level,
						parentId,
					};

					return isTemplate
						? setTemplateData(prev => [...prev, { id: String(item.id), ...data }])
						: updateDoc(doc(db, 'utility_forms_v2_items', item.node.id), data);
				});
			await Promise.all(updatePromises);
		} catch (error) {
			console.error(error);
		}
	};
	const handleUndo = async () => {
		const findPrevActive =
			previousValue.find((item: FlattenedItem) => item.id === activeDataRef.current.id)
				?.node || activeDataRef.current;

		const findPrevOver =
			previousValue.find((item: FlattenedItem) => item.id === overDataRef.current.id)
				?.node || overDataRef.current;

		onReorder(findPrevActive, findPrevOver, previousValue);

		setShowUndo(false);
	};

	useEffect(() => {
		if (selectedItem) onClick?.(selectedItem);
	}, [selectedItem, onClick]);

	useEffect(() => {
		const sortedItems = sortObjectsById(flattenTree(items));
		const sortedPrevious = sortObjectsById(previousRef.current);

		// Check if the items have been updated by other users
		if (
			!isEqual(sortedItems, sortedPrevious) &&
			!reorderCalled &&
			previousRef.current.length &&
			items.length &&
			showUndo
		) {
			setShowUndo(false);
			setSnackbarProps({
				open: true,
				message: `Outline data has been updated.`,
				severity: 'info',
			});
		}
		previousRef.current = sortedItems;
		setReorderCalled(false);
	}, [items]);

	useEffect(() => {
		if (isTemplate && templateData.length) {
			updateTemplateOrder(templateData);
		}
	}, [templateData, isTemplate]);
	return (
		<Container style={style}>
			<Title>
				{title}
				{showUndo && <StyledUndoButton onClick={handleUndo} />}
			</Title>

			{items ? (
				<SortableTree defaultItems={items} onReorder={onReorder} collapsible />
			) : (
				<SpinnerContainer>
					<Spinner />
				</SpinnerContainer>
			)}
		</Container>
	);
};

const Container = styled('div')`
	border: 1px solid ${theme.palette.secondary.light};
	background-color: ${theme.palette.secondary.dark};
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	width: 360px;
	overflow-y: scroll;
	padding: 1px 0;
`;

const Title = styled('div')`
	padding: 8px 16px;
	padding-top: 12px;
	padding-bottom: 8px;
	background-color: ${theme.palette.secondary.dark};
	border-bottom: 1px solid ${theme.palette.grey[600]};
	color: white;
	font-size: 12px;
	text-align: start;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const SpinnerContainer = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	justify-content: center;
`;

const StyledUndoButton = styled(UTurnLeft)`
	transform: rotate(90deg);
	color: ${theme.palette.primary.main};
	cursor: pointer;
	margin: -3px 0;
	transition: 0.3s;
	&:hover {
		opacity: 0.7;
	}
`;

const sortObjectsById = (array: FlattenedItem[]) => {
	return getOrderInfo(array).sort((a: any, b: any) => {
		const idA = String(a.id);
		const idB = String(b.id);
		if (idA < idB) return -1;
		if (idA > idB) return 1;
		return 0;
	});
};

const getOrderInfo = (array: FlattenedItem[]) => {
	const clonedArray = cloneDeep(array);
	return clonedArray.map((item: any) => {
		const sortedItem = { ...item };

		if (Array.isArray(sortedItem.children)) {
			sortedItem.children = getOrderInfo(sortedItem.children);
		}

		delete sortedItem.node.updatedBy;
		delete sortedItem.node.parentsTitle;
		delete sortedItem.node.title;
		delete sortedItem.node.value;
		delete sortedItem.node.options;
		delete sortedItem.node.displayTitle;
		delete sortedItem.node.displayOrder;
		delete sortedItem.node.updatedAt;

		return sortedItem;
	});
};

export default NavigationMenu;
