import { useContext } from 'react';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import {
	ProjectCategory,
	// scopedCategories
} from '../../utils';
import MultiSection from './components/MultiSection';
import Section from './components/Section';
import { CachedScopeSection } from '../../../../../types/firestore';
import { AuthContext } from '../../../../../context/AuthProvider';

type ScopeRendererProps = {
	category: ProjectCategory;
};

export default function ProjectCategoryScopes({ category }: ScopeRendererProps) {
	const { user } = useContext(AuthContext);
	const { cachedProjectScopes } = useContext(ProjectContext);

	// const cachedScopeObj = cachedProjectScopes[category];
	// const showScopes =
	// 	scopedCategories.includes(category) &&
	// 	cachedScopeObj &&
	// 	!!cachedScopeObj.sections.length;

	// hold the cached titles + options for each scope category
	const cachedScanScope = cachedProjectScopes['scanning'];
	const cachedBIMScope = cachedProjectScopes['bim'];

	const isValidMultiSection = (cachedScope: {
		title?: string;
		sections: CachedScopeSection[];
	}) => {
		return cachedScope.title && cachedScope.sections.length > 1;
	};

	/** In 'projectScope' (Project Scope) category, show the scope sections with the
	 * 	ability to edit and select/remove scopes, do not display checkboxes
	 *
	 *  In 'scanning' (Data Collection), 'dataProcessing' (Processing), and
	 * 	'bim' (BIM) categories, display the checkboxes for the selected scopes, but hide
	 * 	the ability to edit the scopes.
	 */
	return category === 'projectScope' && user?.isAdmin ? (
		<>
			<Section
				category="scanning"
				cachedSection={cachedScanScope.sections[0]}
				showCheckboxes={true}
				allowEdits={true}
			/>
			{isValidMultiSection(cachedBIMScope) ? (
				<MultiSection category="bim" showCheckboxes={true} allowEdits={true} />
			) : null}
		</>
	) : null;
	// ) : showScopes ? (
	// 	isValidMultiSection(cachedScopeObj) ? (
	// 		// Multi sectioned scope.
	// 		<MultiSection category={category} showCheckboxes={true} allowEdits={false} />
	// 	) : (
	// 		// Single sectioned scope.
	// 		<Section
	// 			category={category}
	// 			cachedSection={cachedScopeObj.sections[0]}
	// 			showCheckboxes={true}
	// 			allowEdits={false}
	// 		/>
	// 	)
	// ) : null;
}
