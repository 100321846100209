import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PendingProjectShareData } from '../types/firestore';
import { createUser, getPendingProjectShare } from '../firebase';
import splashVideo from '../assets/splash.mp4';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import AppText from '../components/reusable-components/AppText';
import FormikTextField from '../components/reusable-components/FormikTextField';

import { Button } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { AuthContext } from '../context/AuthProvider';
import Spinner from '../components/reusable-components/Spinner';

const validationsForm = {
	fullName: yup.string().required('Full name required'),
	password: yup
		.string()
		.min(6, 'Password must contain at least 6 characters')
		.required('Password required'),
	passwordRepeat: yup
		.string()
		.oneOf([yup.ref('password')], 'Passwords must match')
		.required('Confirm your password'),
};

export default function PendingProjectShare() {
	const { handleOnLogin, handleOnLogout } = useContext(AuthContext);
	const navigate = useNavigate();

	const [shareData, setShareData] = useState<PendingProjectShareData | null>(null);
	const [loadingError, setLoadingError] = useState('');

	// Search params for pending project share id
	const [searchParams] = useSearchParams();
	const pendingShareDocId = searchParams.get('id');

	// Gets pending project share document based on search params on load
	useEffect(() => {
		const getShareData = async () => {
			try {
				const res = await getPendingProjectShare({ id: pendingShareDocId });
				setShareData(res.data as PendingProjectShareData);
			} catch (err) {
				console.error(err);
				setLoadingError('Could not load project invite...');
			}
		};
		getShareData();
	}, [pendingShareDocId]);

	return (
		<Container>
			<BackgroundVideo autoPlay loop muted playsInline>
				<source src={splashVideo} type="video/mp4" />
			</BackgroundVideo>

			{!!shareData ? (
				<Formik
					validateOnBlur
					validationSchema={yup.object().shape(validationsForm)}
					initialValues={{
						email: shareData.email,
						fullName: '',
						password: '',
						passwordRepeat: '',
					}}
					onSubmit={async (values, { setSubmitting }) => {
						try {
							// Call Firebase Function to create new user with pending projects shared
							await createUser({
								...values,
								pendingShareId: shareData.id,
							});
							// Logout any user currently logged in
							await handleOnLogout();
							// Login to platform
							await handleOnLogin({ email: values.email, password: values.password });
							// Goto projects page
							navigate('/projects');
						} catch (err) {
							console.error(err);
							setShareData(null);
							setLoadingError(
								'Could not create account. Please contact the Robotic Imaging team.'
							);
						}
						setSubmitting(false);
					}}>
					{({ errors, touched, handleSubmit, isSubmitting }) => (
						<FormContainer onSubmit={handleSubmit}>
							<AppText variant={'h5'} text="Finish Signup" />
							<FormikTextField
								label="Email"
								name="email"
								type="email"
								fullWidth
								disabled
							/>
							<FormikTextField
								label="Full Name"
								name="fullName"
								type="text"
								helperText={touched.fullName ? errors.fullName : undefined}
								error={touched.fullName && Boolean(errors.fullName)}
								fullWidth
							/>
							<FormikTextField
								label="Password"
								name="password"
								type="password"
								helperText={touched.password ? errors.password : undefined}
								error={touched.password && Boolean(errors.password)}
								fullWidth
							/>
							<FormikTextField
								label="Retype Password"
								name="passwordRepeat"
								type="password"
								helperText={touched.passwordRepeat ? errors.passwordRepeat : undefined}
								error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
								fullWidth
							/>
							{!isSubmitting ? <Button type="submit">Submit</Button> : <Spinner />}
						</FormContainer>
					)}
				</Formik>
			) : !loadingError ? (
				<>
					<Spinner size={200} />
					<span>Loading project invite...</span>
				</>
			) : (
				<>
					<WarningAmberIcon sx={{ height: '100px', width: '100px' }} />
					<span>{loadingError}</span>
				</>
			)}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
	color: #ffb310;
`;

const BackgroundVideo = styled.video`
	position: fixed;
	object-fit: cover;
	z-index: -1;

	width: 100%;
	height: 100%;
`;

const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	min-width: 300px;
	background-color: black;
	padding: 30px;
	border-radius: 20px;
	font-size: 14px;
`;
