import { CellProps, Column } from 'react-table';
import { ProjectData, ProjectStatus } from '../../../../types';
import { projectStatusText } from '../../../../utils';
import {
	StatusGroupBackgroundColor,
	StatusGroupColor,
	StatusGroupName,
	statusGroups,
} from '../utils';
import styled from 'styled-components';
import { Box, Chip, Typography } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';
import { ContentCopy } from '@mui/icons-material';
import { copyToClipboard, getAnonDownloadLink } from '../../../../utils';
import { SnackContext } from '../../../../context/SnackProvider';

export const getStatusGroupColumns = () => {
	return [statusColumn, storeNumber, addressColumn];
};

export const getStatusBackgroundColor = (status: ProjectStatus): string => {
	const groupName = getStatusGroup(status);
	return StatusGroupBackgroundColor[groupName as StatusGroupName];
};

export const getStatusColor = (status: ProjectStatus): string => {
	const groupName = getStatusGroup(status);
	return StatusGroupColor[groupName as StatusGroupName];
};

export const getStatusGroup = (status: ProjectStatus): StatusGroupName => {
	for (const [groupName, statuses] of Object.entries(statusGroups)) {
		if (statuses.includes(status)) {
			return groupName as StatusGroupName;
		}
	}

	return 'Pending';
};

const statusColumn: Column<ProjectData> = {
	Header: 'Status',
	Cell: ({ row }: CellProps<ProjectData>) => {
		const { user } = useContext(AuthContext);
		const status = row.original.projectStatus;
		const bgColor = getStatusBackgroundColor(status);
		const borderColor = getStatusColor(status);
		return (
			<StatusChip
				label={user?.isAdmin ? projectStatusText(status) : getStatusGroup(status)}
				$bgColor={bgColor}
				$borderColor={borderColor}
			/>
		);
	},
};

const storeNumber: Column<ProjectData> = {
	Header: 'Site Number',
	Cell: ({ row }: CellProps<ProjectData>) => {
		return <span>{row.original.storeNumber ?? ''}</span>;
	},
};

const addressColumn: Column<ProjectData> = {
	Header: 'Address',
	Cell: ({ row }: CellProps<ProjectData>) => {
		const { setSnackbarProps } = useContext(SnackContext);
		return (
			<Box sx={{ display: 'flex', alignItems: 'center', ml: '-10px' }}>
				<ContentCopy
					onClick={e => {
						e.stopPropagation();
						copyToClipboard(getAnonDownloadLink(row.original));
						setSnackbarProps({
							open: true,
							message: `${row.original.name} public shareable link copied!`,
							severity: 'success',
						});
					}}
					className="CopyLinkButton CopyLinkButton-black"
					titleAccess="Copy public shareable link"
					tabIndex={0}
				/>
				<Typography variant="body2" sx={{ mt: '4px' }}>
					{row.original.address}
				</Typography>
			</Box>
		);
	},
	width: 350,
};

export const StatusChip = styled(Chip)<{ $bgColor: string; $borderColor: string }>`
	border-radius: 5px;
	background-color: ${({ $bgColor }) => $bgColor};
	border: ${({ $borderColor }) => `2px solid ${$borderColor}`};
	color: black;
`;
