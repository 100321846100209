import styled from 'styled-components';
import theme from '../../../../../../styles/theme';
import ClearIcon from '@mui/icons-material/Clear';

interface ScopeInputProps {
	checked: boolean;
	text: string;
	onChange: (scopeName: string, newValue: boolean) => Promise<void>;
}

export default function ScopeInput({ checked, text, onChange }: ScopeInputProps) {
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onChange(text, !checked);
		}
	};

	return (
		<Container>
			<CheckboxContainer>
				<XCheckbox
					type="checkbox"
					checked={checked}
					onChange={e => onChange(text, e.target.checked)}
					onKeyUp={handleKeyPress}
				/>
				<Checkmark checked={checked} />
			</CheckboxContainer>

			<Title checked={checked}>{text}</Title>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;

const Title = styled.span<{ checked: boolean }>`
	color: ${({ checked }) => (checked ? theme.palette.primary.main : 'white')};
	transition: 0.2s;
`;

const CheckboxContainer = styled.div`
	display: flex;
	position: relative;
`;

const boxWidth = '25px';
const boxBorderWidth = '2px';

const XCheckbox = styled.input`
	display: flex;
	align-items: center;
	justify-content: center;

	width: ${boxWidth};
	height: ${boxWidth};
	margin: 2px;
	position: relative;
	cursor: pointer;

	/* Checkbox */
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: ${boxWidth};
		height: ${boxWidth};
		background: ${theme.palette.secondary.dark};
		outline: solid white ${boxBorderWidth};
		box-sizing: border-box;
		transition: 0.1s;
	}
	&:hover::before {
		outline: solid ${theme.palette.primary.main} ${boxBorderWidth};
	}

	/* Accessibility */
	&:focus {
		outline: none;
		&::before {
			outline: solid ${theme.palette.primary.main} ${boxBorderWidth};
		}
	}
`;

const Checkmark = styled(ClearIcon)<{ checked: boolean }>`
	position: absolute;
	top: ${boxBorderWidth};
	left: ${boxBorderWidth};

	width: ${boxWidth};
	height: ${boxWidth};
	fill: ${theme.palette.primary.main};
	pointer-events: none;
	transition: 200ms;

	opacity: ${({ checked }) => (checked ? '1' : '0')};
	transform: ${({ checked }) =>
		checked ? 'scale(1) rotate(0)' : 'scale(0) rotate(45deg)'};
	-webkit-transform: ${({ checked }) =>
		checked ? 'scale(1) rotate(0)' : 'scale(0) rotate(45deg)'};
`;
