import { useState, useContext, useEffect } from 'react';
import DeliverableDropzone from '../components/DeliverableDropzone';
import { ProjectContext } from '../../../../context/ProjectProvider';
import styled from 'styled-components';
import Carousel from '../../Carousel';
import DeliverableButtonsOverlay from '../components/DeliverableButtonsOverlay';
import { CarouselImg, CarouselPdf, CarouselPdfPlaceholder } from '../../Carousel/Styled';
import { useWindowSize } from '../../../../screens/Welcome';
import { splitFilename } from '../../../../utils';

type PreviewFile = { url: string; type: 'pdf' | 'doc' | 'img' };
const validFileExtensions = [
	'pdf',
	'doc',
	'docx',
	'jpg',
	'jpeg',
	'png',
	'gif',
	'webp',
	'avif',
	'svg',
];

export default function ExistingBox({
	downloadOnly,
	anonUpload,
}: {
	downloadOnly: boolean;
	anonUpload: boolean;
}) {
	const { project } = useContext(ProjectContext);

	const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>([]);
	const [showPdfFilesDialog, setShowPdfFilesDialog] = useState(false);
	const { width } = useWindowSize();

	const locked = downloadOnly && project?.projectStatus === 'Pending Payment';

	useEffect(() => {
		function getValidExistingDocsLinks() {
			if (project?.existingDocs?.length && width >= 1024) {
				setPreviewFiles(() => {
					const fileArr: PreviewFile[] = [];
					project.existingDocs?.forEach(file => {
						const filename = file.filename.toLowerCase();
						const [, extension] = splitFilename(filename);
						if (validFileExtensions.includes(extension || '')) {
							fileArr.push({
								url: file.downloadUrl,
								type:
									extension === 'pdf'
										? 'pdf'
										: ['doc', 'docx'].includes(extension || '')
										? 'doc'
										: 'img',
							});
						}
					});
					return fileArr;
				});
			} else {
				setPreviewFiles([]);
			}
		}
		getValidExistingDocsLinks();
	}, [project?.existingDocs, width]);

	return downloadOnly && !previewFiles.length && !project?.existingDocs?.length ? null : (
		<DeliverableDropzone
			deliverableKey="existingDocs"
			showingDialog={showPdfFilesDialog}
			setShowingDialog={setShowPdfFilesDialog}
			downloadOnly={downloadOnly}
			anonUpload={anonUpload}>
			{previewFiles.length && !locked ? (
				<CadContainer id="deliverable-existingDocs">
					<Carousel lazy>
						{previewFiles.map(({ url, type }, i: number) => {
							if (type === 'pdf') {
								return (
									<CarouselPdf data={url} type="application/pdf" key={i}>
										<CarouselPdfPlaceholder>
											<span>Could not load PDF Viewer:</span>
											<a href={url}>Click to view PDF</a>
										</CarouselPdfPlaceholder>
									</CarouselPdf>
								);
							} else if (type === 'doc') {
								return (
									<iframe
										title={url}
										key={i}
										src={`https://docs.google.com/gview?url=${encodeURIComponent(
											url
										)}&embedded=true`}
										style={{ width: '100%', height: '100%' }}>
										<span>Could not load Word Document Viewer:</span>
										<a href={url}>Click to download Word Document</a>
									</iframe>
								);
							} else {
								return <CarouselImg src={url} key={i} alt={url} />;
							}
						})}
					</Carousel>
					<DeliverableButtonsOverlay
						deliverableKey="existingDocs"
						downloadOnly={downloadOnly}
						setShowingDialog={setShowPdfFilesDialog}
					/>
				</CadContainer>
			) : null}
		</DeliverableDropzone>
	);
}

const CadContainer = styled.div`
	width: 100%;
	height: 100%;
`;
