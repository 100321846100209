import styled from 'styled-components';
import Selector from './Selector';
import { useContext, useMemo } from 'react';
import { V2FormGalleryContext } from '../V2FormGalleryProvider';
import { FormControlLabel, Switch } from '@mui/material';
import { GalleryNode, GalleryPhoto, AncestorSelectorData } from '../types';
import { getParentSelectorTitle } from '../utils';

export default function GalleryControls() {
	const {
		photos,
		galleryIndex,

		filteredNodesObj,

		formRes,
		showPdf,
		setShowPdf,
	} = useContext(V2FormGalleryContext);

	/**
	 * The current GalleryPhoto. Uses 'photos' and 'galleryIndex' to keep track of the current photo.
	 */
	const currPhoto = useMemo(() => photos[galleryIndex], [galleryIndex, photos]);
	/**
	 * An array of GalleryPhotos. Contains the 'currPhoto's sibiling photos from the same parent photo node.
	 */
	const currPhotoOptions = useMemo(() => {
		const currPhotoNode = currPhoto.node;
		const galleryPhotos: GalleryPhoto[] = Array.isArray(currPhotoNode.value)
			? currPhotoNode.value.map((photo, idx) => ({
					...photo,
					node: currPhotoNode,
					galleryIndex: currPhotoNode.galleryIndex + idx,
			  }))
			: [];
		return galleryPhotos;
	}, [currPhoto.node]);

	/**
	 * An array of AncestorSelectorData, created using 'currPhoto' and 'filteredNodesObj', starting
	 * from the current photo's parent, working backwards.
	 *
	 * Is used to render the current photo's ancestors.
	 */
	const ancestors = useMemo(() => {
		const ancestorArr: AncestorSelectorData[] = [];
		let ancestor: GalleryNode | undefined = currPhoto.node;
		/**
		 * Iterate through the current photo's ancestors starting with the current photo's direct ancestor node
		 * (which is a photo node). Get the current ancestor's sibling nodes by using the ancestor's 'parentId'
		 * property to access 'filteredNodesObj'. Now we can use the sibling nodes as options for the current
		 * ancestor's Selector component.
		 */
		while (ancestor) {
			/**
			 * We want the ancestor Selector components to render from left to right in order of most nested to
			 * least nested, but since we are working backwards from the current photo's parent node, use unshift
			 * to add new AncestorSelectorData objects to the to the front of the 'ancestorArr'.
			 *
			 * This way, the 'ancestorArr' contains data in the correct rendering order and we simply map
			 * through it later.
			 *
			 * Note that when we get the sibling nodes from 'filteredNodesObj', the time complexity is constant
			 * because we are simply accessing an object's property. This is why we hold the sibling GalleryNodes
			 * as an array with their parent node's id as keys, because if we only had the tree structure,
			 * traversing the tree to find an ancestor's sibling elements would be O(n) time complexity.
			 */
			ancestorArr.unshift({
				title: getParentSelectorTitle(ancestor),
				options: filteredNodesObj[ancestor.parentId || 'root'],
				currentNode: ancestor,
			});
			/**
			 * Set 'ancestor' to the current ancestor's parent node.
			 *
			 * Note that this operation has constant time complexity due to the tree structure built into the
			 * GalleryNodes.
			 */
			ancestor = ancestor.parentNode;
		}

		return ancestorArr;
	}, [currPhoto.node, filteredNodesObj]);

	return (
		<Container>
			{/* Ancestor Selectors */}
			{ancestors.map(parent => {
				return (
					<Selector
						title={parent.title}
						options={parent.options}
						value={parent.currentNode.galleryIndex}
						key={parent.currentNode.id}
					/>
				);
			})}

			{/* Photo Selector */}
			<Selector title="Photo File" options={currPhotoOptions} value={galleryIndex} />

			{formRes?.form.downloadUrlVirtual ? (
				<SwitchContainer
					control={
						<Switch value={showPdf} onChange={e => setShowPdf(e.currentTarget.checked)} />
					}
					label="Toggle PDf Overlay"
				/>
			) : null}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 10px;
	color: #ffb310;

	> .MuiFormControl-root {
		flex-grow: 1;
		max-width: 350px;
	}

	@media screen and (max-width: 767px) {
		flex-direction: column;

		> div {
			max-width: 100%;
			width: 100%;

			.MuiSelect-select {
				padding: 5px;
			}
		}
	}
`;

const SwitchContainer = styled(FormControlLabel)`
	flex-grow: 0;
	padding-left: 12px;

	.MuiSwitch-track {
		background-color: rgba(255, 255, 255, 0.5);
	}
`;
