import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { SnackContext } from '../../../context/SnackProvider';
import useTemplates from './useTemplates';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';

export default function RemoveTemplateDialog({
	templatesToBeRemoved,
	setTemplatesToBeRemoved,
	teamId,
	organizationId,
}: {
	templatesToBeRemoved: string[] | undefined;
	setTemplatesToBeRemoved: Dispatch<SetStateAction<string[] | undefined>>;
	teamId: string;
	organizationId: string;
}) {
	const { loadTeam } = useContext(OrganizationsContext);
	const { removeTemplateFromTeam } = useTemplates({ teamId, organizationId });
	const { setSnackbarProps } = useContext(SnackContext);
	const [removingTemplateFromTeam, setRemovingTemplateFromTeam] = useState(false);

	const handleClose = () => setTemplatesToBeRemoved(undefined);

	const handleDelete = async () => {
		for (const templateToBeRemoved of templatesToBeRemoved || []) {
			if (templateToBeRemoved) {
				setRemovingTemplateFromTeam(true);
				const result = await removeTemplateFromTeam(templateToBeRemoved);
				await loadTeam(teamId);

				if (result) {
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: `Successfully removed templates from team!`,
					});
				}
			}
		}
		handleClose();
		setRemovingTemplateFromTeam(false);
	};

	return (
		<Dialog open={!!templatesToBeRemoved} onClose={handleClose}>
			<DialogTitle>Remove templates</DialogTitle>
			{removingTemplateFromTeam ? (
				<DialogContent>
					<LoadingScreen
						message="Removing templates from the team..."
						textColor="black"
					/>
				</DialogContent>
			) : (
				<DialogContent>
					<span>Are you sure you want to remove these templates from this team?</span>
				</DialogContent>
			)}
			{!removingTemplateFromTeam && (
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Ok</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}
