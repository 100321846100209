import { ArrowDropDown, DoneAll } from '@mui/icons-material';
import { Box, IconButton, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import logoSrc from '../../../../assets/circleLogo.png';
import theme from '../../../../styles/theme';
import { PendingUser, SharedUser } from './RightNavButtons/types';
import ProjectEmailButton from './RightNavButtons/components/ProjectEmailButton';
import Spinner from '../../../reusable-components/Spinner';

export const ProjectInfoDropdown: React.FC<{
	title: string;
	isAdmin?: boolean;
	isContractor?: boolean;
	isComplete?: boolean;
	sharedUsers?: SharedUser[];
	pendingUsers?: PendingUser[];
	children?: React.ReactNode;
	notExpandable?: boolean;
	isLoadingEmail?: boolean;
	showEmailButton?: boolean;
	customIcon?: React.ReactNode;
}> = ({
	title,
	isAdmin,
	isContractor,
	isComplete,
	sharedUsers = [],
	pendingUsers = [],
	children,
	notExpandable = false,
	isLoadingEmail,
	showEmailButton = false,
	customIcon,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(!isAdmin || notExpandable);

	return (
		<ProjectInfoContainer>
			<ProjectHeader
				onClick={() => !notExpandable && setIsOpen(!isOpen)}
				expandable={!notExpandable ? 1 : 0}>
				<StyledBox>
					<img
						src={logoSrc}
						alt="Logo"
						style={{ height: 18, marginRight: 10, userSelect: 'none' }}
					/>
					<StyledTypography variant="h6">{title}</StyledTypography>
				</StyledBox>
				<StyledBox>
					{showEmailButton && !isContractor ? (
						!isLoadingEmail ? (
							<ProjectEmailButton teamMembers={sharedUsers} pendingUsers={pendingUsers} />
						) : (
							<Box sx={{ mt: '-18px', mb: '-25px' }}>
								<Spinner />
							</Box>
						)
					) : null}

					{!!customIcon && customIcon}

					{isComplete && (
						<DoneAll
							sx={{ color: 'green', cursor: 'default' }}
							onClick={event => event.stopPropagation()}
						/>
					)}

					{!notExpandable && (
						<IconButton sx={{ mt: '-10px', mb: '-10px' }}>
							<ArrowDropDown
								sx={{
									color: theme.palette.common.white,
									cursor: 'pointer',
									transform: isOpen ? 'scale(-1, -1)' : 'scale(1, 1)',
								}}
							/>
						</IconButton>
					)}
				</StyledBox>
			</ProjectHeader>
			<ProjectContent open={isOpen ? 1 : 0}>{children}</ProjectContent>
		</ProjectInfoContainer>
	);
};

const ProjectInfoContainer = styled(Box)`
	background-color: ${theme.palette.common.black};
	border: 1px solid ${theme.palette.borderColor};
	border-radius: 5px;
	overflow: hidden;
	width: 100%;
	transition: 0.3s;
`;

const ProjectHeader = styled(Box)<{ expandable: number }>`
	display: grid;
	align-items: center;
	justify-content: space-between;
	grid-template-columns: 1fr auto;
	border-bottom: 4px solid ${theme.palette.borderColor};
	padding: 10px;
	cursor: ${({ expandable }) => (expandable ? 'pointer' : 'default')};
`;

const ProjectContent = styled(Box)<{ open: number }>`
	width: 100%;
	transition: all 0.3s;
	overflow: visible;
	height: auto;
	background-color: #0a0a0c;
	position: relative;

	& > div {
		transition: all 0.3s;
	}

	${({ open }) =>
		!open &&
		`
		overflow: hidden;
		height: 0;
		padding-bottom: 0;
	`}
`;

const StyledBox = styled(Box)`
	display: flex;
	align-items: center;
`;

const StyledTypography = styled(Typography)`
	margin-top: 4px;
	user-select: none;
	line-height: 1.25rem;
	font-weight: 600;
`;
