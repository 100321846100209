import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormV2Context, FormV2ContextProps } from './FormV2Context.types';
import {
	FormNode,
	FormNodeWithChildren,
	ItemType,
	LocalPhoto,
	NodeType,
	PhotoV2,
	QueuedPhotoV2,
	UtilityFormV2,
	UtilityFormV2Status,
	itemTypes,
} from '../utils/types';
import { AuthContext } from '../../../../context/AuthProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { SnackContext } from '../../../../context/SnackProvider';
import { db } from '../../../../firebase';
import {
	addDoc,
	arrayRemove,
	arrayUnion,
	collection,
	deleteDoc,
	deleteField,
	doc,
	getDocs,
	onSnapshot,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from 'firebase/firestore';
import { useV2Storage } from '../hooks/useV2Storage';
import { v4 as uuidv4 } from 'uuid';
import { getFileExtension } from '../utils/utils';
import { useParams } from 'react-router-dom';
import { useRootNodes } from '../hooks/useRootNodes';

export const FormV2Provider: FC<React.PropsWithChildren<FormV2ContextProps>> = ({
	children,
}) => {
	const { formId } = useParams() as { [key: string]: string };

	const { setSnackbarProps } = useContext(SnackContext);
	const { user, firebaseAuthData } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const [utilityForm, setUtilityForm] = useState<UtilityFormV2>();
	const [isLoading, setIsLoading] = useState(true);
	const [isUploading, setIsUploading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [photoUploadQueue, setPhotoUploadQueue] = useState<QueuedPhotoV2[]>([]);
	const [photoToRemoveFromQueue, setPhotoToRemoveFromQueue] = useState<
		QueuedPhotoV2 | undefined
	>();
	const [failedPhotoUpload, setFailedPhotoUpload] = useState<QueuedPhotoV2>();
	const [nodeStack, setNodeStack] = useState<string[]>([]);
	const [currentNode, setCurrentNode] = useState<FormNode>();

	const { uploadFromQueue } = useV2Storage();
	const { rootNodes } = useRootNodes(formId, utilityForm?.projectId || '');
	const formDocRef = useMemo(() => doc(db, `utility_forms_v2/${formId}`), [formId]);
	const [copiedNodeData, setCopiedNodeData] = useState<FormNodeWithChildren | null>(null);

	const uid = firebaseAuthData?.uid || 'Unknown UID';

	const addToNodeStack = (nodeId: string) => {
		setNodeStack([...nodeStack, nodeId]);
	};

	const popFromNodeStack = () => {
		const newStack = [...nodeStack];
		newStack.pop();
		setNodeStack(newStack);
	};

	const updateProgress = (photoName: string, progress: number) => {
		setPhotoUploadQueue(currentQueue =>
			currentQueue.map(photo =>
				photo.photo.name === photoName ? { ...photo, progress } : photo
			)
		);
	};

	const lockForm = useCallback(async () => {
		try {
			await updateDoc(formDocRef, {
				locked: {
					...utilityForm?.locked,
					[uid]: {
						at: serverTimestamp(),
						by: user?.fullName || '',
					},
				},
			});
		} catch (e) {
			console.error(e);
		}
	}, [formDocRef, uid, user?.fullName, utilityForm?.locked]);

	const unlockForm = useCallback(async () => {
		try {
			if (utilityForm) {
				const locked = utilityForm.locked;
				if (locked) {
					delete locked[uid];

					if (Object.keys(locked).length === 0) {
						await updateDoc(formDocRef, {
							locked: deleteField(),
						});
					} else {
						await updateDoc(formDocRef, {
							locked: locked,
						});
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [formDocRef, uid, utilityForm]);

	useEffect(() => {
		if (photoUploadQueue.length > 0) setIsUploading(true);
		else {
			unlockForm();
			setIsUploading(false);
		}

		const isUploading = photoUploadQueue?.some(
			queuedPhoto => queuedPhoto.status === 'UPLOADING'
		);

		if (photoUploadQueue.length === 0 || isUploading) return;

		const nextQueuedPhoto = photoUploadQueue.find(
			queuedPhoto => queuedPhoto.status === 'QUEUED'
		);

		if (nextQueuedPhoto) {
			// Lock the form to prevent other users from submitting
			lockForm();

			// Set photo status as QUEUED
			setPhotoUploadQueue(current =>
				current.map(queuedPhoto => {
					if (queuedPhoto.photo.name === nextQueuedPhoto.photo.name) {
						return { ...queuedPhoto, status: 'UPLOADING' };
					}
					return queuedPhoto;
				})
			);
			uploadFromQueue(nextQueuedPhoto, setFailedPhotoUpload, updateProgress);
		}
	}, [lockForm, photoUploadQueue, unlockForm, uploadFromQueue]);

	useEffect(() => {
		let unsub = () => {};
		if (formId) {
			unsub = onSnapshot(formDocRef, async documentSnapshot => {
				setIsLoading(false);

				if (documentSnapshot.exists()) {
					const form = {
						...(documentSnapshot.data() as UtilityFormV2),
						id: documentSnapshot.id,
					};

					setUtilityForm(form);
				}
			});
		}

		return () => {
			unsub();
		};
	}, [formDocRef, formId]);

	const addPhotosToDownloadQueue = (nodeId: string, images: LocalPhoto[]) => {
		const newPhotos: PhotoV2[] = [];

		images.forEach(image => {
			const uniqueId = uuidv4();
			const extension = getFileExtension(image.originalName || image.uri);
			const uniqueFilename = `${uniqueId}${extension}`;
			const newPhoto: PhotoV2 = {
				originalName: image.originalName || uniqueFilename,
				url: '',
				name: uniqueFilename,
				file: image.uri,
			};

			newPhotos.push(newPhoto);
		});

		const newQueue = newPhotos.map(
			newPhoto =>
				({
					nodeId,
					formId,
					photo: newPhoto,
					projectId: project?.id || '',
					status: 'QUEUED',
					onFinishUpload: addPhotoToFirestore,
				} as QueuedPhotoV2)
		);

		setPhotoUploadQueue(current => [...current, ...newQueue]);
	};

	const resetForm = () => {
		// setFormData([]);
		// clearPhotoQueue();
	};

	const updateValue = useCallback(
		async (nodeId: string, value: any): Promise<void> => {
			if (value === undefined) return;

			const itemRef = doc(db, `utility_forms_v2_items/${nodeId}`);
			await updateDoc(itemRef, {
				value,
				updatedAt: serverTimestamp(),
				updatedBy: {
					id: firebaseAuthData?.uid || '',
					name: user?.fullName || '',
				},
			});
		},
		[firebaseAuthData?.uid, user?.fullName]
	);

	const updateDisplayTitle = async (nodeId: string, displayTitle: any): Promise<void> => {
		if (!displayTitle) return;
		const itemRef = doc(db, `utility_forms_v2_items/${nodeId}`);
		await updateDoc(itemRef, {
			displayTitle,
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
	};

	const addPhotoToFirestore = async (queuedPhoto: QueuedPhotoV2) => {
		const { nodeId } = queuedPhoto;
		const photo = queuedPhoto.photo;
		delete photo.file;
		const photoWithUser = {
			...photo,
			uploadedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
			uploadedAt: new Date(),
		};

		const itemRef = doc(db, `utility_forms_v2_items/${nodeId}`);
		await updateDoc(itemRef, {
			value: arrayUnion(photoWithUser),
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
		setPhotoToRemoveFromQueue(queuedPhoto);
	};

	useEffect(() => {
		if (photoToRemoveFromQueue) {
			setPhotoUploadQueue(current => {
				const filtered = current.filter(
					photo => photo.photo.name !== photoToRemoveFromQueue.photo.name
				);
				return filtered;
			});
		}
	}, [photoToRemoveFromQueue]);

	const deleteNodePhoto = async (nodeId: string, photo: PhotoV2) => {
		try {
			const itemRef = doc(db, `utility_forms_v2_items/${nodeId}`);
			await updateDoc(itemRef, {
				value: arrayRemove(photo),
				updatedAt: serverTimestamp(),
				updatedBy: {
					id: firebaseAuthData?.uid || '',
					name: user?.fullName || '',
				},
			});
		} catch (e) {
			console.error(e);
			setSnackbarProps({
				open: true,
				message: 'There was an error deleting the photo. Please try again.',
				severity: 'error',
			});
		}
	};

	const updatePhotos = async (nodeId: string, photos: PhotoV2[]) => {
		try {
			const itemRef = doc(db, `utility_forms_v2_items/${nodeId}`);
			await updateDoc(itemRef, {
				value: photos,
				updatedAt: serverTimestamp(),
				updatedBy: {
					id: firebaseAuthData?.uid || '',
					name: user?.fullName || '',
				},
			});
		} catch (e) {
			console.error(e);
			setSnackbarProps({
				open: true,
				message: 'There was an error deleting the photo. Please try again.',
				severity: 'error',
			});
		}
	};

	const setFocusedInput = (nodeId: string) => {
		const itemRef = doc(db, `utility_forms_v2_items/${nodeId}`);
		updateDoc(itemRef, {
			focusedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
			focusedAt: serverTimestamp(),
		});
	};

	const removeFocusedInput = (nodeId: string) => {
		const itemRef = doc(db, `utility_forms_v2_items/${nodeId}`);
		updateDoc(itemRef, {
			focusedBy: deleteField(),
			focusedAt: deleteField(),
		});
	};

	const buildDropDownItems = (items: string[]) => {
		return items.map(item => ({ label: item, value: item }));
	};

	useEffect(() => {
		if (failedPhotoUpload) {
			setSnackbarProps({
				open: true,
				message: 'There was an error uploading one or more photos.',
				hideDuration: 5000,
				severity: 'error',
			});

			setPhotoUploadQueue(current =>
				current.filter(
					queuedPhoto => queuedPhoto.photo.name !== failedPhotoUpload.photo.name
				)
			);
		}
	}, [failedPhotoUpload, setSnackbarProps]);

	const deleteNodeFromFirestore = async (nodeId: string) => {
		const itemRef = doc(db, `utility_forms_v2_items/${nodeId}`);
		await deleteDoc(itemRef);
	};

	const getItemType = (type: NodeType): ItemType | undefined =>
		itemTypes.find(i => i.value === type);

	const updateFormName = async (name: string) => {
		await updateDoc(formDocRef, {
			formName: name,
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
	};

	const submitForm = async () => {
		try {
			setIsSubmitting(true);
			await updateDoc(formDocRef, {
				status: 'uploaded',
				format: 'archD',
				lastUpdated: serverTimestamp(),
				uploadedDate: serverTimestamp(),
				submitedBy: {
					id: firebaseAuthData?.uid || '',
					name: user?.fullName || '',
				},
			});
		} catch (e) {
			console.error(e);
			setIsSubmitting(false);
		}
	};

	const submitLetterForm = async () => {
		try {
			setIsSubmitting(true);
			await updateDoc(formDocRef, {
				status: 'uploaded',
				format: 'letter',
				lastUpdated: serverTimestamp(),
				uploadedDate: serverTimestamp(),
				submitedBy: {
					id: firebaseAuthData?.uid || '',
					name: user?.fullName || '',
				},
			});
		} catch (e) {
			console.error(e);
			setIsSubmitting(false);
		}
	};

	const changeFormStatus = async (status: UtilityFormV2Status, formId: string) => {
		if (utilityForm?.status === status) return;

		try {
			await updateDoc(formDocRef, {
				status,
				lastUpdated: serverTimestamp(),
			});
		} catch (e) {
			console.error(e);
		}
	};

	const addNewNode = async (
		displayTitle: string,
		type: NodeType,
		options?: string[],
		parentId?: string
	) => {
		if (!formId) return null;
		const order = Math.floor(new Date().getTime() / 1000);

		const newNode = {
			displayTitle,
			type,
			formId,
			options: options || [],
			parentId: parentId || '',
			createdAt: serverTimestamp(),
			createdBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
			parentsTitle: nodeStack.join(' > ') || '',
			order: order,
			level: nodeStack.length,
			projectId: utilityForm?.projectId || '',
		};

		try {
			const docRef = await addDoc(collection(db, 'utility_forms_v2_items'), newNode);
			await updateDoc(docRef, { id: docRef.id });
			console.log('Node added successfully');
			return docRef.id;
		} catch (error) {
			console.error('Error adding new node: ', error);
			return null;
		}
	};

	const buildTree = (
		nodes: FormNode[],
		parentId: string = '',
		level = 0
	): FormNodeWithChildren[] => {
		return nodes
			.filter(node => node.parentId === parentId)
			.sort((a, b) => (a.order || 0) - (b.order || 0))
			.map(node => {
				const nodeWithChildren: FormNodeWithChildren = {
					...node,
					level,
					children: buildTree(nodes, node.id, level + 1),
				};

				return nodeWithChildren;
			});
	};

	const fetchNodesAsTree = async (): Promise<FormNodeWithChildren[] | undefined> => {
		const projectId = utilityForm?.projectId || '';
		const formId = utilityForm?.id || '';

		if (!projectId || !formId) return;

		const nodesRef = query(
			collection(db, 'utility_forms_v2_items'),
			where('formId', '==', formId),
			where('projectId', '==', projectId)
		);
		const snapshot = await getDocs(nodesRef);

		const nodes = snapshot.docs.map(doc => {
			return { ...doc.data(), id: doc.id } as FormNode;
		});

		const tree = buildTree(nodes);

		console.log(tree.length, nodes.length, snapshot.size);

		return tree;
	};

	// Flatten a tree structure to a list of nodes
	const flattenNodes = (nodes: FormNodeWithChildren[]): FormNodeWithChildren[] => {
		const flattenedNodes: FormNodeWithChildren[] = [];
		nodes.forEach(node => {
			flattenedNodes.push(node);
			flattenedNodes.push(...flattenNodes(node.children));
		});
		return flattenedNodes;
	};

	// Add parent nodes to the list of nodes
	const orderNodes = async (props: {
		nodesToReorder: FormNodeWithChildren[];
		allFormNodes: FormNodeWithChildren[];
		nodeTypeFilter?: string;
	}): Promise<FormNodeWithChildren[]> => {
		const { nodesToReorder, allFormNodes, nodeTypeFilter } = props;
		// Get all parents of the picker nodes
		const parents = nodesToReorder
			.map(node => {
				if (node.parentId)
					return getParents({ parentId: node.parentId, formNodes: allFormNodes });
				return [];
			})
			.flat();

		// Filter out duplicate parent nodes

		// Combine the picker nodes and parent nodes and sort them by order
		const allNodes = [...nodesToReorder, ...parents]
			.filter(
				(parent, index, self) =>
					index === self.findIndex(t => t && parent && t.id === parent.id)
			)
			.sort((a, b) => (a.order || 0) - (b.order || 0));

		// Build the tree structure from the nodes to organize them
		const treeNodes = buildTree(allNodes);

		// Filter out orphan nodes (those with a parent but no children - they moved to the root level of the tree)
		const filteredNodes = treeNodes.filter(
			node => !node.parentId || (node.parentId && node.children.length > 0)
		);

		// Removes all nodes that are not of chosen type
		const flatNodes = flattenNodes(filteredNodes)
			.filter(node => (nodeTypeFilter ? node.type === nodeTypeFilter : true))
			.map((node, index) => ({ ...node, order: index }));

		// Update the nodes with the parent titles
		flatNodes.forEach(node => {
			const parents = getParents({ parentId: node.parentId, formNodes: allFormNodes });
			node.parentsTitle = parents
				.map(parent => parent.displayTitle)
				.reverse()
				.join(' > ');
			node.level = parents.length;
		});

		return flatNodes;
	};

	// Get all parents of a node
	const getParents = ({
		parentId,
		formNodes,
	}: {
		parentId: string;
		formNodes: FormNodeWithChildren[];
	}): FormNodeWithChildren[] => {
		const parents = [];
		let currentParentId = parentId;

		while (currentParentId) {
			const currentId = currentParentId; // Create a new variable to capture the value of currentParentId
			const parent = formNodes.find(node => node.id === currentId); // Use the new variable in the function declaration
			if (parent) parents.push(parent);
			currentParentId = parent?.parentId || '';
		}

		return parents;
	};

	return (
		<FormV2Context.Provider
			value={{
				formId,
				rootNodes,

				orderNodes,
				addPhotosToDownloadQueue,
				addPhotoToFirestore,
				addToNodeStack,
				buildDropDownItems,
				changeFormStatus,
				deleteNodeFromFirestore,
				deleteNodePhoto,
				getItemType,
				isLoading,
				setIsLoading,
				isSubmitting,
				isUploading,
				nodeStack,
				addNewNode,
				photoUploadQueue,
				popFromNodeStack,
				removeFocusedInput,
				resetForm,
				setFocusedInput,
				setIsSubmitting,
				submitForm,
				submitLetterForm,
				updateDisplayTitle,
				updateFormName,
				updatePhotos,
				updateValue,
				utilityForm,
				currentNode,
				setCurrentNode,
				fetchNodesAsTree,
				copiedNodeData,
				setCopiedNodeData,
			}}>
			{children}
		</FormV2Context.Provider>
	);
};

export default FormV2Provider;
