import { Construction } from '@mui/icons-material';
import styled from 'styled-components';
import theme from '../../../../styles/theme';
import React, { useContext, useState } from 'react';
import CreateTemplateDialog from '../../../screen-components/ProjectUtilityFormV2/components/Dialogs/CreateTemplateDialog/CreateTemplateDialog';
import UnarchivePhotosDialog from '../../../screen-components/ProjectUtilityFormV2/components/Dialogs/UnarchivePhotosDialog/UnarchivePhotosDialog';
import { FormV2Context } from '../../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import UnarchiveDialog from '../../../screen-components/ProjectUtilityFormV2/components/Dialogs/UnarchiveDialog/UnarchiveDialog';

import SaveIcon from '@mui/icons-material/Save';
import { mdiArchiveEye, mdiArchiveSync } from '@mdi/js';
import { ButtomMenu, MenuOption, StyledIconButton } from './components';
import { MdiIcon } from '../../../reusable-components/MdiIcon';

export default function Tools() {
	const { utilityForm } = useContext(FormV2Context);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
	const [unarchiveItemsDialogOpen, setUnarchiveItemsDialogOpen] = useState(false);
	const [unarchivePhotosDialogOpen, setUnarchivePhotosDialogOpen] = useState(false);

	return (
		<>
			<StyledIconButton onClick={handleClick} title="Tools">
				<ToolsIcon />
			</StyledIconButton>

			<ButtomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<MenuOption
					onClick={() => {
						setTemplateDialogOpen(true);
						handleClose();
					}}>
					<SaveIcon />
					<span>Save Form as Template</span>
				</MenuOption>
				<MenuOption
					onClick={() => {
						setUnarchiveItemsDialogOpen(true);
						handleClose();
					}}>
					<MdiIcon path={mdiArchiveSync} />
					<span>Unarchive Items</span>
				</MenuOption>
				<MenuOption
					onClick={() => {
						setUnarchivePhotosDialogOpen(true);
						handleClose();
					}}>
					<MdiIcon path={mdiArchiveEye} />
					<span>Unarchive Photos</span>
				</MenuOption>
			</ButtomMenu>

			<CreateTemplateDialog
				open={templateDialogOpen}
				onClose={() => setTemplateDialogOpen(false)}
			/>
			<UnarchiveDialog
				open={unarchiveItemsDialogOpen}
				onClose={() => setUnarchiveItemsDialogOpen(false)}
				formId={utilityForm?.id || ''}
				projectId={utilityForm?.projectId || ''}
			/>
			<UnarchivePhotosDialog
				open={unarchivePhotosDialogOpen}
				onClose={() => setUnarchivePhotosDialogOpen(false)}
				formId={utilityForm?.id || ''}
				projectId={utilityForm?.projectId || ''}
			/>
		</>
	);
}

const ToolsIcon = styled(Construction)`
	color: ${theme.palette.primary.main};
	width: 40px;
`;
