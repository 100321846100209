import { useContext, useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';

import { ProjectContext } from '../../../../context/ProjectProvider';
import ProjectCard from './components/ProjectCard';
import ContractorCard from './components/ContractorCard';
import { createMapOptions } from './google-map-options';

import { db } from '../../../../firebase';
import { storage } from '../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getMetadata, ref } from 'firebase/storage';

import Fab from '@mui/material/Fab';
import InventoryIcon from '@mui/icons-material/Inventory';

import { appBarHeight } from '../../../../components/reusable-components/Layout';
import ContractorArchive from '../ContractorArchive';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import styled from 'styled-components';
import { ProjectStatus } from '../../../../types';
import { MapContext } from '../../../../context/MapProvider';
import LoadingScreen from '../../../../components/reusable-components/LoadingScreen';
import { getCameraIcon, getPinSrc, startingLatLng } from './utils';
import { mapHiddenStatuses } from './utils';
import MapLegend from './components/MapLegend';
import { AuthContext } from '../../../../context/AuthProvider';
import { projectStatuses } from '../../../../utils';

type ProjectMapProps = {
	setShowingDialog: React.Dispatch<React.SetStateAction<boolean>>;
	setShowingCDialog: React.Dispatch<React.SetStateAction<boolean>>;
	initialAddress?: string;
};

// type ProjectMarker = {
// 	id: string | undefined;
// 	name: string;
// 	position: {} | undefined;
// 	project: ProjectData;
// };
type ProjectMarker = any;

/**
 * Functional component that displays a GoogleMap with project and contractor pins
 * @param setShowingDialog useState updater that controls the AddProjectForm dialog
 * @param setShowingCDialog useState updater that controls the AddContractorForm dialog
 */
export default function ProjectMap({
	setShowingDialog,
	setShowingCDialog,
	initialAddress,
}: ProjectMapProps) {
	const { user } = useContext(AuthContext);
	const { contractors } = useContext(ProjectContext);
	const { mapProjects, fetchingMapProjects, googleMap } = useContext(MapContext);

	const [mapCenter, setMapCenter] = useState(startingLatLng);
	const [zoom, setZoom] = useState(5);

	const [projectMarkers, setProjectMarkers] = useState<ProjectMarker[]>([]);
	const [contractorMarkers, setContractorMarkers] = useState<any[]>([]);
	const [zoomLevel, setZoomLevel] = useState(5);

	const [showContractors, setShowContractors] = useState(true);
	const [showProjects, setShowProjects] = useState(true);

	const [activeMarker, setActiveMarker] = useState<string | null>(null);
	const [hoverMarker, setHoverMarker] = useState(null);
	let positionDict: any = {};

	const [showContractorArchive, setShowContractorArchive] = useState(false);

	const [visibleProjectStatuses, setVisibleProjectStatuses] = useState(
		projectStatuses.map(status => ({
			status,
			visible: true,
		}))
	);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_google_maps_apiKey as string,
	});

	useEffect(() => {
		if (isLoaded && initialAddress) {
			getGeocode({ address: initialAddress })
				.then(results => getLatLng(results[0]))
				.then(({ lat, lng }) => {
					setMapCenter({ lat, lng });
					setZoom(15); // Zoom in closer to address that was passed
				})
				.catch(error => console.error('Error: ', error));
		}
	}, [initialAddress, isLoaded]);

	const visibleStatuses = useMemo(() => {
		return visibleProjectStatuses.filter(
			item => !mapHiddenStatuses.includes(item.status)
		);
	}, [visibleProjectStatuses]);

	const statuses = useMemo(() => {
		return user?.isAdmin
			? visibleStatuses
			: visibleStatuses.filter(item => item.status !== 'Proposal Sent');
	}, [user?.isAdmin, visibleStatuses]);

	const handleActiveMarker = (
		markerId: string,
		position: google.maps.LatLng | google.maps.LatLngLiteral
	) => {
		if (markerId !== activeMarker) {
			setActiveMarker(markerId);
			googleMap.current?.panTo(position);
		}
	};

	const getPosition = (marker: any) => {
		return new Promise(resolve => {
			if (marker.address === '' || marker.lat === '' || marker.lng === '') {
				resolve(null);
			} else if (marker.lat && marker.lng) {
				resolve({
					lat: marker.lat,
					lng: marker.lng,
				});
			} else {
				getGeocode({ address: marker.address })
					.then(async results => {
						const { lat, lng } = await getLatLng(results[0]);
						updateDoc(doc(db, 'projects', marker.id), {
							lat: lat,
							lng: lng,
						});
						resolve({
							lat,
							lng,
						});
					})
					.catch(err => {
						console.error(err);
						if (err === 'ZERO_RESULTS') {
							updateDoc(doc(db, 'projects', marker.id), {
								lat: '',
								lng: '',
							});
						}
						resolve(null);
					});
			}
		});
	};

	const getContractorPosition = useCallback((marker: any) => {
		return new Promise((resolve, reject) => {
			// Already has lat/lng
			if (marker.lat && marker.lng) {
				resolve({
					...marker,
					position: {
						lat: marker.lat,
						lng: marker.lng,
					},
				});
			}
			// Needs to geocode lat/lng
			else {
				getGeocode({ address: marker.Address })
					.then(async results => {
						const { lat, lng } = await getLatLng(results[0]);
						updateDoc(doc(db, 'matterport_directory', marker.id), {
							lat: lat,
							lng: lng,
						});
						resolve({
							...marker,
							position: {
								lat: lat,
								lng: lng,
							},
						});
					})
					.catch(err => {
						console.error(`Error grabbing coords for ${marker.Name}:`, err);
						resolve({
							...marker,
							position: {
								lat: 0,
								lng: 0,
							},
						});
					});
			}
		});
	}, []);

	const formatContractor = useCallback(
		(marker: any) => {
			return new Promise((resolve, reject) => {
				(async () => {
					const withPos: any = await getContractorPosition(marker);
					// Contractor has files
					if (
						withPos.hasOwnProperty('files') &&
						Array.isArray(withPos.files) &&
						withPos.files.length > 0
					) {
						const fileLinks: any[] = [];
						// Iterate through files to format them into file links
						withPos.files.forEach((file: string, i: number) => {
							(async () => {
								const fileRef = ref(storage, `/contractors/${withPos.id}/` + file);
								const metaData = await getMetadata(fileRef);
								const url = await getDownloadURL(fileRef);
								fileLinks.push({
									...metaData,
									url,
									path: file,
									idx: i,
								});
								resolve({
									...withPos,
									fileLinks,
								});
							})();
						});
					}
					// Contractor has no files
					else resolve(withPos);
				})();
			});
		},
		[getContractorPosition]
	);

	const createProjectMarkers = useCallback(async () => {
		if (mapProjects && statuses.length) {
			// console.log('create project markers');
			const newMarkers: ProjectMarker[] = [];

			for (const proj of mapProjects) {
				if (statuses.find(({ status }) => status === proj.projectStatus)?.visible) {
					const coords = await getPosition(proj);
					// console.log({ proj, coords });
					const newMarker: ProjectMarker = {
						id: proj.id,
						name: proj.address,
						position: coords ?? undefined,
						project: proj,
					};
					newMarkers.push(newMarker);
				}
			}

			setProjectMarkers(newMarkers);
		}
	}, [mapProjects, statuses]);

	const createContractorMarkers = useCallback(async () => {
		if (contractors.length && user?.isAdmin) {
			// console.log('create contractor markers');
			const newCMarkers: any[] = [];

			for (const contractor of contractors) {
				if (contractor && contractor.Address) {
					const formatted = await formatContractor(contractor);
					newCMarkers.push(formatted);
				}
			}

			setContractorMarkers(newCMarkers);
		}
	}, [contractors, formatContractor, user?.isAdmin]);

	// Create project markers
	useEffect(() => {
		createProjectMarkers();
	}, [createProjectMarkers]);

	// Create contractor markers
	useEffect(() => {
		createContractorMarkers();
	}, [createContractorMarkers]);

	// Repaint clusters when amount of markers changes
	const clustererRef = useRef<any | null>(null);
	useEffect(() => {
		clustererRef.current?.repaint();
	}, [projectMarkers.length]);

	// Reset map hooks after component unmounts
	useEffect(() => {
		return () => {
			setActiveMarker(null);
		};
	}, [setActiveMarker]);

	return (
		<MapContainer>
			{fetchingMapProjects || !isLoaded ? (
				<LoadingScreen message="Loading projects..." />
			) : (
				<GoogleMap
					zoom={zoom}
					center={mapCenter}
					options={{ styles: createMapOptions }}
					onClick={() => setActiveMarker(null)}
					mapContainerStyle={{
						width: '100%',
						height: `calc(100vh - ${appBarHeight})`,
						position: 'relative',
						display: 'flex',
					}}
					onZoomChanged={() => {
						if (googleMap.current?.getZoom()) setZoomLevel(googleMap.current.getZoom()!);
					}}
					onLoad={map => {
						googleMap.current = map;
					}}>
					{showProjects ? (
						<>
							{projectMarkers.map((marker: any, idx) => {
								let pos = marker.position ? { ...marker.position } : undefined;
								const posStr = `${marker.position?.lat} ${marker.position?.lng}`;

								if (!positionDict[posStr]) positionDict[posStr] = 1;
								else {
									if (pos) pos.lng = pos.lng + 0.00005 * positionDict[posStr];
									positionDict[posStr] += 1;
								}

								const defaultSize = { width: 30, height: 42 };
								const modifier = 0.2 + zoomLevel / 30;

								const scaledIconSize = new google.maps.Size(
									defaultSize.width * modifier,
									defaultSize.height * modifier
								);

								return (
									<Marker
										key={idx}
										zIndex={idx + 1}
										position={pos}
										onClick={() => handleActiveMarker(marker.project.id, pos)}
										options={{
											icon: {
												url: getPinSrc(marker.project.projectStatus as ProjectStatus),
												scaledSize: scaledIconSize,
											},
										}}
										title={
											marker.project.address || marker.project.name || 'Unnamed Project'
										}
										onMouseOver={() => setHoverMarker(marker.project.id)}
										onMouseOut={() => setHoverMarker(null)}>
										{activeMarker === marker.project.id ||
										hoverMarker === marker.project.id ? (
											<InfoWindow
												onCloseClick={() => {
													if (activeMarker === marker.project.id) setActiveMarker(null);
												}}
												options={{
													disableAutoPan: hoverMarker === marker.project.id,
												}}>
												<ProjectCard project={marker.project} />
											</InfoWindow>
										) : null}
									</Marker>
								);
							})}
						</>
					) : null}

					{contractorMarkers?.map((marker: any, idx) => {
						let pos = marker.position ? { ...marker.position } : undefined;
						const posStr = `${marker.position.lat} ${marker.position.lng}`;

						if (!positionDict[posStr]) positionDict[posStr] = 1;
						else {
							if (pos) pos.lng = pos.lng + 0.00005 * positionDict[posStr];
							positionDict[posStr] += 1;
						}
						let cameraIconUrl, iconSize;
						const modifier = 0.2 + zoomLevel / 30;

						[cameraIconUrl, iconSize] = getCameraIcon(marker?.['Cameras']);
						const mapsIconsSize = iconSize as google.maps.Size;
						const scaledIconSize = new google.maps.Size(
							mapsIconsSize.width * modifier,
							mapsIconsSize.height * modifier
						);

						return (
							<Marker
								key={marker.id}
								zIndex={idx + 1}
								position={pos}
								onClick={() => handleActiveMarker(marker.id, pos)}
								options={{
									icon: {
										url: cameraIconUrl as string,
										scaledSize: scaledIconSize,
									},
								}}
								visible={showContractors}
								title={marker.Name}
								onMouseOver={() => setHoverMarker(marker.id)}
								onMouseOut={() => setHoverMarker(null)}>
								{activeMarker === marker.id || hoverMarker === marker.id ? (
									<InfoWindow
										onCloseClick={() => {
											if (activeMarker === marker.id) setActiveMarker(null);
										}}
										options={{ disableAutoPan: hoverMarker === marker.id }}>
										<ContractorCard capture={marker} setCMarkers={setContractorMarkers} />
									</InfoWindow>
								) : null}
							</Marker>
						);
					})}

					<MapLegend
						statuses={statuses}
						showContractors={showContractors}
						setShowContractors={setShowContractors}
						showProjects={showProjects}
						setShowProjects={setShowProjects}
						setShowingDialog={setShowingDialog}
						setShowingCDialog={setShowingCDialog}
						setVisibleProjectStatuses={setVisibleProjectStatuses}
					/>

					{user?.isAdmin ? (
						<ContractorArchiveButton
							title="View contractor archive"
							onClick={() => setShowContractorArchive(true)}>
							<InventoryIcon />
						</ContractorArchiveButton>
					) : null}
				</GoogleMap>
			)}

			<ContractorArchive
				showingDialog={showContractorArchive}
				setShowingDialog={setShowContractorArchive}
			/>
		</MapContainer>
	);
}

const MapContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const ContractorArchiveButton = styled(Fab)`
	height: 40px;
	width: 40px;

	position: absolute;
	right: 10px;
	bottom: 210px;
`;
