import { FC, PropsWithChildren, useContext } from 'react';
import { FormNodeWrapperProps } from './FormNodeWrapper.types';
import {
	WrapperContainer,
	WrapperHeader,
	WrapperIconButton,
	WrapperNode,
	WrapperNodeContainer,
	WrapperTitleContainer,
} from './FormNodeWrapper.styles';
import { ActionButton, CommentsButton, FlagButton } from './buttons';
import { FormTextEditField } from '../FormTextEditField';
import { MenuReorderContext } from '../../screen-components/Form/Form';
import { Box } from '@mui/system';
import { TemplateContext } from '../../../context/TemplateProvider';

const FormNodeWrapper: FC<PropsWithChildren<FormNodeWrapperProps>> = ({
	node,
	children,
}) => {
	const { selectedItem } = useContext(MenuReorderContext);
	const { isTemplate } = useContext(TemplateContext);

	const showHeader = !['node', 'placeholder'].includes(node.type);

	return (
		<WrapperContainer
			flag={node.flag}
			nodeType={node.type}
			level={node.level}
			id={node.id}
			isSelected={selectedItem?.id === node.id}>
			{showHeader && (
				<WrapperHeader>
					{isTemplate ? (
						<Box width={'40px'}></Box>
					) : (
						<WrapperIconButton>
							<FlagButton node={node} />
						</WrapperIconButton>
					)}
					<WrapperTitleContainer>
						<FormTextEditField
							node={node}
							nodeField="displayTitle"
							isFormTitle
							showInfo
						/>
					</WrapperTitleContainer>
					{!isTemplate && (
						<WrapperIconButton>
							<CommentsButton node={node} />
						</WrapperIconButton>
					)}
					<WrapperIconButton>
						<ActionButton node={node} />
					</WrapperIconButton>
				</WrapperHeader>
			)}
			<WrapperNodeContainer>
				{showHeader && <WrapperIconButton />}
				<WrapperNode>{children}</WrapperNode>
				{showHeader && (
					<>
						<WrapperIconButton />
						<WrapperIconButton />
					</>
				)}
			</WrapperNodeContainer>
		</WrapperContainer>
	);
};

export default FormNodeWrapper;
