import React, { FC, useEffect, useState } from 'react';
import { IconButton, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { GalleryImage } from './PhotoGalleryDialog.styles';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Flag,
	PhotoV2,
} from '../../../screen-components/ProjectUtilityFormV2/utils/types';
import Carousel from '../../../reusable-components/Carousel';
import { getFlagStyle } from '../../../screen-components/ProjectUtilityFormV2/utils/utils';
import theme from '../../../../styles/theme';
import { Dialog } from '../../Dialog';
import { StyledMenu } from '../../StyledMenu';
import { Flag as FlagIcon, RemoveCircle } from '@mui/icons-material';
import { completedColor, reviewColor } from '../../FormNodeWrapper/buttons/FlagButton';

interface PhotoGalleryDialogProps {
	open: boolean;
	photos: PhotoV2[];
	initialIndex: number;
	onClose: () => void;
	onDelete: (index: number) => void;
	setFlag: (index: number, flag: Flag) => void;
}

const PhotoGalleryDialog: FC<React.PropsWithChildren<PhotoGalleryDialogProps>> = ({
	open,
	photos,
	initialIndex,
	onClose,
	onDelete,
	setFlag,
}) => {
	const [currentIndex, setCurrentIndex] = useState(initialIndex);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);

	useEffect(() => {
		setCurrentIndex(initialIndex);
	}, [initialIndex]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = () => {
		onDelete(currentIndex);

		if (photos.length <= 1) {
			onClose();
		} else if (currentIndex === photos.length - 1) {
			setCurrentIndex(currentIndex - 1);
		}
		handleClose();
	};

	const handleChangeIndex = (newIndex: number) => {
		setCurrentIndex(newIndex);
	};

	const handleSetFlag = (flag: Flag) => {
		setFlag(currentIndex, flag);
		handleClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title={photos[currentIndex]?.originalName || ''}
			fullWidth
			fullHeight
			actions={
				<>
					<IconButton
						aria-label="settings"
						aria-controls={openMenu ? 'settings-menu' : undefined}
						aria-expanded={openMenu ? 'true' : undefined}
						onClick={handleClick}
						style={{ color: theme.palette.primary.main }}>
						<SettingsIcon />
					</IconButton>
					<StyledMenu
						id="settings-menu"
						anchorEl={anchorEl}
						open={openMenu}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'settings-button',
						}}>
						<MenuItem onClick={handleDelete}>
							<ListItemIcon>
								<DeleteIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Delete Photo</ListItemText>
						</MenuItem>
						{photos[currentIndex]?.flag && (
							<MenuItem onClick={() => handleSetFlag('')}>
								<ListItemIcon>
									<RemoveCircle style={{ color: 'grey' }} />
								</ListItemIcon>
								Remove flag
							</MenuItem>
						)}
						{photos[currentIndex]?.flag !== 'review' && (
							<MenuItem onClick={() => handleSetFlag('review')}>
								<ListItemIcon>
									<FlagIcon fontSize="small" sx={{ color: reviewColor }} />
								</ListItemIcon>
								Mark as needs review
							</MenuItem>
						)}
						{photos[currentIndex]?.flag !== 'completed' && (
							<MenuItem onClick={() => handleSetFlag('completed')}>
								<ListItemIcon>
									<FlagIcon fontSize="small" sx={{ color: completedColor }} />
								</ListItemIcon>{' '}
								Mark as completed
							</MenuItem>
						)}
					</StyledMenu>
				</>
			}>
			<Carousel
				captureWindowKeypresses
				controlledIndex={currentIndex}
				setControlledIndex={handleChangeIndex}>
				{photos.map((photo, index) => (
					<GalleryImage
						key={index}
						src={photo.url || photo.thumbnail}
						alt="Gallery View"
						style={getFlagStyle(photo.flag)}
					/>
				))}
			</Carousel>
		</Dialog>
	);
};

export default PhotoGalleryDialog;
