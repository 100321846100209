import { useContext } from 'react';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { ProjectInfoTextField } from '../../styled-components';

export default function StringInput({
	field,
	id,
	type = 'text',
	className,
	onBlur,
	onChange,
	isActive,
	inactiveDisplay = '',
	multiline = false,
}: {
	field:
		| 'subTotal'
		| 'internalNotes'
		| 'address'
		| 'name'
		| 'buildingSize'
		| 'lastContacted'
		| 'storeNumber'
		| 'description'
		| 'registrationNotes'
		| 'modelingScope'
		| 'vrDeliveryTimeline'
		| 'photoReportTimeline';
	id?: string;
	type?: string;
	className?: string;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	isActive: boolean;
	inactiveDisplay?: string;
	multiline?: boolean;
}) {
	const { project } = useContext(ProjectContext);

	const fieldValue = project?.[field];

	return isActive ? (
		<ProjectInfoTextField
			name={field}
			id={id}
			type={type}
			className={className}
			defaultValue={project?.[field] || ''}
			onBlur={onBlur}
			onChange={onChange}
			fullWidth
			multiline={multiline}
		/>
	) : !multiline ? (
		<div className="fieldInfo">{inactiveDisplay || fieldValue}</div>
	) : (
		<p className="fieldInfoParagraph">{inactiveDisplay || fieldValue}</p>
	);
}
