import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import {
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	doc,
	onSnapshot,
	setDoc,
	updateDoc,
} from 'firebase/firestore';
import { db } from '../../../../../../firebase';
import { ProjectScopeData } from '../types';
import { SnackContext } from '../../../../../../context/SnackProvider';

type ProjectScopeContextType = {
	projectScopeDocRef: DocumentReference<DocumentData, DocumentData> | null;
	projectScopeDoc: React.MutableRefObject<DocumentSnapshot<
		DocumentData,
		DocumentData
	> | null>;
	projectScopeData: ProjectScopeData;
	handleRemoveScope: (category: string, sectionTitle: string, scopeName: string) => void;
	handleAddScope: (category: string, sectionTitle: string, scope: string) => void;
	editScopeData: ProjectScopeData;
	setEditScopeData: React.Dispatch<React.SetStateAction<ProjectScopeData>>;
	handleSubmit: (
		cachedSectionTitle: string,
		category: string,
		handleClose: () => void
	) => Promise<void>;
	disableSubmit: boolean;
};

export const ProjectScopeContext = createContext({} as ProjectScopeContextType);

export const ProjectScopeProvider = ({ children }: { children?: React.ReactNode }) => {
	const { project } = useContext(ProjectContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [projectScopeData, setProjectScopeData] = useState<ProjectScopeData>({});
	const [editScopeData, setEditScopeData] = useState(projectScopeData);
	const [disableSubmit, setDisableSubmit] = useState(false);

	const projectScopeDocRef = project?.id
		? doc(db, `projects/${project?.id}/private/projectScopes`)
		: null;
	const projectScopeDoc = useRef<DocumentSnapshot<DocumentData, DocumentData> | null>(
		null
	);

	// Fetch project scope data from Firestore and update state
	useEffect(() => {
		let unsub = () => {};
		if (project?.id) {
			unsub = onSnapshot(
				doc(db, `projects/${project?.id}/private/projectScopes`),
				snap => {
					if (snap.exists()) {
						projectScopeDoc.current = snap;
						const scopeData = snap.data() as ProjectScopeData;
						setProjectScopeData(scopeData);
					} else {
						projectScopeDoc.current = null;
						setProjectScopeData({});
					}
				}
			);
		}

		return () => {
			unsub();
		};
	}, [project?.id]);

	// Update editScopeData whenever projectScopeData changes
	useEffect(() => {
		setEditScopeData(projectScopeData);
	}, [projectScopeData]);

	// Add a new scope to the project scope data
	const handleAddScope = (category: string, sectionTitle: string, scope: string) => {
		setEditScopeData(prev => ({
			...prev,
			[category]: {
				...prev[category],
				[sectionTitle]: [
					...(prev[category]?.[sectionTitle] || []),
					{ scopeName: scope, value: false },
				],
			},
		}));
	};

	// Remove a scope from the project scope data
	const handleRemoveScope = (
		category: string,
		sectionTitle: string,
		scopeName: string
	) => {
		setEditScopeData(prev => {
			const updatedCategory = {
				...prev[category],
				[sectionTitle]: prev[category][sectionTitle].filter(
					scope => scope.scopeName !== scopeName
				),
			};
			return { ...prev, [category]: updatedCategory };
		});
	};

	// Submit changes to project scope data to Firestore
	const handleSubmit = async (
		cachedSectionTitle: string,
		category: string,
		handleClose: () => void
	) => {
		const noScopes = !editScopeData;

		setDisableSubmit(true);
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Saving changes...',
		});

		if (projectScopeDocRef) {
			if (projectScopeDoc.current?.exists()) {
				await updateDoc(projectScopeDocRef, {
					...editScopeData,
				});
			} else if (!noScopes) {
				await setDoc(projectScopeDocRef, {
					...editScopeData,
				});
			}
		}

		handleClose();
		setDisableSubmit(false);
		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});
	};

	return (
		<ProjectScopeContext.Provider
			value={{
				projectScopeDocRef,
				projectScopeDoc,
				projectScopeData,
				handleAddScope,
				handleRemoveScope,
				editScopeData,
				setEditScopeData,
				handleSubmit,
				disableSubmit,
			}}>
			{children}
		</ProjectScopeContext.Provider>
	);
};
