import Layout from '../../components/reusable-components/Layout';
import { V2FormGalleryProvider } from './V2FormGalleryProvider';
import V2FormGalleryRenderer from './components/V2FormGalleryRenderer';

export default function CaptureFormGalleryV2() {
	return (
		<Layout>
			<V2FormGalleryProvider>
				<V2FormGalleryRenderer />
			</V2FormGalleryProvider>
		</Layout>
	);
}
