import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { CoverImage } from '../components/screen-components/ProjectUtilityFormV2/utils/types';
import {
	CoverImageProps,
	DeleteCoverImageProps,
	UploadCoverImagesProps,
} from '../components/form-components/CoverImage/CoverImage.types';

export const uploadCoverImages = async ({
	oldURL,
	name,
	formId,
	projectId,
	storageFilepath,
	onProgress,
	newFile,
}: UploadCoverImagesProps) => {
	const formRef = doc(db, `utility_forms_v2`, formId);
	const oldRef = ref(storage, oldURL);
	const imageRef = ref(storage, `${storageFilepath}/${name}`);
	const formSnapshot = await getDoc(formRef);
	const bucketType = 'coverImage';
	const bucketData: CoverImage[] = formSnapshot.exists()
		? formSnapshot.data()[bucketType]
		: [];
	const metadata = {
		customMetadata: {
			originalFilename: name,
			projectId: projectId,
		},
	};

	const file = newFile
		? newFile
		: await fetch(await getDownloadURL(oldRef)).then(res => res.blob());

	const uploadTask = uploadBytesResumable(imageRef, file, metadata);
	!!oldURL && (await deleteObject(oldRef));
	await uploadTask.on(
		'state_changed',
		snapshot => {
			const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			onProgress(progress);
		},
		error => {
			console.log(error);
		},
		() => {
			getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
				const formRef = doc(db, 'utility_forms_v2', formId);
				const updateData = bucketData.filter((item: CoverImageProps) => {
					if (item.downloadURL === oldURL) {
						item.downloadURL = downloadURL;
						item.name = name;
						item.filepath = storageFilepath;
						return item;
					}
					return item;
				});
				!oldURL && updateData.push({ name, downloadURL, filepath: storageFilepath });
				const updateObject = {
					[bucketType]: updateData,
				};
				updateDoc(formRef, updateObject)
					.then(() => {
						console.log(`${bucketType} URL updated in Firestore`);
					})
					.catch(error => {
						console.error('Error updating document: ', error);
					});
			});
		}
	);
};

export const deleteCoverImage = async ({
	downloadURL,
	formId,
}: DeleteCoverImageProps) => {
	const imageRef = ref(storage, downloadURL);
	const docRef = doc(db, `utility_forms_v2`, formId);
	const data = (await getDoc(docRef)).data() || {};
	const currentArray = data.coverImage;
	const updatedArray = currentArray.filter(
		(item: CoverImageProps) => item.downloadURL !== downloadURL
	);
	await deleteObject(imageRef);
	await updateDoc(docRef, { coverImage: updatedArray });
};

export const checkExistingFiles = async ({
	name,
	formId,
}: {
	name: string;
	formId: string;
}) => {
	const docRef = doc(db, `utility_forms_v2`, formId);
	const data = (await getDoc(docRef)).data() || {};
	const currentArray = data.coverImage;
	const existingFile = currentArray.find((item: CoverImageProps) => item.name === name);
	return !!existingFile;
};

export const uploadClientLogo = async ({
	name,
	oldURL,
	formId,
	projectId,
	storageFilepath,
	onProgress,
	newFile,
}: {
	name: string;
	oldURL: string;
	formId: string;
	projectId: string;
	storageFilepath: string;
	onProgress: (progress: number) => void;
	newFile: Blob | Uint8Array | ArrayBuffer;
}) => {
	const formRef = doc(db, `utility_forms_v2`, formId);
	const oldRef = ref(storage, oldURL);
	const imageRef = ref(storage, `${storageFilepath}/${name}`);
	const bucketType = 'clientLogo';
	const metadata = {
		customMetadata: {
			originalFilename: name,
			projectId: projectId,
		},
	};

	const uploadTask = uploadBytesResumable(imageRef, newFile, metadata);
	!!oldURL && (await deleteObject(oldRef));
	await uploadTask.on(
		'state_changed',
		snapshot => {
			const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			onProgress(progress);
		},
		error => {
			console.log(error);
		},
		() => {
			getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
				updateDoc(formRef, {
					[bucketType]: downloadURL,
				})
					.then(() => {
						console.log(`${bucketType} URL updated in Firestore`);
					})
					.catch(error => {
						console.error('Error updating document: ', error);
					});
			});
		}
	);
};

export const deleteClientLogo = async ({
	downloadURL,
	formId,
}: DeleteCoverImageProps) => {
	const imageRef = ref(storage, downloadURL);
	const docRef = doc(db, `utility_forms_v2`, formId);
	await deleteObject(imageRef);
	await updateDoc(docRef, { clientLogo: '' });
};
