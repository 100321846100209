import { useState, useContext, useRef } from 'react';
import { Row, useAsyncDebounce } from 'react-table';
import styled from 'styled-components';

import {
	MenuItem,
	TextField,
	Button,
	FormControlLabel,
	Switch,
	Autocomplete,
	IconButton,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';

import { SnackContext } from '../../../../../../context/SnackProvider';
import { LeadsContext } from '../../../LeadsProvider';

import { Lead } from '../../../../types';
import { updateLeads } from '../../../../../../firebase';
import AddLeadDialog from './components/AddLeadDialog';
import { leadStatuses } from '../../../../utils';
import MassComments from './components/MassComments';
import LeadsExportButton from '../LeadsExportButton';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../../../../context/AuthProvider';
import { UserObj } from '../../../../../../types';

type ActionBarProps = {
	setGlobalFilter: (filterValue: any) => void;
	filterByAccountManager: React.Dispatch<React.SetStateAction<UserObj | undefined>>;
	filterByLeadStatus: React.Dispatch<React.SetStateAction<string>>;
	selectedRows: Row<Lead>[];

	showGraphs: boolean;
	setShowGraphs: React.Dispatch<React.SetStateAction<boolean>>;

	filterByClient: React.Dispatch<React.SetStateAction<string[]>>;
	isFilterVisible: boolean;
	setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
	toggleAllRowSelection: (value?: boolean | undefined) => void;
};

export default function ActionBar({
	setGlobalFilter,
	filterByAccountManager,
	filterByLeadStatus,
	selectedRows,

	showGraphs,
	setShowGraphs,

	filterByClient,
	isFilterVisible,
	setIsFilterVisible,
	toggleAllRowSelection,
}: ActionBarProps) {
	const [, setSearchParams] = useSearchParams();

	const { setSnackbarProps } = useContext(SnackContext);
	const { projectRoleOptions } = useContext(AuthContext);
	const { setLeads, clientList } = useContext(LeadsContext);

	const [filterValue, setFilterValue] = useState('');

	const debounceGlobalFilter = useAsyncDebounce(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setGlobalFilter(e.target.value || undefined);
		},
		200
	);

	const handleFilterChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFilterValue(e.target.value);
		debounceGlobalFilter(e);
	};
	const clearFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setFilterValue('');
		setGlobalFilter('');
		inputRef.current?.focus();
	};

	const [openLeadDialog, setOpenLeadDialog] = useState(false);

	const showFilters = () => setIsFilterVisible(!isFilterVisible);

	const massChange = async (field: string, value: any) => {
		setSnackbarProps({
			open: true,
			message: 'Submitting changes...',
			severity: 'warning',
			hideDuration: null,
		});

		const selectedLead = selectedRows.map(row => row.original);
		const selectedLeadIds: string[] = [];
		for (const lead of selectedLead) {
			if (lead.id) selectedLeadIds.push(lead.id);
		}
		setLeads(prev => {
			const tmp = [...prev];
			let idx = 0;
			const newDate = new Date();

			for (const id of selectedLeadIds) {
				idx = tmp.findIndex(lead => lead.id === id);
				const updatedLead: Lead = {
					...tmp[idx],
					[field]: value,
					lastActivity: newDate,
				};
				tmp.splice(idx, 1, updatedLead);
			}
			return tmp;
		});
		await updateLeads({
			ids: selectedLeadIds,
			updatedFields: {
				[field]: value,
			},
		});

		setSnackbarProps({
			open: true,
			message: 'Changes submitted!',
			severity: 'success',
		});
	};

	const inputRef = useRef<HTMLInputElement>();

	return (
		<ActionBarContainer>
			<ActionBarRow>
				<Searchbar
					placeholder="Search leads..."
					variant="standard"
					value={filterValue}
					onChange={handleFilterChange}
					InputProps={{
						endAdornment: filterValue ? (
							<ClearFilterButton onClick={clearFilter}>
								<ClearIcon />
							</ClearFilterButton>
						) : null,
					}}
					inputRef={inputRef}
				/>

				<YellowButton onClick={() => setOpenLeadDialog(true)}>
					<AddIcon />
					Add Lead
				</YellowButton>

				{/* New button added to drop down leads filter options */}
				<YellowButton onClick={showFilters}>
					{isFilterVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
					Filter Leads
				</YellowButton>

				<GraphToggleContainer>
					<FormControlLabel
						control={
							<Switch
								checked={showGraphs}
								onChange={e => {
									setShowGraphs(e.target.checked);
									setSearchParams(prev => ({
										...prev,
										graphs: `${e.target.checked}`,
									}));
								}}
							/>
						}
						label="Graph"
					/>
				</GraphToggleContainer>

				<AddLeadDialog open={openLeadDialog} setOpen={setOpenLeadDialog} />
			</ActionBarRow>

			{isFilterVisible && ( //shows the Filter row if the button is clicked
				<ActionBarRow>
					<ClientFilterComponent>
						{/* Autocomplete Component so that users can filter by one or more Clients  */}
						<Autocomplete
							id="client-filter-box"
							multiple //supports multiple selections
							freeSolo //allows users to add non-provided options
							options={clientList}
							onChange={(e, value) => {
								const selectedClients = Array.isArray(value) ? value : [value];
								filterByClient(selectedClients);
							}}
							renderInput={params => (
								<TextField
									{...params}
									variant="standard"
									placeholder="Filter by client"
									sx={{ flexGrow: 1, minWidth: '140px' }}
									InputProps={{
										...params.InputProps,
										sx: { textAlign: 'left' },
										className: 'filter-input',
									}}
								/>
							)}
						/>
					</ClientFilterComponent>

					<SpecialDropdown>
						<span>Account Manager:</span>
						<TextField
							id="filterBoxes"
							select
							SelectProps={{ displayEmpty: true }}
							defaultValue={undefined}
							onChange={e => {
								const selectedId = e.target.value;
								if (typeof selectedId === 'string') {
									const selectedManager = projectRoleOptions['accountManager'].find(
										userWithData => userWithData.id === selectedId
									);
									if (selectedManager) {
										const userObj: UserObj = {
											email: selectedManager.email,
											name: selectedManager.fullName,
											id: selectedManager.id,
										};
										filterByAccountManager(userObj);
									} else {
										filterByAccountManager({ email: '', name: '', id: '' });
									}
								} else {
									filterByAccountManager(undefined);
								}
							}}
							variant="standard">
							<MenuItem value={undefined}>All Managers</MenuItem>
							<MenuItem value={''}>Unassigned</MenuItem>
							{projectRoleOptions['accountManager'].map(userWithData => (
								<MenuItem value={userWithData.id} key={userWithData.id}>
									{userWithData.fullName}
								</MenuItem>
							))}
						</TextField>
					</SpecialDropdown>

					<SpecialDropdown>
						<span>Status:</span>
						<TextField
							id="filterBoxes"
							select
							SelectProps={{ displayEmpty: true }}
							defaultValue={''}
							onChange={e => {
								filterByLeadStatus(e.target.value);
							}}
							variant="standard">
							<MenuItem value={''}>All Statuses</MenuItem>
							{leadStatuses.map((leadStatus, i) => (
								<MenuItem value={leadStatus} key={i}>
									{leadStatus}
								</MenuItem>
							))}
						</TextField>
					</SpecialDropdown>
				</ActionBarRow>
			)}

			{selectedRows.length ? (
				<ActionBarRow spaceBetween>
					<LeadsSelected>
						<span>
							{selectedRows.length === 1
								? `${selectedRows.length} lead selected`
								: `${selectedRows.length} leads selected`}
						</span>
						<YellowButton onClick={() => toggleAllRowSelection(false)}>
							De-select all
						</YellowButton>
					</LeadsSelected>

					<MassChangeContainer>
						<SelectDropdown>
							<TextField
								label="Mass Change Status"
								select
								size="small"
								defaultValue=""
								sx={{ minWidth: '190px' }}
								onChange={e => massChange('status', e.target.value)}
								variant="outlined">
								{leadStatuses.map((leadStatus, i) => (
									<MenuItem value={leadStatus} key={i}>
										{leadStatus}
									</MenuItem>
								))}
							</TextField>
						</SelectDropdown>

						<SelectDropdown>
							<TextField
								label="Mass Change Account Manager"
								select
								size="small"
								defaultValue=""
								sx={{ minWidth: '190px' }}
								onChange={e => {
									const selectedId = e.target.value;
									if (selectedId) {
										const selectedManager = projectRoleOptions['accountManager'].find(
											userWithData => userWithData.id === selectedId
										);
										if (selectedManager) {
											const userObj: UserObj = {
												email: selectedManager.email,
												name: selectedManager.fullName,
												id: selectedManager.id,
											};
											massChange('accountManager', userObj);
										}
									} else {
										massChange('accountManager', null);
									}
								}}
								variant="outlined">
								{projectRoleOptions['accountManager'].map(userWithData => (
									<MenuItem value={userWithData.id} key={userWithData.id}>
										{userWithData.fullName}
									</MenuItem>
								))}
							</TextField>
						</SelectDropdown>

						<SelectDropdown>
							<TextField
								label="Mass Change Client"
								select
								size="small"
								defaultValue=""
								sx={{ minWidth: '190px' }}
								onChange={e => massChange('client', e.target.value)}
								variant="outlined">
								{clientList.map((client: string, i: number) => (
									<MenuItem value={client} key={i}>
										{client}
									</MenuItem>
								))}
							</TextField>
						</SelectDropdown>

						<MassComments selectedRows={selectedRows} setLeads={setLeads} />

						<LeadsExportButton selectedRows={selectedRows} />
					</MassChangeContainer>
				</ActionBarRow>
			) : null}
		</ActionBarContainer>
	);
}

const ActionBarContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	overflow-x: auto;

	background-color: rgb(17, 17, 17);

	top: 0;
	position: sticky;
	z-index: 1;
`;

const ActionBarRow = styled.div<{ spaceBetween?: boolean }>`
	display: flex;
	gap: 10px;
	width: 100%;
	min-height: 40px;
	// align-items: center
	justify-content: ${props => (props.spaceBetween ? 'space-between' : 'flex-start')};

	& > div {
		background-color: white;
		padding: 5px;
		border-radius: 10px;
		display: flex;
		// align-items: center;
	}
`;

const Searchbar = styled(TextField)`
	flex-grow: 1;
	min-width: 140px;
`;

const ClearFilterButton = styled(IconButton)`
	height: 24px;
	width: 24px;
`;

const YellowButton = styled(Button)`
	background-color: #ffb310;
	color: black;
	flex-shrink: 0;
	border-radius: 10px;
	line-height: normal;

	&:hover {
		background-color: #ffb310;
	}
`;

const ClientFilterComponent = styled.div`
	display: flex;
	flex-grow: 1;

	.MuiAutocomplete-root {
		width: 100%; /* Set the width to 100% to stretch through the entire container */
	}

	.filter-input {
		color: '#9aaab6';
	}
`;

const SpecialDropdown = styled.div`
	display: flex;
	align-items: center;
	min-height: 40px;
	gap: 10px;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		/* min-width: 100px; */
		margin-top: auto; //aligns the text to the bottom of the div
	}
`;

const SelectDropdown = styled.div`
	display: flex;
	flex-shrink: 0;
	gap: 10px;
	align-items: center;
`;

const GraphToggleContainer = styled.div`
	& > label {
		margin: 0px;
		padding-right: 5px;
	}
`;

const LeadsSelected = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	line-height: normal;
`;

const MassChangeContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	padding-left: 10px;
`;
