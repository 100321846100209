import { Close, Refresh, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, styled } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import logoSrc from '../../../../../../assets/circleLogo.png';
import { CSSProperties } from 'styled-components';

type SectionHeaderProps = {
	children: React.ReactNode;
	onRefresh?: (query: string) => void;
	onSearch?: (query: string) => void;
	showLogo?: boolean;
	propQuery?: string;
	setPropQuery?: React.Dispatch<React.SetStateAction<string>>;
	headerStyle?: CSSProperties;
	headerComponents?: React.ReactNode;
};

const SectionHeader: FC<SectionHeaderProps> = ({
	children,
	onRefresh,
	onSearch,
	showLogo = true,
	propQuery,
	setPropQuery,
	headerStyle,
	headerComponents,
}) => {
	const [searchVisible, setSearchVisible] = useState(
		typeof propQuery === 'string' ? propQuery : false
	);
	const [searchQuery, setSearchQuery] = useState(
		typeof propQuery === 'string' ? propQuery : ''
	);

	useEffect(() => {
		if (!searchVisible && !setPropQuery) {
			setSearchQuery('');
			onSearch?.('');
		}
	}, [onSearch, searchVisible, setPropQuery]);

	const debounce = useRef<number | null>(null);
	useEffect(() => {
		if (setPropQuery) {
			if (typeof debounce.current === 'number') {
				window.clearTimeout(debounce.current);
			}
			debounce.current = window.setTimeout(() => {
				debounce.current = null;
				setPropQuery(searchQuery);
			}, 500);
		}
	}, [searchQuery, setPropQuery]);

	const inputRef = useRef<HTMLInputElement | undefined>();

	return (
		<Header style={headerStyle}>
			{showLogo && <img src={logoSrc} alt="" style={{ height: 18, marginRight: 10 }} />}
			<Title>{children}</Title>
			{onSearch ? (
				<SearchContainer>
					<SearchInput
						size="small"
						inputRef={inputRef}
						onChange={e => {
							onSearch(e.target.value);
							setSearchQuery(e.target.value);
						}}
						value={searchQuery}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search color="primary" />
								</InputAdornment>
							),
						}}
						sx={{
							color: 'white',
							'& .MuiInputBase-input': {
								color: 'white',
							},
						}}
						style={{ display: searchVisible ? 'flex' : 'none' }}
					/>
					<IconButton
						size="small"
						onClick={() =>
							setSearchVisible(prev => {
								if (!prev) window.setTimeout(() => inputRef.current?.focus(), 100);
								if (prev) setSearchQuery('');
								return !prev;
							})
						}>
						{searchVisible ? <Close color="primary" /> : <Search color="primary" />}
					</IconButton>
					{onRefresh ? (
						<IconButton size="small" onClick={() => onRefresh(searchQuery)}>
							<Refresh color="primary" />
						</IconButton>
					) : null}

					{headerComponents}
				</SearchContainer>
			) : null}
		</Header>
	);
};

export default SectionHeader;

const SearchContainer = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	top: 6px;
	right: 10px;
`;

const SearchInput = styled(TextField)`
	background-color: #1e1e23;
	width: 250px;
	border-radius: 5px;
`;

const Title = styled('div')`
	font-size: 1.2rem;
	line-height: 1.2rem;
	color: white;
	margin-top: 4px;
	font-weight: bold;
	width: 100%;
`;

const Header = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 14px;
	margin: 0;
	background-color: #000000;
	position: relative;
`;
