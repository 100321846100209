import { Timestamp } from 'firebase/firestore';
import { UtilityFormStatus } from '../../ProjectUtilityForm/types';
import { SharedUser } from '../../ProjectInfo/components/RightNavButtons/types';

export type NodeType =
	| 'input'
	| 'area'
	| 'select'
	| 'switch'
	| 'date'
	| 'datetime'
	| 'photos'
	| 'node'
	| 'placeholder';

export type FormNode = {
	id: string;
	displayTitle: string;
	formId: string;
	parentId: string;
	type: NodeType;
	options?: string[];
	value?: boolean | string | PhotoV2[] | Timestamp;
	focusedBy?: {
		name: string;
		id: string;
	};
	focusedAt?: Timestamp;
	createdAt?: Timestamp;
	createdBy?: {
		name: string;
		id: string;
	};
	updatedAt?: Timestamp;
	updatedBy?: {
		name: string;
		id: string;
	};
	parentsTitle?: string;
	// New node will get big order numbers because we use the current time
	// in seconds as the order number. This is to ensure that the new node
	// is always at the bottom of the list.
	order?: number;
	level?: number;
	flag?: Flag;
	projectId: string;
	info?: string;
	comments?: CommentType[];
	displayOrder?: number;
};

export type Flag = 'completed' | 'progress' | 'review' | '' | undefined;

export type FormNodeWithChildren = FormNode & {
	children: FormNodeWithChildren[];
	selected?: boolean;
};

export type CommentType = {
	id: string;
	comment: string;
	authorName: string;
	createdAt?: Timestamp;
	authorId: string;
	taggedUsers?: SharedUser[];
};

export type CopiedNode = {
	parentId: string; // id of the node being pasted into
	itemId: string; // id of the node being copied
	formId: string; // form id to be able to fetch all items
};

export type QueuedPhotoV2 = {
	photo: PhotoV2;
	nodeId: string;
	projectId: string;
	formId: string;
	status?: 'QUEUED' | 'UPLOADING' | 'UPLOADED' | 'ERROR';
	onFinishUpload: (photo: QueuedPhotoV2) => void;
};

export type PhotoV2 = {
	originalName: string;
	name: string;
	file?: string;
	url?: string;
	thumbnail?: string;
	uploadedAt?: Timestamp;
	flag?: Flag;
};

export type ArchivedPhotoV2 = PhotoV2 & {
	selected?: boolean;
	archivedAt: Timestamp;
	id: string;
};

export type ItemType = {
	label: string;
	value: string;
	key: NodeType;
};

export type LocalPhoto = {
	originalName?: string;
	uri: string;
};

export type FormTemplate = {
	id: string;
	name: string;
	nodes: FormNode[];
	createdAt: Timestamp;
	createdBy: {
		name: string;
		id: string;
	};
	updatedAt: Timestamp;
	updatedBy: {
		name: string;
		id: string;
	};
	info?: string;
	teamIds: string[];
	searchableName: string[];
	projectIds: string[];
	organizationIds: string[];
};

export interface UtilityFormV2 {
	createdDate?: Timestamp;
	uploadedDate?: Timestamp;
	formName: string;
	id: string;
	lastUpdated?: Timestamp;
	projectId: string;
	status: UtilityFormV2Status;
	v2?: boolean;
	sitePhoto?: string;
	siteMap?: string;
	siteAerial?: string;
	clientLogo?: string;
	matterportLink?: string;
	locked?: LockedForm;
	createdBy?: {
		name: string;
		id: string;
	};
	submitedBy?: {
		name: string;
		id: string;
	};
	downloadUrlVirtual?: string;
	report?: boolean;
	coverImage?: CoverImage[];
}

interface LockedForm {
	[key: string]: {
		at: Timestamp;
		by: string;
	};
}

export type UtilityFormV2Status =
	| 'draft'
	| 'uploaded'
	| 'processing'
	| 'processing failed'
	| 'completed'
	| 'archived';

export type UtilityFormDetailsForV2 = {
	id: string;
	status: UtilityFormStatus;
	formName: string;
	createdDate: Timestamp | null;
	lastUpdated: Timestamp;
	uploadedDate: Timestamp | null;
	creator: string;
	editor?: string;
	canView?: boolean;
	projectId: string;
};

export const itemTypes: ItemType[] = [
	{ label: 'Field', value: 'input', key: 'input' },
	{ label: 'Multiline Field', value: 'area', key: 'area' },
	{ label: 'Photos', value: 'photos', key: 'photos' },
	{ label: 'Switch', value: 'switch', key: 'switch' },
	{ label: 'Picker', value: 'select', key: 'select' },
	{ label: 'Date', value: 'date', key: 'date' },
	{ label: 'Date and Time', value: 'datetime', key: 'datetime' },
	{ label: 'Container', value: 'node', key: 'node' },
];
export const itemTypesWithSection: ItemType[] = [
	{ label: 'Field', value: 'input', key: 'input' },
	{ label: 'Multiline Field', value: 'area', key: 'area' },
	{ label: 'Photos', value: 'photos', key: 'photos' },
	{ label: 'Switch', value: 'switch', key: 'switch' },
	{ label: 'Picker', value: 'select', key: 'select' },
	{ label: 'Date', value: 'date', key: 'date' },
	{ label: 'Date and Time', value: 'datetime', key: 'datetime' },
	{ label: 'Section', value: 'node', key: 'node' },
];

export type DocumentFormat = 'archD' | 'letter';

export type CoverImage = {
	downloadURL: string;
	name: string;
	filepath: string;
};
