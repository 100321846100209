import React, { useEffect, useRef, useState } from 'react';
import { IconButton, ListItemIcon, MenuItem } from '@mui/material';
import { Flag, FlagOutlined, RemoveCircle } from '@mui/icons-material';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase';
import { FormNode } from '../../../../screen-components/ProjectUtilityFormV2/utils/types';
import theme from '../../../../../styles/theme';
import { StyledMenu } from '../../../StyledMenu';
import { completedColor, progressColor, reviewColor } from './FlagButtons.styles';

type FlagButtonProps = {
	node: FormNode;
};

export default function FlagButton({ node }: FlagButtonProps) {
	const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);
	const actionMenuOpen = Boolean(actionAnchorEl);

	const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) =>
		setActionAnchorEl(event.currentTarget as unknown as HTMLElement);

	const _isMounted = useRef(true);
	useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		};
	}, []);

	const handleCloseActionMenu = () => {
		if (_isMounted.current) setActionAnchorEl(null);
	};

	const handleSetFlag = async (flag: string) => {
		handleCloseActionMenu();

		if (node.id) {
			await updateDoc(doc(db, 'utility_forms_v2_items', node.id), {
				flag,
			});
		}
	};

	const getFlag = () => {
		switch (node.flag) {
			case 'progress':
				return <Flag style={{ color: progressColor }} />;
			case 'review':
				return <Flag style={{ color: reviewColor }} />;
			case 'completed':
				return <Flag style={{ color: completedColor }} />;
			default:
				return <FlagOutlined sx={{ color: theme.palette.grey[500] }} />;
		}
	};

	return (
		<>
			<IconButton onClick={handleClick}>{getFlag()}</IconButton>

			<StyledMenu
				anchorEl={actionAnchorEl}
				open={actionMenuOpen}
				onClose={handleCloseActionMenu}>
				{node.flag && (
					<MenuItem onClick={() => handleSetFlag('')}>
						<ListItemIcon>
							<RemoveCircle style={{ color: 'grey' }} />
						</ListItemIcon>
						Remove flag
					</MenuItem>
				)}
				{node.flag !== 'progress' && (
					<MenuItem onClick={() => handleSetFlag('progress')}>
						<ListItemIcon>
							<Flag style={{ color: progressColor }} />
						</ListItemIcon>
						Mark as in progress
					</MenuItem>
				)}
				{node.flag !== 'review' && (
					<MenuItem onClick={() => handleSetFlag('review')}>
						<ListItemIcon>
							<Flag style={{ color: reviewColor }} />
						</ListItemIcon>
						Mark as needs review
					</MenuItem>
				)}
				{node.flag !== 'completed' && (
					<MenuItem onClick={() => handleSetFlag('completed')}>
						<ListItemIcon>
							<Flag style={{ color: completedColor }} />
						</ListItemIcon>
						Mark as completed
					</MenuItem>
				)}
			</StyledMenu>
		</>
	);
}
