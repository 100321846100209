import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FileBucket, FileType } from '../../types';
import { FirebaseFile, ProjectData, ZipFile } from '../../../../../types';

import { AuthContext } from '../../../../../context/AuthProvider';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { deleteProjectFile, moveProjectFile } from '../../../../../firebase';
import { downloadUploadedFiles } from '../../../../../utils/project-file-download';
import { getDeliverableDialogBucketTitle } from '../../utils';
import TitleButtons from './components/TitleButtons';
import { FullWidthDialogContent } from '../../../../styled-components/dialog';
import Spinner, { SpinnerContainer } from '../../../Spinner';
import { checkPermissions } from '../../../../../utils';
import FileRow from './components/FileRow';
import { LoadingContext } from '../../../../../context/LoadingProvider';
import { updateProjectFields } from '../../../../../firebase/firestore/projects';
import useDownloadZippedFiles from '../../../../../utils/useDownloadZippedFiles/useDownloadZippedFiles';
import DownloadMessage from './components/DownloadMessage';

const formatFileTimestamps = (file: any) => {
	if (file.uploadDate._seconds)
		return {
			...file,
			uploadDate: new Date(file.uploadDate._seconds * 1000),
			lastUpdated: new Date(file.lastUpdated._seconds * 1000),
		} as FirebaseFile;
	else return file;
};

export default function ProjectFilesDialog({
	deliverableKey,
	showingDialog,
	setShowingDialog,
	uploadFunction,

	handleClose = () => {},
	downloadOnly = false,
	anonUpload = false,
	downloadFunction,
	projectProp,
}: {
	deliverableKey: FileType;
	showingDialog: boolean;
	setShowingDialog: React.Dispatch<React.SetStateAction<boolean>>;
	uploadFunction: (files: File[]) => void;

	handleClose?: () => void;
	downloadOnly?: boolean;
	anonUpload?: boolean;
	downloadFunction?: () => Promise<void>;
	projectProp?: ProjectData;
}) {
	const { setLoadingStatus } = useContext(LoadingContext);
	const { user } = useContext(AuthContext);
	const { project, setProject } = useContext(ProjectContext);
	const { downloadZippedFiles } = useDownloadZippedFiles();
	const [downloadMessage, setDownloadMessage] = useState<string>('');

	const projectData: ProjectData | undefined = useMemo(() => {
		return project || projectProp;
	}, [project, projectProp]);

	const [selectedBucket, setSelectedBucket] = useState<FileBucket>('uploaded');
	const [fileList, setFileList] = useState(projectData?.[deliverableKey] ?? []);
	const [editingOrder, setEditingOrder] = useState(false);

	const isAnonymous = anonUpload || downloadOnly;
	// const onIndividualProjectPage = !projectProp;

	const getBucketFiles = useCallback(
		(input: FileBucket) => {
			switch (input) {
				case 'inProgress':
					return projectData?.[`${deliverableKey}InProgress`] ?? [];
				case 'archive':
					return projectData?.[`${deliverableKey}Archive`] ?? [];
				default:
					return projectData?.[deliverableKey] ?? [];
			}
		},
		[projectData, deliverableKey]
	);
	useEffect(() => {
		setFileList(getBucketFiles(selectedBucket).map(file => formatFileTimestamps(file)));
	}, [getBucketFiles, selectedBucket]);

	const getDocKey = (input: FileBucket): keyof ProjectData => {
		switch (input) {
			case 'inProgress':
				return `${deliverableKey}InProgress`;
			case 'archive':
				return `${deliverableKey}Archive`;
			default:
				return deliverableKey;
		}
	};

	const changePreviewFile = async (i: number) => {
		if (projectData) {
			// Re-order file list.
			const temp = [...fileList];
			const newMain = temp.splice(i, 1)[0];
			const updatedData: Partial<ProjectData> = {
				[deliverableKey]: [newMain, ...temp],
			};

			// Update project document and state.
			setLoadingStatus({ title: 'Changing preview order...' });
			await updateProjectFields(projectData, updatedData, setProject);
			setLoadingStatus(null);
		} else {
			throw new Error(
				`No project data present for DeliverableFilesDialog component with deliverableKey '${deliverableKey}'...`
			);
		}
	};

	const moveFile = async (i: number, destination: FileBucket) => {
		if (projectData) {
			const originDocKey = getDocKey(selectedBucket);
			const destinationDocKey = getDocKey(destination);
			const temp = [...getBucketFiles(selectedBucket)];
			const fileToMove = temp.splice(i, 1)[0];

			moveProjectFile({
				projectId: projectData.id,
				filepath: fileToMove.filepath,
				origin: originDocKey,
				destination: destinationDocKey,
			});

			const destinationArr = (projectData[destinationDocKey] as FirebaseFile[]) || [];
			const updatedProj = {
				...projectData,
				[originDocKey]: temp,
				[destinationDocKey]: [...destinationArr, fileToMove],
			};
			setProject(updatedProj);
		} else {
			throw new Error(
				`No project data present for DeliverableFilesDialog component with deliverableKey '${deliverableKey}'...`
			);
		}
	};

	const deleteFile = (i: number) => {
		const file = fileList[i];
		if (projectData) {
			if (window.confirm(`Permanently delete ${file.filename}?`)) {
				// Update states
				const temp = [...fileList];
				temp.splice(i, 1);
				const bucketKey = deliverableKey + 'Archive';
				const updatedProject = {
					...projectData,
					[bucketKey]: temp,
				};
				setProject(prev => {
					if (prev) return updatedProject;
					else return prev;
				});

				// Delete file from storage and firebase
				deleteProjectFile({
					projectId: projectData.id,
					deliverableKey: bucketKey,
					filepath: file.filepath,
				});
			}
		} else {
			throw new Error(
				`No project data present for DeliverableFilesDialog component with deliverableKey '${deliverableKey}'...`
			);
		}
	};

	const handleDownloadAll = async () => {
		if (downloadFunction) {
			await downloadFunction();
		} else if (projectData) {
			const fileArr = projectData[deliverableKey] || [];
			await downloadUploadedFiles(fileArr);
		}
	};

	const handleZip = async () => {
		if (projectData) {
			const fileArr: ZipFile[] =
				projectData[deliverableKey]?.map((file, index) => ({
					name: file.filename,
					url: file.downloadUrl,
					order: index,
				})) || [];
			console.log('fileArr', fileArr);
			await downloadZippedFiles(
				fileArr,
				`${deliverableKey} - ${projectData?.address}.zip`,
				message => setDownloadMessage(message)
			);
			setDownloadMessage('');
			onClose();
		}
	};

	const archiveAll = async () => {
		if (projectData) {
			// Empty 'uploaded' file array and add them to the 'Archive' array.
			const uploadedArr = [...getBucketFiles('uploaded')];
			const archiveArr = projectData[`${deliverableKey}Archive`] || [];
			const updatedData: Partial<ProjectData> = {
				[deliverableKey]: [],
				[`${deliverableKey}Archive`]: [...archiveArr, ...uploadedArr],
			};

			// Update project document and state.
			setLoadingStatus({ title: 'Archiving files...' });
			await updateProjectFields(projectData, updatedData, setProject);
			setLoadingStatus(null);
		} else {
			throw new Error(
				`No project data present for DeliverableFilesDialog component with deliverableKey '${deliverableKey}'...`
			);
		}
	};

	const handleFileUploadChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files: File[] = Array.from(e.target.files || []);
		onClose();
		uploadFunction(files);
	};

	// Resets the dialog view after opening the dialog again
	useEffect(() => {
		if (showingDialog) {
			setSelectedBucket('uploaded');
			setEditingOrder(false);
		}
	}, [showingDialog]);

	const onClose = () => {
		setShowingDialog(false);
		handleClose();
	};

	return (
		<Dialog open={showingDialog} onClose={onClose}>
			<StyledTitle>
				<span>
					{!editingOrder
						? getDeliverableDialogBucketTitle(selectedBucket, deliverableKey)
						: 'Editing Preview Order'}
				</span>

				<TitleButtons
					projectData={projectData}
					deliverableKey={deliverableKey}
					anonUpload={anonUpload}
					downloadOnly={downloadOnly}
					editingOrder={editingOrder}
					setEditingOrder={setEditingOrder}
					selectedBucket={selectedBucket}
					setSelectedBucket={setSelectedBucket}
				/>
			</StyledTitle>

			<FullWidthDialogContent>
				{projectData ? (
					fileList.length ? (
						<FileList>
							{fileList.map((file, fileIndex) => {
								return (
									<FileRow
										file={file}
										fileIndex={fileIndex}
										field={getDocKey(selectedBucket)}
										selectedBucket={selectedBucket}
										projectData={projectData}
										changeFirstPreview={changePreviewFile}
										moveFile={moveFile}
										deleteFile={deleteFile}
										editingOrder={editingOrder}
										isAnonymous={isAnonymous}
										key={file.filename + fileIndex}
									/>
								);
							})}
						</FileList>
					) : (
						<h3 style={{ margin: '0' }}>No files to display...</h3>
					)
				) : (
					<SpinnerContainer>
						<Spinner />
					</SpinnerContainer>
				)}
			</FullWidthDialogContent>

			<DialogActions>
				<DownloadMessage message={downloadMessage} />
				{!downloadMessage && (
					<>
						{fileList.length &&
						(projectData?.projectStatus !== 'Pending Payment' ||
							checkPermissions(user, 'contractor')) &&
						selectedBucket === 'uploaded' ? (
							<Button color="primary" onClick={handleZip}>
								Download Zip
							</Button>
						) : null}

						{fileList.length &&
						(projectData?.projectStatus !== 'Pending Payment' ||
							checkPermissions(user, 'contractor')) &&
						selectedBucket === 'uploaded' ? (
							<Button color="primary" onClick={handleDownloadAll}>
								Download All Files
							</Button>
						) : null}

						{checkPermissions(user, 'admin') &&
						selectedBucket === 'uploaded' &&
						fileList?.length &&
						!isAnonymous ? (
							<Button color="primary" onClick={() => archiveAll()}>
								Archive All
							</Button>
						) : null}

						{!downloadOnly && selectedBucket === 'uploaded' ? (
							<>
								<input
									id="deliverable-dialog-upload"
									type="file"
									accept={
										deliverableKey === 'pdf'
											? '.pdf,application/pdf,.doc,.docx,application/msword'
											: ''
									}
									multiple
									onChange={e => handleFileUploadChange(e)}
									hidden
								/>
								<Button
									color="primary"
									onClick={() =>
										document.getElementById('deliverable-dialog-upload')?.click()
									}>
									Upload Files
								</Button>
							</>
						) : null}

						<Button onClick={onClose} color="primary">
							Close
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
}

const StyledTitle = styled(DialogTitle)`
	display: flex;
	justify-content: space-between;
`;

const FileList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
