import React, {
	useContext,
	useState,
	useEffect,
	useCallback,
	useMemo,
	useRef,
} from 'react';
import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useTable,
	useRowSelect,
	useFlexLayout,
	useRowState,
	HeaderGroup,
} from 'react-table';
import { AuthContext } from '../../../../../context/AuthProvider';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import {
	ProjectData,
	ProjectQueryResult,
	ProjectStatus,
	UserObj,
} from '../../../../../types';
import { formatProjectTimestamps, numberStrToMoney } from '../../../../../utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
	TableWrapper,
	Table,
	HeaderRow,
	HeaderCell,
	StatusHeaderCellInner,
	BottomRow,
	Total,
	TotalTitle,
	Pagination,
	PaginationButton,
} from '../../../../styled-components/styledReactTable';
import { getStatusColumns, sortableColumns } from './columns';
import Spinner from '../../../Spinner';
import styled from 'styled-components';
import {
	getProjectStatusCounts,
	getStatusTotal,
	queryProjects,
} from '../../../../../firebase';
import { ProjectStatusCountState } from '../../../../../hooks/useProjectStatusCount/reducer';
import SkeletonTable from '../../../SkeletonTable';
import { LoadingContext } from '../../../../../context/LoadingProvider';
import { StatusTableContext } from '../../StatusTableProvider';
import StatusTableRow from './components/StatusTableRow';
import { defaultPageSize } from './utils';

type StatusTableProps = {
	status: ProjectStatus;
	query: string;
	accountManagerFilter: UserObj | null;
};

/**
 * Functional component that displays a table and the projects of a specific status.
 * @param status A string of type ProjectStatus
 * @param width string value for width field of the style of the container of the StatusTable component (i.e. '100%')
 */
export default function StatusTable({
	status,
	query,
	accountManagerFilter,
}: StatusTableProps) {
	const {
		loadingProjects,
		setLoadingProjects,
		loadingProjectStatuses,
		setLoadingProjectStatuses,
	} = useContext(LoadingContext);

	const { projectStatusCount, setProjectStatusCount } = useContext(ProjectContext);

	const { user } = useContext(AuthContext);
	const { statusProjects, setStatusProjects } = useContext(StatusTableContext);

	const [total, setTotal] = useState(0);
	const [loadingTotal, setLoadingTotal] = useState(false);

	const [controlledPageIndex, setControlledPageIndex] = useState(0);
	const [controlledPageSize, setControlledPageSize] = useState(defaultPageSize);

	const [loadingNextPage, setLoadingNextPage] = useState(false);

	const showLowerLeftTotal = useMemo(
		() => user?.isAdmin && !['Sample', 'Template', 'Archive'].includes(status),
		[status, user?.isAdmin]
	);

	const totalCount = useRef(projectStatusCount[status]);
	useEffect(() => {
		totalCount.current = projectStatusCount[status];
	}, [projectStatusCount, status]);

	const statusColumns = useMemo(
		() => getStatusColumns(status, user, setStatusProjects),
		[status, user, setStatusProjects]
	);

	const [sortObj, setSortObj] = useState<{
		field: keyof ProjectData;
		order: 'asc' | 'desc';
	} | null>(null);

	const handleSortClick = (field: keyof ProjectData) => {
		setSortObj(prev => {
			if (prev?.field === field) {
				if (prev.order === 'asc') {
					return {
						field,
						order: 'desc',
					};
				} else {
					return null;
				}
			} else {
				return {
					field,
					order: 'asc',
				};
			}
		});
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		pageCount,
		setPageSize,
		gotoPage,
	} = useTable(
		{
			data: statusProjects,
			columns: statusColumns,
			initialState: {
				pageSize: controlledPageSize,
				pageIndex: controlledPageIndex,
				hiddenColumns: user?.isContractor
					? ['Sub-Total', 'Internal Notes', 'Invoice Link', 'Signed Proposal']
					: [],
			},
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		useRowSelect,
		useFlexLayout,
		useRowState
	);

	const cachedLength = useRef(statusProjects.length);
	useEffect(() => {
		cachedLength.current = statusProjects.length;
	}, [statusProjects]);

	const pageMin = controlledPageIndex * controlledPageSize + 1;
	const pageMax =
		controlledPageIndex + 1 === pageCount
			? statusProjects.length
			: (controlledPageIndex + 1) * controlledPageSize;

	const canNextPage = pageMax < (projectStatusCount[status] || 0);
	const canPreviousPage = pageMin !== 1;

	useEffect(() => {
		setPageSize(controlledPageSize);
	}, [controlledPageSize, setPageSize]);

	useEffect(() => {
		gotoPage(controlledPageIndex);
	}, [controlledPageIndex, gotoPage]);

	// Cached the states so that only the appropriate useEffect triggers when a state is changed.
	const cachedQuery = useRef<string | null>(null);
	const cachedStatus = useRef(status);
	const cachedAccountManager = useRef(accountManagerFilter);
	const cachedPageSize = useRef(controlledPageSize);
	const cachedPageIndex = useRef(controlledPageIndex);
	const cachedSortObj = useRef(sortObj);

	// Cache the time at which queries are made so that the state is only updated by
	// the latest queries.
	const cachedQueryTime = useRef(0);
	const cachedStatusTime = useRef(0);
	const cachedTotalTime = useRef(0);

	// TODO: Refactor table to use actual html <table> elements and implement fixed header/footer.
	// TODO: Make sure no stale hooks are updated after component dismounts.

	const fetchSetProjects = useCallback(
		async (
			queryStr: string,
			projectStatus: ProjectStatus,
			limit: number,
			offset: number,
			accountManager: UserObj | null,
			sortFilter: {
				field: keyof ProjectData;
				order: 'asc' | 'desc';
			} | null
		) => {
			if (limit > 0) {
				const time = new Date().getTime();
				cachedQueryTime.current = time;

				const sortStr = sortFilter
					? sortFilter.field + '._seconds:' + sortFilter.order
					: '';

				const res = await queryProjects({
					searchMode: 'individual-status',
					query: queryStr,
					projectStatus: projectStatus,
					limit: limit,
					offset: offset,
					accountManager: accountManager,
					sortFilter: sortStr,
				});
				const fetchedProjects = (res.data as ProjectQueryResult).projects;

				if (cachedQueryTime.current === time) {
					setStatusProjects(prev => {
						if (offset === 0) return formatProjectTimestamps(fetchedProjects);
						else return [...(prev || []), ...formatProjectTimestamps(fetchedProjects)];
					});
				}
			}
		},
		[setStatusProjects]
	);

	const fetchSetStatusCounts = useCallback(
		async (queryStr: string, accountManager: UserObj | null) => {
			setLoadingProjectStatuses(true);

			const time = new Date().getTime();
			cachedStatusTime.current = time;

			const res = await getProjectStatusCounts({
				query: queryStr,
				accountManager: accountManager,
			});

			if (cachedStatusTime.current === time) {
				const newCount = res.data as ProjectStatusCountState;
				setProjectStatusCount(newCount);
				setLoadingProjectStatuses(false);
			}
		},
		[setLoadingProjectStatuses, setProjectStatusCount]
	);

	const fetchSetStatusTotal = useCallback(
		async (queryStr: string, status: ProjectStatus, accountManager: UserObj | null) => {
			if (showLowerLeftTotal) {
				setLoadingTotal(true);
				const time = new Date().getTime();
				cachedTotalTime.current = time;

				const res = await getStatusTotal({
					query: queryStr,
					projectStatus: status,
					accountManager: accountManager,
				});

				if (cachedTotalTime.current === time) {
					const total = res.data as number;
					setTotal(total);
					setLoadingTotal(false);
				}
			}
		},
		[showLowerLeftTotal]
	);

	// Handle query changes which triggers project/status count fetches and status total fetch.
	// Will handle initial project fetch and status count fetch.
	useEffect(() => {
		// Only trigger effects on initial load or when the query changes.
		if (query !== cachedQuery.current) {
			// Update cached query.
			cachedQuery.current = query;

			// Get new status counts.
			fetchSetStatusCounts(query, cachedAccountManager.current);

			// Get new projects.
			setLoadingProjects(true);
			fetchSetProjects(
				query,
				cachedStatus.current,
				cachedPageSize.current * 2,
				0,
				cachedAccountManager.current,
				cachedSortObj.current
			).then(() => {
				// Set cached page index to zero before changing page index so that the changed
				// index does not trigger more fetches.
				cachedPageIndex.current = 0;
				setControlledPageIndex(0);
				setLoadingProjects(false);
			});

			// Get status total.
			fetchSetStatusTotal(query, cachedStatus.current, cachedAccountManager.current);
		}
	}, [
		fetchSetProjects,
		fetchSetStatusCounts,
		fetchSetStatusTotal,
		query,
		setLoadingProjects,
	]);

	// Handles 'sortObj' changes.
	useEffect(() => {
		if (
			cachedSortObj.current?.field !== sortObj?.field ||
			cachedSortObj.current?.order !== sortObj?.order
		) {
			// Updated cached 'sortObj'.
			cachedSortObj.current = sortObj;

			// Get new projects.
			setLoadingProjects(true);
			fetchSetProjects(
				cachedQuery.current || '',
				cachedStatus.current,
				cachedPageSize.current * 2,
				0,
				cachedAccountManager.current,
				sortObj
			).then(() => {
				// Set cached page index to zero before changing page index so that the changed
				// index does not trigger more fetches.
				cachedPageIndex.current = 0;
				setControlledPageIndex(0);
				setLoadingProjects(false);
			});
		}
	}, [fetchSetProjects, setLoadingProjects, sortObj]);

	// Handles accountManager filter changes.
	useEffect(() => {
		if (cachedAccountManager.current !== accountManagerFilter) {
			// Update cached accountManager.
			cachedAccountManager.current = accountManagerFilter;

			// Get new status counts.
			fetchSetStatusCounts(cachedQuery.current || '', accountManagerFilter);

			// Get new projects.
			setLoadingProjects(true);
			fetchSetProjects(
				cachedQuery.current || '',
				cachedStatus.current,
				cachedPageSize.current * 2,
				0,
				accountManagerFilter,
				cachedSortObj.current
			).then(() => {
				// Set cached page index to zero before changing page index so that the changed
				// index does not trigger more fetches.
				cachedPageIndex.current = 0;
				setControlledPageIndex(0);
				setLoadingProjects(false);
			});

			// Get status total.
			fetchSetStatusTotal(
				cachedQuery.current || '',
				cachedStatus.current,
				accountManagerFilter
			);
		}
	}, [
		fetchSetProjects,
		fetchSetStatusCounts,
		fetchSetStatusTotal,
		accountManagerFilter,
		setLoadingProjects,
	]);

	// Handles status changes.
	useEffect(() => {
		// When the status changes, reset the page index to 0.
		if (status !== cachedStatus.current) {
			// Update cached status.
			cachedStatus.current = status;

			const offset = 0;

			setLoadingProjects(true);
			fetchSetProjects(
				cachedQuery.current || '',
				status,
				cachedPageSize.current,
				offset,
				cachedAccountManager.current,
				null
			).then(() => {
				// Update page index before changing the hooks so that no additional
				// fetches are triggered.
				cachedPageIndex.current = offset;
				setControlledPageIndex(offset);

				// Update 'cachedSortObject' and 'sortObject' because sorting should be reset after switching
				// to a different project status;
				cachedSortObj.current = null;
				setSortObj(null);

				setLoadingProjects(false);
			});

			// Get status total.
			fetchSetStatusTotal(
				cachedQuery.current || '',
				status,
				cachedAccountManager.current
			);
		}
	}, [fetchSetProjects, fetchSetStatusTotal, setLoadingProjects, status]);

	// Handles page index changes.
	useEffect(() => {
		// Only fetch more projects if the new project index is greater than the old one and
		// if not all the projects were fetched.
		if (
			controlledPageIndex > cachedPageIndex.current &&
			cachedLength.current < (totalCount.current || 0)
		) {
			setLoadingNextPage(true);
			fetchSetProjects(
				cachedQuery.current || '',
				cachedStatus.current,
				cachedPageSize.current,
				cachedLength.current,
				cachedAccountManager.current,
				cachedSortObj.current
			).then(() => {
				setLoadingNextPage(false);
			});
		}
		// Update cached page index.
		cachedPageIndex.current = controlledPageIndex;
	}, [controlledPageIndex, fetchSetProjects]);

	// Handles page size changes.
	useEffect(() => {
		// Calculate the new page index such that the top row of the current page will also be
		// present in the new page.
		const newPageIndex = Math.floor(
			(cachedPageIndex.current * cachedPageSize.current) / controlledPageSize
		);

		// Calculate how many projects should be loaded after the page size is updated.
		const limit = (newPageIndex + 1) * controlledPageSize + controlledPageSize;

		// Only fetch projects if the amount of projects already fetched is less than how many projects
		// should be fetched and if not all projects were fetched.
		if (
			cachedLength.current < limit &&
			cachedLength.current < (totalCount.current || 0)
		) {
			setLoadingProjects(true);
			fetchSetProjects(
				cachedQuery.current || '',
				cachedStatus.current,
				limit,
				cachedLength.current,
				cachedAccountManager.current,
				cachedSortObj.current
			).then(() => {
				// Update the cached page index to the new page index before changing the hook
				// so that no additional fetches are triggered.
				cachedPageIndex.current = newPageIndex;
				setControlledPageIndex(newPageIndex);
				setLoadingProjects(false);
			});
		} else {
			// Update the page index cache and hook even if no new projects were fetched.
			cachedPageIndex.current = newPageIndex;
			setControlledPageIndex(newPageIndex);
		}
		// Updated cached page size.
		cachedPageSize.current = controlledPageSize;
	}, [controlledPageSize, fetchSetProjects, setLoadingProjects]);

	const headerRef = useRef<HTMLDivElement>(null);
	const bodyRef = useRef<HTMLDivElement>(null);

	const handleScroll = () => {
		if (bodyRef.current && headerRef.current) {
			const { scrollLeft } = bodyRef.current;
			headerRef.current.scrollLeft = scrollLeft;
		}
	};

	return (
		<Container>
			{statusProjects.length > 0 || loadingProjects || loadingProjectStatuses ? (
				!loadingProjects && !loadingProjectStatuses ? (
					<TableWrapper {...getTableProps()}>
						{headerGroups.map(headerGroup => (
							<HeaderRow
								{...headerGroup.getHeaderGroupProps({ style: { minWidth: '100%' } })}
								ref={headerRef}>
								{headerGroup.headers.map((col, idx) => {
									const column = col as HeaderGroup<ProjectData> & {
										projectField: keyof ProjectData | null;
									};
									const canSort = column.projectField
										? sortableColumns.includes(column.projectField)
										: false;
									const isSorted = sortObj?.field === column.projectField;
									const isSortedDesc = isSorted && sortObj?.order === 'desc';

									return (
										<HeaderCell key={idx} style={{ width: column.width }}>
											<StatusHeaderCellInner
												canSort={canSort}
												isSorted={isSorted}
												{...column.getHeaderProps()}
												onClick={() => {
													if (canSort && column.projectField)
														handleSortClick(column.projectField);
												}}
												title={canSort ? `Sort table by ${column.Header}` : ''}>
												{column.render('Header')}
												{isSorted ? (
													isSortedDesc ? (
														<ExpandMoreIcon />
													) : (
														<ExpandLessIcon />
													)
												) : null}
											</StatusHeaderCellInner>
										</HeaderCell>
									);
								})}
							</HeaderRow>
						))}

						<Table ref={bodyRef} onScroll={handleScroll}>
							<div {...getTableBodyProps()}>
								{page.map(row => {
									prepareRow(row);
									return <StatusTableRow row={row} key={row.original.id} />;
								})}
							</div>
						</Table>

						<BottomRow>
							{showLowerLeftTotal ? (
								<Total>
									<TotalTitle>Total:</TotalTitle>

									{!loadingTotal ? (
										<span>{numberStrToMoney(total.toFixed(2))}</span>
									) : (
										<Spinner size={50} />
									)}
								</Total>
							) : null}

							{projectStatusCount[status] !== null ? (
								<Pagination>
									<div>Rows per page:</div>

									<select
										value={controlledPageSize}
										// onChange={e => configurePageSize(Number(e.target.value))}
										onChange={e => setControlledPageSize(Number(e.target.value))}>
										{[defaultPageSize, 25, 50, 100].map(num => (
											<option key={num} value={num}>
												{num}
											</option>
										))}
									</select>

									{/* <div>{page.length > 0 && `${pageMin()}-${pageMax()} of ${pageRows()}`}</div> */}
									<div>{`${pageMin}-${pageMax} of ${projectStatusCount[status]}`}</div>

									<PaginationButton
										disabled={!canPreviousPage}
										onClick={() => {
											if (canPreviousPage) setControlledPageIndex(prev => prev - 1);
											// previousPage();
										}}>
										<ChevronLeftIcon />
									</PaginationButton>

									{!loadingNextPage ? (
										<PaginationButton
											disabled={!canNextPage}
											onClick={() => {
												if (canNextPage) {
													setControlledPageIndex(prev => prev + 1);
													// nextPage();
												}
											}}>
											<ChevronRightIcon />
										</PaginationButton>
									) : (
										<Spinner size={50} />
									)}
								</Pagination>
							) : null}
						</BottomRow>
					</TableWrapper>
				) : (
					<SkeletonTable />
				)
			) : (
				<NoProjectsContainer>
					<span>No displayable projects.</span>
				</NoProjectsContainer>
			)}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	overflow: hidden;

	width: 100%;
	min-height: 0;
	max-height: 100%;
`;

const NoProjectsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100%;
	width: 100%;

	font-size: 1.5rem;
	color: white;
`;
