import React, { Fragment, useState, FC, useContext, useEffect } from 'react';
import { Dialog, StyledTextField } from '../../../../components/form-components';
import { Box, InputAdornment, Typography } from '@mui/material';
import {
	StyledButtonText,
	StyledCircularProgress,
} from '../../../../components/form-components/CoverImage/CoverImageStyles';
import { AuthContext } from '../../../../context/AuthProvider';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { ProjectData } from '../../../../types';
import { ProjectTable } from './ProjectTable';
import useFormName from '../../../V2FormsList/useFormName';
import { Close, Search } from '@mui/icons-material';
import { SnackContext } from '../../../../context/SnackProvider';
import { TemplateContext } from '../../../../context/TemplateProvider';

export const FormDialog: FC<{ open: boolean; handleClose: () => void }> = ({
	open,
	handleClose,
}) => {
	const { user } = useContext(AuthContext);
	const { tempId } = useContext(TemplateContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const { handleAddName } = useFormName();

	const [formName, setFormName] = useState<string>('');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [step, setStep] = useState<number>(0);
	const [projects, setProjects] = useState<any[]>([]);
	const [filteredProjects, setFilteredProjects] = useState<any[]>([]);
	const [selectedProject, setSelectedProject] = useState<string[]>(['']);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [disableButton, setDisableButton] = useState<boolean>(true);

	const handleCancel = () => {
		setStep(0);
		setFormName('');
		setSelectedProject(['']);
		setSearchQuery('');
		setFilteredProjects([]);
		setDisableButton(false);
		handleClose();
	};
	const handleNext = () => {
		setStep(step + 1);
	};

	const handleSelectProject = (id: string) => {
		setSelectedProject(selectedProject[0] === id ? [''] : [id]);
	};
	const handleAssign = async () => {
		setDisableButton(true);
		await handleAddName(formName, 'template', selectedProject[0], tempId, true);

		await setSnackbarProps({
			open: true,
			message: `Success! Form added to the project.`,
			severity: 'success',
		});
		handleCancel();
	};

	const displayNextButton = () => {
		switch (step) {
			case 0:
				return (
					<StyledButtonText onClick={handleNext} disabled={!formName.length}>
						Next
					</StyledButtonText>
				);
			case 1:
				return (
					<StyledButtonText
						onClick={handleAssign}
						disabled={!selectedProject || disableButton}>
						Assign Form
					</StyledButtonText>
				);
			default:
				return (
					<StyledButtonText onClick={handleNext} disabled={!formName.length}>
						Next
					</StyledButtonText>
				);
		}
	};

	const fetchProjects = async () => {
		if (!user?.isAdmin && !user?.teamIds) return;
		setIsLoading(true);
		const projectsRef = user?.isAdmin
			? query(collection(db, 'projects'))
			: query(
					collection(db, 'projects'),
					where('sharedTeams', 'array-contains-any', user?.teamIds)
			  );
		const q = projectsRef;
		const snapshot = await getDocs(q);
		const projects = snapshot.docs.map(doc => doc.data() as ProjectData);
		setProjects(projects);
		setFilteredProjects(projects);
		setIsLoading(false);
	};

	useEffect(() => {
		open && fetchProjects();
	}, [open]);

	const clearSearchHandler = () => {
		setSearchQuery('');
		setSelectedProject(['']);
		setFilteredProjects(projects);
	};

	const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value.toLocaleLowerCase();
		setSearchQuery(query);
		setFilteredProjects(
			projects.filter(project => project.address.toLocaleLowerCase().includes(query)) ||
				projects
		);
	};

	return (
		<Dialog
			open={open}
			wide
			title={step === 0 ? 'Please enter a name for this form:' : formName}
			onClose={handleCancel}
			bottomActions={
				<>
					<StyledButtonText onClick={handleCancel}>Cancel</StyledButtonText>
					{displayNextButton()}
				</>
			}>
			{!isLoading && step === 0 && (
				<Fragment>
					<Typography color="newText.primary" variant="body2" sx={{ mb: '24px' }}>
						Please enter a name for this form:
					</Typography>
					<StyledTextField
						label="Form Name"
						value={formName}
						size="small"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setFormName(event.target.value)
						}
					/>
				</Fragment>
			)}
			{!isLoading && step === 1 && (
				<Fragment>
					<Typography color="newText.primary" variant="body2" sx={{ mb: '24px' }}>
						Select which project this form should be created for.
					</Typography>
					<StyledTextField
						label="Search"
						value={searchQuery}
						size="small"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							searchHandler(event)
						}
						sx={{
							mb: '16px',
							width: '520px',
							maxWidth: '100%',
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search color="primary" />
								</InputAdornment>
							),
							endAdornment: !!searchQuery.length && (
								<InputAdornment position="end" onClick={() => clearSearchHandler()}>
									<Close color="primary" sx={{ cursor: 'pointer	' }} />
								</InputAdornment>
							),
						}}
					/>
					<ProjectTable
						rows={filteredProjects}
						selectedProject={selectedProject}
						selectProject={handleSelectProject}
					/>
				</Fragment>
			)}

			{isLoading && (
				<Box sx={{ mb: '30px' }}>
					<StyledCircularProgress size={24} variant="indeterminate" />
				</Box>
			)}
		</Dialog>
	);
};
