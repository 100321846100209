import {
	CardsWrapper,
	ContainerBorder,
	ProjectsContainer,
} from '../../../screens/ProjectsList/components/ClientProjects/styles';
import { Add } from '@mui/icons-material';
import { LoadingContext } from '../../../context/LoadingProvider';
import { ProjectContext } from '../../../context/ProjectProvider';
import { IconButton, styled } from '@mui/material';
import { useContext, useState } from 'react';
import GroupedStatusTable from '../GroupedStatusTable';
import SectionHeader from '../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import AddProjectForm from '../AddProjectForm';
import { AuthContext } from '../../../context/AuthProvider';
import GroupedStatuses from '../GroupedStatusTable/components/GroupedStatuses';
import { style } from '@mui/system';

// TODO: update the comment here :
/**
 * Component for displaying all projects shared with a user
 * @param showingDialog boolean useState that determines whether or not the dialog is shown
 * @param setShowingDialog useState updater for showingDialog
 */
const UserProjects = () => {
	const { setLoadingProjectStatuses } = useContext(LoadingContext);
	const { projectsListQuery, setProjectsListQuery } = useContext(ProjectContext);
	const [showingAddProject, setShowingAddProject] = useState<boolean>(false);
	const { user } = useContext(AuthContext);

	return (
		<>
			<ContainerBorder style={{ height: '100%' }}>
				<CardsWrapper>
					<SectionHeader
						headerStyle={{ width: '100%', padding: '0px 14px' }}
						onSearch={() => setLoadingProjectStatuses(true)}
						propQuery={projectsListQuery}
						setPropQuery={setProjectsListQuery}
						headerComponents={
							<IconButton
								size="small"
								onClick={() => {
									setShowingAddProject(true);
								}}>
								<Add color="primary" />
							</IconButton>
						}>
						<Container>
							Projects
							<Grid>
								<GroupedStatuses />
							</Grid>
						</Container>
					</SectionHeader>
					<ProjectsContainer>
						<GroupedStatusTable />
					</ProjectsContainer>
				</CardsWrapper>
			</ContainerBorder>
			<AddProjectForm
				showingDialog={showingAddProject}
				setShowingDialog={setShowingAddProject}
				isClient={!user?.isAdmin}
				orgRequired={false}
			/>
		</>
	);
};

const Container = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

const Grid = styled('div')`
	display: flex;
	width: 100%;
	place-content: center;
`;

export default UserProjects;
