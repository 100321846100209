import { FirebaseFile, ZipFile } from '../../../../types';
import { downloadUploadedFiles } from '../../../../utils/project-file-download';
import useDownloadZippedFiles from '../../../../utils/useDownloadZippedFiles/useDownloadZippedFiles';
import { formsPath } from '../../../screen-components/ProjectUtilityFormV2/hooks/useV2Storage';
import {
	FormNode,
	PhotoV2,
} from '../../../screen-components/ProjectUtilityFormV2/utils/types';

const useDownloadPhotos = () => {
	const { downloadZippedFiles, safeFileName } = useDownloadZippedFiles();

	const downloadZip = async (
		combinedPhotos: PhotoV2[],
		itemNode: FormNode,
		setDownloadMessage: React.Dispatch<React.SetStateAction<string>>
	) => {
		const photos: ZipFile[] = combinedPhotos
			.filter(photo => photo.url)
			.map((photo, index) => ({
				name: photo.originalName || photo.name,
				url: photo.url!,
				order: index,
			}));

		await downloadZippedFiles(
			photos,
			`${safeFileName(itemNode.displayTitle)}.zip`,
			message => setDownloadMessage(` ...${message}`)
		);
	};

	const downloadPhotos = async (
		combinedPhotos: PhotoV2[],
		itemNode: FormNode,
		setDownloadMessage: React.Dispatch<React.SetStateAction<string>>
	) => {
		const fileArr = combinedPhotos.map(
			photo =>
				({
					downloadUrl: photo.url,
					filename: photo.originalName,
					filepath: `${formsPath}/${itemNode.formId}/photos/${photo.name}`,
					lastUpdated: photo.uploadedAt?.toDate(),
					uploadDate: photo.uploadedAt?.toDate(),
				} as FirebaseFile)
		);
		await downloadUploadedFiles(fileArr, setDownloadMessage);
	};

	return { downloadPhotos, downloadZip };
};

export default useDownloadPhotos;
