import { UserRowContainer, UserRow } from './styled-components';
import { SharedTeam } from '../../types';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../../context/AuthProvider';
import { Avatar, ListItemText, MenuItem, TextField } from '@mui/material';
import styled from 'styled-components';
import PersonIcon from '@mui/icons-material/Person';

interface SharedTeamsProps {
	sharedTeams: SharedTeam[];
}

export default function SharedTeams({ sharedTeams }: SharedTeamsProps) {
	const { user } = useContext(AuthContext);

	const [teamIdx, setTeamIdx] = useState(0);
	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setTeamIdx(Number(event.target.value));
	};

	return sharedTeams.length < 1 ? (
		<h2>No shared teams yet...</h2>
	) : (
		<>
			<StyledTextfield select value={teamIdx} onChange={handleChange}>
				{sharedTeams.map((team, idx) => (
					<StyledMenuItem value={idx} key={team.orgName + team.teamName + idx}>
						<ListItemText primary={team.teamName} secondary={team.orgName} />
					</StyledMenuItem>
				))}
			</StyledTextfield>

			<UserRowContainer isContractor={user?.isContractor}>
				{sharedTeams.length < 1 ? (
					<h2>No shared teams yet...</h2>
				) : (
					<>
						{sharedTeams[teamIdx].members.length ? (
							sharedTeams[teamIdx].members.map(member => (
								<UserRow>
									<Avatar>
										<PersonIcon />
									</Avatar>

									<ListItemText primary={member.email} secondary={member.accountType} />
								</UserRow>
							))
						) : (
							<h2>No users added to this team yet...</h2>
						)}
					</>
				)}
			</UserRowContainer>
		</>
	);
}

const StyledTextfield = styled(TextField)`
	background-color: white;

	.MuiInputBase-root {
		border-radius: 0;
	}

	.MuiSelect-select {
		padding-top: 5px;
		padding-bottom: 5px;
	}
`;
const StyledMenuItem = styled(MenuItem)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;
