import {
	ContentContainer,
	LayoutContainer,
	ProjectsWrapper,
	RightColumn,
} from './styles';
import { AuthContext } from '../../../../context/AuthProvider';
import { UserHomeContext } from '../../../../context/UserHomeProvider';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import { useContext, useEffect } from 'react';
import UserTemplates from '../../../../components/reusable-components/UserTemplates';
import UserProjects from '../../../../components/reusable-components/UserProjects';
import UserReports from '../../../../components/reusable-components/UserReports';
import UserTeams from '../../../../components/reusable-components/UserTeams';
import MapButton from '../MapButton';
import Layout from '../../../../components/reusable-components/Layout';

export type ClientView = 'card' | 'table';

export default function ClientProjects() {
	const { loadingOrganizations, organizations } = useContext(OrganizationsContext);
	const { loadData, templates, reports } = useContext(UserHomeContext);
	const { user, firebaseAuthData } = useContext(AuthContext);

	useEffect(() => {
		if (firebaseAuthData?.uid) {
			if (!templates && !reports) loadData(firebaseAuthData.uid);
		}
	}, [loadData, reports, templates, firebaseAuthData?.uid]);

	const layoutTitle = loadingOrganizations
		? 'Loading organizations...'
		: organizations.length
		? organizations.map(org => org.name).join(', ')
		: 'Home';

	const userHasTeams = user?.teamIds && user.teamIds?.length > 0;

	return (
		<Layout
			title={layoutTitle}
			RightNavComponent={
				<>
					<MapButton />
				</>
			}>
			<LayoutContainer style={{ padding: '20px' }}>
				{userHasTeams ? <UserTeams /> : null}

				<ContentContainer>
					<ProjectsWrapper $teamLength={user?.teamIds?.length}>
						<UserProjects />
					</ProjectsWrapper>

					{userHasTeams ? (
						<RightColumn>
							<UserTemplates />
							<UserReports />
						</RightColumn>
					) : null}
				</ContentContainer>
			</LayoutContainer>
		</Layout>
	);
}
