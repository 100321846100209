import type { FlattenedItem } from './types';
import useFormNodes from './useFormNodes';
import useDragging from './useDragging';
import { CellMeasurerCache } from 'react-virtualized';
import { FormNode } from '../../../ProjectUtilityFormV2/utils/types';
import { addOrderToItems } from '../../../../../utils/addFormOrder';
import FormNodeWrapper from '../../../../form-components/FormNodeWrapper';
import {
	DateField,
	DateTimeField,
	InputField,
	MultilineField,
	PhotosField,
	Section,
	SelectField,
	SwitchField,
} from '../../../../form-components';
import { FormPlaceholder } from '../../../../form-components/FormPlaceholder';
import styled from 'styled-components';
import { useContext } from 'react';
import { MenuReorderContext } from '../../Form';
import useFormTemplateNodes from './useFormTemplateNodes';
import { TemplateContext } from '../../../../../context/TemplateProvider';

interface FormWrapperProps {
	collapsible?: boolean;
	indentationWidth?: number;
	indicator?: boolean;
	removable?: boolean;
	formId: string;
	projectId: string;
	navigateToNode: FormNode | undefined;
}

const rowHeights = new CellMeasurerCache({
	defaultHeight: 80,
	fixedWidth: true,
});

export default function FormWrapper({
	indicator = false,
	indentationWidth = 50,
	formId,
	projectId,
}: FormWrapperProps) {
	const { isTemplate } = useContext(TemplateContext);
	const {
		allFormNodes,
		flattenedFooterPlaceholder,
		flattenedHeaderPlaceholder,
		saveNodes,
	} = useFormNodes(formId, projectId);
	const { allFormTemplateNodes, saveTemplateNodes } = useFormTemplateNodes(formId ?? '');

	const nodes = isTemplate ? allFormTemplateNodes : allFormNodes;
	const safeFunc = isTemplate ? saveTemplateNodes : saveNodes;

	const { flattenedItems } = useDragging({
		allFormNodes: nodes,
		flattenedFooterPlaceholder,
		flattenedHeaderPlaceholder,
		indentationWidth,
		indicator,
		rowHeights,
		saveNodes: safeFunc,
	});

	const orderedItems = addOrderToItems(flattenedItems, true) as FlattenedItem[];

	const itemsByParentId: Record<string, FlattenedItem[]> = {};
	orderedItems.forEach(item => {
		const parentId = item.parentId ?? 'root';
		itemsByParentId[parentId] = [...(itemsByParentId[parentId] || []), item];
	});
	const { expandedList, toggleItem } = useContext(MenuReorderContext);

	return (
		<Container>
			{itemsByParentId['root']?.map(item => {
				return (
					<FormNodeWrapper node={item.node} key={item.node.id}>
						{item.node.type === 'input' ? (
							<InputField node={item.node} key={item.node.id} disabled={isTemplate} />
						) : item.node.type === 'area' ? (
							<MultilineField node={item.node} key={item.node.id} disabled={isTemplate} />
						) : item.node.type === 'switch' ? (
							<SwitchField itemNode={item.node} key={item.node.id} />
						) : item.node.type === 'photos' ? (
							<PhotosField itemNode={item.node} key={item.node.id} />
						) : item.node.type === 'date' ? (
							<DateField node={item.node} key={item.node.id} />
						) : item.node.type === 'datetime' ? (
							<DateTimeField node={item.node} key={item.node.id} />
						) : item.node.type === 'select' ? (
							<SelectField node={item.node} key={item.node.id} />
						) : item.node.type === 'placeholder' ? (
							<FormPlaceholder node={item.node} key={item.node.id} />
						) : (
							<Section
								node={item.node}
								key={item.node.id}
								level={item.depth + 1}
								itemsByParentId={itemsByParentId}
								expandedList={expandedList}
								toggleCollapse={toggleItem}
							/>
						)}
					</FormNodeWrapper>
				);
			})}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;
