import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import { ProjectData, UserWithData } from '../../../types';
import { projectStatuses } from '../../../utils';
import styled from 'styled-components';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import DoughnutGraph from './DoughnutGraph';

export default function StatsPage({
	user,
	filteredProjects,
}: {
	user: UserWithData | null;
	filteredProjects: ProjectData[] | null;
}) {
	Chart.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend,
		ArcElement
	);

	const totalProjectStatusCounts = projectStatuses.map(
		status =>
			filteredProjects?.filter(project => project.projectStatus === status).length || 0
	);

	return (
		<Container>
			<UserContainer>
				<StyledAvatar>
					<Person />
				</StyledAvatar>
				<UserDetails>
					<Name>{user?.fullName}</Name>
					<Email>{user?.email}</Email>
				</UserDetails>
			</UserContainer>
			<GraphsContainer>
				<DoughnutGraph
					projectStatuses={projectStatuses}
					totalProjectStatusCounts={totalProjectStatusCounts}
				/>
			</GraphsContainer>
		</Container>
	);
}

const Person = styled(PersonIcon)`
	font-size: 4rem;

	@media (max-width: 768px) {
		font-size: 2rem;
	}

	@media (min-width: 2200px) {
		font-size: 7rem;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	background-color: #17181b;
	width: 100%;
	height: 100%;
	margin: 1rem auto;
	border-radius: 20px;
	text-align: center;
	padding: 20px;
	gap: 20px;
	overflow: auto;

	@media (max-width: 1300px) {
		flex-direction: column;
		height: 100%;
	}

	> div {
		padding-top: 1rem 0;
	}

	> div > span {
		color: white;
		font-size: 2.5em;
		font-weight: 300;
		text-transform: capitalize;

		@media (max-width: 768px) {
			font-size: 2rem;
		}
		@media (min-width: 2200px) {
			font-size: 4em;
		}
	}
`;

const StyledAvatar = styled(Avatar)`
	width: 7rem;
	height: 7rem;
	margin: 20px;

	@media (max-width: 768px) {
		width: 4rem;
		height: 4rem;
	}

	@media (min-width: 2200px) {
		width: 15rem;
		height: 15rem;
	}

	@media (max-width: 500px) {
		margin: 10px;
	}
`;

const UserContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: rgb(14, 14, 15);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	align-items: center;
	border-radius: 20px;
	padding: 10px;
	width: 30%;
	min-width: 250px;

	@media (max-width: 1300px) {
		flex-direction: row;
		min-width: 100%;
		justify-content: flex-start;
	}
`;

const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	width: 100%;

	@media (max-width: 1300px) {
		justify-content: flex-start;
		gap: 0px;
	}
`;

const Name = styled.span`
	color: #f6f6f6;
	font-family: 'Epilogue';
	font-size: 20px;
	text-align: center;

	@media (max-width: 1300px) {
		text-align: left;
	}
`;

const Email = styled.span`
	color: #767676;
	font-family: 'Epilogue';
	font-size: 15px;

	@media (max-width: 1300px) {
		text-align: left;
	}

	@media (max-width: 500px) {
		font-size: 10px;
	}
`;

const GraphsContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	@media (max-width: 768px) {
		flex-direction: column;
	}
	@media (max-width: 1300px) {
		flex-direction: row;
		min-width: 100%;
	}
	background-color: rgb(14, 14, 15);
	width: 80%;
	border-radius: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;
