import { useContext } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Table,
	TableRow,
	styled,
	tableCellClasses,
} from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import { ProjectDataWithForms } from '../../../types';
import Loading from '../ProjectUtilityFormV2/components/Loading/Loading';
import FormCheckbox from './FormCheckbox';
import { Center } from '../ProjectUtilityFormV2/components/Dialogs/UnarchiveDialog/UnarchiveDialog.styles';
import { ProjectTableCell, FormTableCell } from './StyledComponents';
import { Link } from 'react-router-dom';

export default function CreateReportDialog({
	open,
	createReport,
	projects,
	setOpen,
	toggleForm,
	totalSelectedForms,
}: {
	createReport: () => void;
	open: boolean;
	projects: ProjectDataWithForms[] | undefined;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	toggleForm: (formId: string, value: boolean) => void;
	totalSelectedForms: number;
}) {
	const { setSnackbarProps } = useContext(SnackContext);

	return (
		<Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
			<DialogTitle>Create Report ({totalSelectedForms} forms selected)</DialogTitle>
			<DialogContent>
				{projects ? (
					<Table
						sx={{
							[`& .${tableCellClasses.root}`]: {
								borderBottom: 'none',
							},
						}}>
						<tbody>
							{projects.map((project, index) => (
								<TableRow style={{ border: 'none' }} key={project.id}>
									<ProjectTableCell key={project.id}>
										<Link
											to={`/projects/${project.id}`}
											target="_blank"
											style={{ color: 'black' }}
											title={`Navigate to ${project.address}`}>
											{index + 1}. {project.address.slice(0, 50)}
											{project.address.length > 50 && '...'}
										</Link>
										<br />
										<ProjectStatus>({project.projectStatus})</ProjectStatus>
									</ProjectTableCell>
									{project.forms.map(form => (
										<FormTableCell key={form.id}>
											<FormCheckbox
												onClick={value => toggleForm(form.id, value)}
												initialValue={form.report && form.status === 'completed'}
												disabled={form.status !== 'completed'}
											/>
											<br />
											<label htmlFor={form.id}>
												{['draft', 'completed'].includes(form.status) ? (
													<>
														<Link
															to={`/projects/${project.id}/capture-form-v3/${form.id}`}
															target="_blank"
															style={{ color: 'black' }}
															title={`Navigate to ${form.formName}`}>
															{form.formName}
														</Link>
														<br />
														<ProjectStatus>({form.status})</ProjectStatus>
													</>
												) : (
													<span>
														{form.formName}
														<br />
														<ProjectStatus>({form.status})</ProjectStatus>
													</span>
												)}
											</label>
										</FormTableCell>
									))}
								</TableRow>
							))}
						</tbody>
					</Table>
				) : (
					<Center>
						<Loading />
					</Center>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>Cancel</Button>
				<Button
					onClick={() => {
						setOpen(false);
						createReport();
						setSnackbarProps({
							open: true,
							message: 'Report creation started.',
							severity: 'success',
						});
					}}
					autoFocus>
					Accept
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const ProjectStatus = styled('span')`
	color: #888888;
	font-size: 0.7rem;
`;
