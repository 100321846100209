import { createTheme } from '@mui/material/styles';
import '@fontsource/epilogue';
import '@fontsource/epilogue/300.css';

export const baseFontSize = '0.875rem';
const dialogContentPadding = {
	padding: '2em 3em',
	'@media screen and (max-width: 767px)': {
		padding: '5px',
	},
};

const theme = createTheme({
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					fontSize: baseFontSize,
				},
			},
		},
		// MuiAlert: {
		// 	styleOverrides: {
		// 		message: {
		// 			whiteSpace: 'pre-line',
		// 		},
		// 	},
		// },
		MuiButton: {
			styleOverrides: {
				root: {
					transition: '0.2s',
					display: 'flex',
					gap: '5px',
					backfaceVisibility: 'hidden',

					'&:hover, &:focus-visible': {
						filter: 'brightness(0.7)',
					},
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&:hover, &:focus-visible': {
						backgroundColor: 'rgba(255, 179, 16, 0.12)',
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: '#ffb310',
				},
			},
		},

		// MuiDialog: {
		// 	styleOverrides: {
		// 		paper: {
		// 			'& div.MuiDialogContent-root': {
		// 				padding: '2em 3em',
		// 				display: 'flex',
		// 				flexDirection: 'column',
		// 				gap: '5px',

		// 				'@media screen and (max-width: 767px)': {
		// 					padding: '5px',
		// 				},
		// 			},
		// 		},
		// 	},
		// },

		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: '20px',
					backgroundColor: 'transparent',
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					display: 'flex',
					alignItems: 'center',
					gap: '10px',
					background: 'linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(44, 44, 44) 90%)',
					color: 'white',

					'+ div.MuiDialogContent-root': {
						...dialogContentPadding,
					},
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					...dialogContentPadding,
					display: 'flex',
					flexDirection: 'column',
					gap: '5px',
					backgroundColor: '#f1f1f1',
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					background: 'linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(44, 44, 44) 90%)',
					color: 'white',
					gap: '5px',

					'& > *': {
						lineHeight: 'normal',
						'&:not(:first-of-type)': {
							marginLeft: '0',
						},
					},
				},
			},
		},

		MuiAutocomplete: {
			styleOverrides: {
				fullWidth: {
					minWidth: '0px',
				},
			},
		},

		MuiChip: {
			styleOverrides: {
				root: {
					backgroundColor: 'lightgrey',
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				color: 'secondary',
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					'&.customTextFieldLabel': {
						maxWidth: 'calc(100% - 64px)',
					},
				},
				shrink: {
					'&.customTextFieldLabel': {
						maxWidth: 'calc(133% - 24px)',
					},
				},
			},
		},

		MuiSkeleton: {
			styleOverrides: {
				wave: {
					'&::after': {
						background:
							'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.5), transparent)',
					},
				},
			},
		},
	},
	palette: {
		background: { default: 'black' },
		primary: {
			light: '#ffe551',

			// M3/sys/dark/on-primary-container
			// Primary color
			main: '#ffb310',

			dark: '#c78400',
			contrastText: '#000',
		},
		secondary: {
			main: '#121212',
			light: '#242424',
			dark: '#000000',
			contrastText: '#ffe551',
		},
		divider: '#595959',
		borderColor: 'rgba(89, 89, 89, 0.4)',
		common: {
			black: '#000',
			white: '#fff',
		},
		grey: {
			50: '#f1f1f1',
			100: '#d1d1d1',
			200: '#b1b1b1',

			// M3/sys/dark/on-surface-variant
			// Text color on popups
			300: '#838383',

			400: '#717171',

			// M3/sys/dark/outline-variant
			// Divider
			500: '#595959',

			600: '#4E4539',

			// M3/sys/dark/primary-container
			// Button background color
			700: '#322100',

			// M3/sys/dark/outline
			// Input border color
			800: '#242424',

			// M3/sys/dark/surface-container
			// Background of popups
			900: '#1E1E23',
			A100: '#0a0a0a',
		},
		newText: {
			primary: '#fbfbfb',
		},
	},

	typography: {
		fontFamily: 'Epilogue',
		label: {
			fontSize: '0.7rem',
		},
	},
});
declare module '@mui/material/styles/createPalette' {
	interface Palette {
		newText: Palette['text'];
		borderColor: Palette['divider'];
	}

	interface PaletteOptions {
		newText: PaletteOptions['text'];
		borderColor: PaletteOptions['divider'];
	}
}

declare module '@mui/material/styles/createPalette' {
	interface Palette {
		newText: Palette['text'];
		borderColor: Palette['divider'];
	}

	interface PaletteOptions {
		newText: PaletteOptions['text'];
		borderColor: PaletteOptions['divider'];
	}
}

declare module '@mui/material/styles' {
	interface TypographyVariants {
		label: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		label?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		label: true;
	}
}

export default theme;
