import { styled } from '@mui/material';
import { ContainerBorder } from '../../../screens/ProjectsList/components/ClientProjects/styles';

export const ContentList = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

export const Center = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const Border = styled(ContainerBorder)`
	flex: 1;
`;

export const Scroller = styled('div')`
	overflow-y: auto;
	width: 100%;
	height: 100%;
`;
