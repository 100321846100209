import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { InputFieldContainer, ManageUploadsButton } from './FormFillV2.styles';
import DraggableGallery from './PhotosField/DraggableGallery';
import styled from 'styled-components';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { SnackContext } from '../../../context/SnackProvider';
import { Flag, FormNode } from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { FilePresent } from '@mui/icons-material';
import PhotoGalleryDialog from './PhotoGalleryDialog/PhotoGalleryDialog';
import PhotoUploadManager from './PhotoUploadManager/PhotoUploadManager';
import theme from '../../../styles/theme';
import { Typography } from '@mui/material';
import { TemplateContext } from '../../../context/TemplateProvider';

type PhotosFieldProps = {
	itemNode: FormNode;
};

const PhotosField: FC<PhotosFieldProps> = memo(({ itemNode }) => {
	const { deleteNodePhoto, photoUploadQueue, updatePhotos } = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);

	const [openUploadManager, setOpenUploadManager] = useState(false);
	const uploadedPhotos = useMemo(
		() => (itemNode.value && Array.isArray(itemNode.value) ? itemNode.value : []),
		[itemNode.value]
	);
	const [draggingDisabled, setDraggingDisabled] = useState(false);

	const { setSnackbarProps } = useContext(SnackContext);
	const [isGalleryOpen, setIsGalleryOpen] = useState(false);
	const [initialImageIndex, setInitialImageIndex] = useState(0);

	const combinedPhotos = useMemo(
		() => [
			...uploadedPhotos,
			...(!!photoUploadQueue
				? photoUploadQueue?.filter(p => p?.nodeId === itemNode.id).map(p => p.photo)
				: []),
		],
		[itemNode.id, photoUploadQueue, uploadedPhotos]
	);

	useEffect(() => {
		setDraggingDisabled(combinedPhotos.some(photo => !photo.url));
	}, [combinedPhotos]);

	const openGallery = (index: number) => {
		// console.log('Opening gallery at index:', index);
		setInitialImageIndex(index);
		setIsGalleryOpen(true);
	};

	const closeGallery = () => {
		setIsGalleryOpen(false);
	};

	const deleteImage = async (index: number) => {
		const photoToDelete = uploadedPhotos[index];
		if (!photoToDelete) {
			console.error("Can't find photo.");
			return;
		}
		try {
			deleteNodePhoto(itemNode.id, photoToDelete);
			// Update local state to reflect the photo deletion

			setSnackbarProps({
				open: true,
				message: 'Photo deleted successfully.',
				severity: 'success',
			});
		} catch (error) {
			console.error('Failed to delete photo:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to delete photo. Please try again later.',
				severity: 'error',
			});
		}
	};

	const setFlag = (index: number, flag: Flag) => {
		const photos = uploadedPhotos.map((photo, i) =>
			i === index ? { ...photo, flag } : photo
		);
		try {
			updatePhotos(itemNode.id, photos);
		} catch (error) {
			console.error('Failed to update flag:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to update flag. Please try again later.',
				severity: 'error',
			});
		}
	};

	return (
		<Container>
			<InputFieldContainer>
				<ManageUploadsButton onClick={() => !isTemplate && setOpenUploadManager(true)}>
					<FilePresent />

					<Typography
						fontWeight={700}
						sx={{
							[theme.breakpoints.down('sm')]: {
								fontSize: 12,
								textAlign: 'center',
							},
						}}>
						Manage uploads
					</Typography>
				</ManageUploadsButton>

				<PhotoUploadManager
					open={openUploadManager}
					onClose={() => setOpenUploadManager(false)}
					itemNode={itemNode}
					uploadedPhotos={uploadedPhotos}
				/>

				<DraggableGallery
					key={itemNode.id}
					photos={combinedPhotos}
					nodeId={itemNode.id}
					openGallery={openGallery}
					disabled={draggingDisabled}
				/>

				<PhotoGalleryDialog
					open={isGalleryOpen}
					photos={uploadedPhotos}
					initialIndex={initialImageIndex}
					onClose={closeGallery}
					onDelete={deleteImage}
					setFlag={setFlag}
				/>
			</InputFieldContainer>
		</Container>
	);
});

export default PhotosField;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;
