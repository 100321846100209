import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { GalleryContainer, PhotoDropzone } from '../FormFillV2.styles';
import SortablePhoto from './SortablePhoto';
import { PhotoV2 } from '../../../screen-components/ProjectUtilityFormV2/utils/types';
import { FormV2Context } from '../../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { Typography } from '@mui/material';
import { ImageOutlined } from '@mui/icons-material';
import theme from '../../../../styles/theme';
import { useDropzone } from 'react-dropzone';
import { SnackContext } from '../../../../context/SnackProvider';
import { TemplateContext } from '../../../../context/TemplateProvider';

interface DraggableGalleryProps {
	disabled?: boolean;
	photos: PhotoV2[];
	openGallery: (index: number) => void;
	nodeId: string;
}

type DropzoneFileRejection = {
	file: File;
	errors: DropzoneFileError[];
};

type DropzoneFileError = {
	code: string;
	message: string;
};

const DraggableGallery: React.FC<React.PropsWithChildren<DraggableGalleryProps>> = ({
	disabled,
	photos,
	openGallery,
	nodeId,
}) => {
	const { updateValue, addPhotosToDownloadQueue } = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);
	const [localPhotos, setLocalPhotos] = useState<PhotoV2[]>(photos);
	const { setSnackbarProps } = useContext(SnackContext);

	useEffect(() => {
		setLocalPhotos(photos);
	}, [photos]);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;

			if (over && active.id !== over.id) {
				const oldIndex = localPhotos.findIndex(item => item.name === active.id);
				const newIndex = localPhotos.findIndex(item => item.name === over.id);
				const newPhotos = arrayMove(localPhotos, oldIndex, newIndex);

				setLocalPhotos(newPhotos);
				updateValue(nodeId, newPhotos).catch(console.error);
			}
		},
		[localPhotos, updateValue, nodeId]
	);

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			// convert dropped photos to LocalPhoto[] type, and add to download queue
			const localPhotos = acceptedFiles.map(file => ({
				originalName: file.name,
				uri: URL.createObjectURL(file), //create local URL for file
				name: file.name,
			}));
			addPhotosToDownloadQueue(nodeId, localPhotos);
		},
		[addPhotosToDownloadQueue, nodeId]
	);

	const onDropRejected = useCallback(
		(fileRejections: DropzoneFileRejection[]) => {
			fileRejections.forEach(fileRejection => {
				fileRejection.errors.forEach((error: DropzoneFileError) => {
					if (error.code === 'file-too-large') {
						setSnackbarProps({
							open: true,
							message: `File is too large.`,
							severity: 'warning',
						});
					} else if (error.code === 'file-invalid-type') {
						setSnackbarProps({
							open: true,
							message: `File type not accepted.`,
							severity: 'warning',
						});
					}
				});
			});
		},
		[setSnackbarProps]
	);

	const { isDragActive, getRootProps, getInputProps, isDragReject } = useDropzone({
		onDrop,
		onDropRejected,
		accept: ['image/png', 'image/jpeg'],
		minSize: 0,
	});

	return (
		<DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
			<SortableContext items={localPhotos.map(photo => photo.name)} disabled={disabled}>
				<GalleryContainer>
					{localPhotos.map((photo, index) => (
						<SortablePhoto
							key={photo.name + index}
							photo={photo}
							index={index}
							openGallery={openGallery}
						/>
					))}
					<PhotoDropzone {...getRootProps()} small={1}>
						<input {...getInputProps()} disabled={isTemplate} />
						<ImageOutlined
							sx={{
								fontSize: 24,
								marginBottom: 1,
							}}
						/>
						<Typography
							fontWeight={700}
							variant="caption"
							sx={{
								textAlign: 'center',
								[theme.breakpoints.down('sm')]: {
									fontSize: 12,
								},
							}}>
							{!isDragActive && 'Add photo'}
							{isDragActive && !isDragReject && 'Drop it like its hot!'}
						</Typography>
					</PhotoDropzone>
				</GalleryContainer>
			</SortableContext>
		</DndContext>
	);
};

export default DraggableGallery;
