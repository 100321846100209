import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
} from '@mui/material';
import { UserPerms, UserPermsMap, UserWithData } from '../../../types';
import { SnackContext } from '../../../context/SnackProvider';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthProvider';
import { updateUserPerms } from '../../../firebase';
import { getEntries } from '../../../utils/typescript-utils';

export default function UserPermsDialog({
	open,
	onClose,
	userPermsEdit,
	setUserPermsEdit,
}: {
	open: boolean;
	onClose: () => void;

	userPermsEdit: UserWithData | null;
	setUserPermsEdit: React.Dispatch<React.SetStateAction<UserWithData | null>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setUsersWithData, setUser, firebaseAuthData } = useContext(AuthContext);

	const handlePermsChange = async (checked: boolean, perm: UserPerms) => {
		setSnackbarProps({
			open: true,
			message: 'Saving changes...',
			severity: 'warning',
			hideDuration: null,
		});

		// Update states.
		const updatedUser = {
			...userPermsEdit,
			userPerms: {
				...(userPermsEdit?.userPerms || {}),
				[perm as UserPerms]: checked,
			},
		} as UserWithData;

		setUserPermsEdit(updatedUser);
		setUsersWithData(prev => {
			// Splice prev instead of map so that the whole table doesn't get re-rendered.
			const idx = prev.findIndex(u => u.id === updatedUser.id);
			prev.splice(idx, 1, updatedUser);
			return prev;
		});

		// Update Firestore.
		await updateUserPerms({
			uid: updatedUser.id,
			[perm]: checked,
		});

		// Update user object if the current user is being changed.
		if (userPermsEdit?.id === firebaseAuthData?.uid) {
			setUser((prev: any) => {
				return {
					...prev,
					userPerms: {
						...(prev?.userPerms || {}),
						[perm]: checked,
					},
				};
			});
		}

		setSnackbarProps({
			open: true,
			message: 'Changes saved!',
			severity: 'success',
		});
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Editing Permissions</DialogTitle>

			<DialogContent>
				<strong>{`${userPermsEdit?.fullName || 'Unnamed User'}`}</strong>
				<span>{`(${userPermsEdit?.email || 'Unknown Email'})`}</span>
				{getEntries(UserPermsMap).map(([key, value]) => {
					const perm = key as UserPerms;
					const permTitle = value;
					const hasPerm = !!userPermsEdit?.userPerms?.[perm];

					return (
						<FormControlLabel
							control={
								<Checkbox
									checked={hasPerm}
									onChange={(e, checked) => handlePermsChange(checked, perm)}
								/>
							}
							label={permTitle}
							key={perm}
						/>
					);
				})}
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}
