import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { useContext } from 'react';
import { SnackContext } from '../../../context/SnackProvider';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';

export default function RemoveUserDialog({
	usersToBeRemoved,
	setUsersToBeRemoved,
	teamId,
}: {
	usersToBeRemoved: string[] | undefined;
	setUsersToBeRemoved: React.Dispatch<React.SetStateAction<string[] | undefined>>;
	teamId: string;
}) {
	const { removeUserFromTeam, removingUserFromTeam } = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClose = () => {
		setUsersToBeRemoved(undefined);
	};

	const handleDelete = async () => {
		for (const userToBeRemoved of usersToBeRemoved || []) {
			if (userToBeRemoved) {
				const result = await removeUserFromTeam(userToBeRemoved, teamId);
				if (result) {
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: `Successfully removed the user from team!`,
					});
				}
			}
		}
		handleClose();
	};

	return (
		<Dialog open={!!usersToBeRemoved} onClose={handleClose}>
			<DialogTitle>Remove users</DialogTitle>
			{removingUserFromTeam ? (
				<DialogContent>
					<LoadingScreen message="Removing users from the team..." textColor="black" />
				</DialogContent>
			) : (
				<DialogContent>
					<span>Are you sure you want to remove these users from this team?</span>
				</DialogContent>
			)}
			{!removingUserFromTeam && (
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Ok</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}
