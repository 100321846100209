import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectContext } from '../../../../../context/ProjectProvider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import LaunchIcon from '@mui/icons-material/Launch';
import { projectStatusText } from '../../../../../utils';
import Spinner from '../../../../../components/reusable-components/Spinner';
import styled from 'styled-components';
import theme from '../../../../../styles/theme';

const imageWidth = 130;

/**
 * Functional component for displaying an info card when a project pin on the Map component is clicked
 * @param project Object containing project data
 */
export default function ProjectCard({ project }: any) {
	const { setProjectsSelected } = useContext(ProjectContext);
	const navigate = useNavigate();

	const concatAddress = project.address.replace(/\s+/g, '+');
	const imageApiUrl = `https://maps.googleapis.com/maps/api/staticmap?&markers=color:blue|${concatAddress}|size:&center=${concatAddress}&zoom=18&size=800x800&maptype=satellite&key=${process.env.REACT_APP_firebase_apiKey}`;
	const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

	useEffect(() => {
		// Adds a delay to fetch static map image so that we don't flood Google's API with requests when we hover over many map markers
		const fetchImg = setTimeout(() => setImageSrc(imageApiUrl), 1000);
		// Cancels timeout if component unmounts
		return () => clearTimeout(fetchImg);
	}, [imageApiUrl]);

	const navigateTo = (proj: any) => {
		setProjectsSelected([]);
		navigate(`/projects/${proj.id}`);
	};
	return (
		<StyledCard>
			<CardDetails>
				<StyledCardContent>
					<Typography component="h5" variant="body1" color="white">
						{project.address}
					</Typography>

					<Typography variant="caption" color="newText.primary">
						{`Creator: ${project.creator}`}
					</Typography>

					<Typography variant="caption" color="newText.primary">
						{`Created: ${new Date(project.createdAt._seconds * 1000).toDateString()}`}
					</Typography>

					<Typography variant="caption" color="newText.primary">
						{`Status: ${projectStatusText(project.projectStatus)}`}
					</Typography>
				</StyledCardContent>

				<Controls>
					<IconButton
						aria-label="navigate-to-project"
						title="Navigate to project"
						onClick={() => navigateTo(project)}
						size="large"
						sx={{ ...iconStyles }}>
						<LaunchIcon />
					</IconButton>
				</Controls>
			</CardDetails>

			<StyledCardMedia image={imageSrc} title="Google Street View image">
				{!imageSrc ? <Spinner relativeWidth="70%" /> : null}
			</StyledCardMedia>
		</StyledCard>
	);
}

const StyledCard = styled(Card)`
	display: flex;
	background-color: ${theme.palette.secondary.main};
	margin-left: -14px;
	margin-right: 2px;
	padding-left: 16px;
	padding-right: 8px;
	padding-bottom: 20px;
	max-height: 210px;
	overflow-y: auto;
	overflow-x: hidden;
`;

const CardDetails = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledCardContent = styled(CardContent)`
	flex: 1 0 auto;
	padding: 0;
	display: flex;
	flex-direction: column;
	padding-right: 5px;
`;

const Controls = styled.div`
	display: flex;
	align-items: center;
`;

const StyledCardMedia = styled(CardMedia)`
	width: ${imageWidth}px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
`;

const iconStyles = {
	color: theme.palette.common.black,
	filter: 'invert(100%)',
	ml: '-12px',
};
