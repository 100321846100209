import { Button, Input, Switch, TextField } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../styles/theme';

export const Page = styled.div`
	display: flex;
	flex-direction: row;
	width: 100vw;
	height: calc(100vh - 75px);
	background-color: #eef0f5;
	justify-content: center;
	padding: 20px;
`;

export const ChildBlockContainer = styled.div`
	width: 100%;
	background-color: bisque;
	/* background-color: #eef0f5; */
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);

	border-radius: 20px;
	height: fit-content;
	gap: 20px;
	padding: 20px;
`;

interface OverviewPageProps {
	currentNode?: boolean;
}

export const Field = styled.div`
	background-color: #fefefe;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
	border-radius: 20px;
	min-height: 50px;
	break-inside: avoid;
	padding: 20px;
	position: relative;
`;

export const SubCategoryHeaderContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const InputFieldContainer = styled.div`
	gap: 40px;
	flex: 1;
`;

export const PhotoDropzone = styled.div<{ small?: number }>`
	display: flex;
	flex: 1;
	flex-direction: column;
	background-color: ${theme.palette.grey[900]};
	color: ${theme.palette.primary.main};
	font-size: 16px;
	font-weight: 700;
	text-decoration: underline;
	border-radius: 5px;
	min-height: 100px;
	padding: 10px;
	justify-content: center;
	align-items: center;
	break-inside: avoid;
	cursor: pointer;
	${({ small }) =>
		small &&
		`
			flex: unset;
			width: 100px;
			height: 100px;
			transition: 0.3s;

			&:hover {
				border: 1px solid ${theme.palette.primary.main};
			}
	`}
`;

export const ChildrenContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	border-radius: 20px;
	width: 100%;
	height: fit-content;
`;

export const NodeContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ItemTitleInput = styled(TextField)`
	font-family: 'Epilogue';
	color: black;
	font-weight: 400;
	font-size: 1rem;
	width: 50%;
	display: flex;
`;

export const NodeCenteredHeader = styled(Input)`
	font-family: 'Epilogue';
	color: black;
	font-weight: 500;
	font-size: 1.2rem;
	text-align: center;
	input {
		text-align: center;
	}
`;

export const StaticTitle = styled.span`
	display: flex;
	font-family: 'Epilogue';
	color: black;
	font-weight: 600;
	font-size: 1rem;
	margin-right: 10px;
	margin-left: 5px;
`;

export const Value = styled.div<OverviewPageProps>`
	/* display: flex; */
	display: ${({ currentNode }) => (currentNode ? 'flex' : null)};
	flex: 1;
`;

export const FormSwitch = styled(Switch)`
	width: 28;
	height: 16;
	padding: 0;
	display: 'flex';

	&:active {
		& .MuiSwitch-thumb {
			width: 20;
		}
		& .MuiSwitch-switchBase.Mui-checked {
			transform: translateX(15px);
		}
	}
	& .MuiSwitch-switchBase {
		padding: 2;
		&.Mui-checked {
			transform: translateX(18px);
			color: #f6f6f6;
			& + .MuiSwitch-track {
				opacity: 1;
				background-color: #ffb310;
			}
		}
	}
	& .MuiSwitch-thumb {
		box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
		width: 12;
		height: 12;
		border-radius: 6;
		transition: width 100 transform 200ms;
	}
	& .MuiSwitch-track {
		border-radius: 20px;
		opacity: 1;
		background-color: #eef0f5;
		box-sizing: border-box;
	}
`;

export const PickerButton = styled(Button)`
	text-transform: none;
	border: 1px solid grey;
	color: black;
`;

export const GalleryContainer = styled.div`
	display: flex;
	// flex-direction: row;
	flex-wrap: wrap;
	justify-content: start;
	align-items: flex-start;
	gap: 10px;
	padding: 5px;
	padding: 5px;
`;

export const PhotoLoadingIcon = styled('div')`
	position: absolute;
	align-self: center;
	z-index: 2;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const PhotoContainer = styled.div`
	width: 100px;
	height: 100px;
	position: relative;
	overflow: hidden;
	outline: none;

	@keyframes pulse {
		0%,
		100% {
			background-color: rgba(0, 0, 0, 0.5);
		}
		50% {
			background-color: rgba(0, 0, 0, 0.2);
		}
	}

	animation: pulse 3s infinite;
`;

export const ManageUploadsButton = styled.div`
	padding: 15px 5px;
	color: ${theme.palette.primary.main};
	text-decoration: underline;
	cursor: pointer;
	width: fit-content;
	font-family: 'Epilogue';
	font-weight: 600;
	font-size: 1rem;
	display: flex;
	align-items: center;
	gap: 10px;
`;
