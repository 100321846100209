import {
	ContainerBorder,
	TeamsContainer,
	TeamsWrapper,
} from '../../../screens/ProjectsList/components/ClientProjects/styles';
import { useContext, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Center } from '../TeamTables';
import { IconButton } from '@mui/material';
import { AuthContext } from '../../../context/AuthProvider';
import { SnackContext } from '../../../context/SnackProvider';
import { UserHomeContext } from '../../../context/UserHomeProvider';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import AddTeamDialog from '../../screen-components/OrganizationPage/AddTeamDialog';
import SectionHeader from '../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import TeamGrid from '../../screen-components/OrganizationPage/grid-components/TeamGrid';
import Loading from '../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';
import { TeamData } from '../../screen-components/ProjectInfo/components/field-components/SharedTeams/types';
import EmptyStateMessage from '../EmptyStateMessage';

const UserTeams = () => {
	const { user, firebaseAuthData } = useContext(AuthContext);
	const { loadingTeams, refreshTeams, teams } = useContext(UserHomeContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [searchQuery, setSearchQuery] = useState('');
	const [openTeamDialog, setOpenTeamDialog] = useState(false);
	const { organizations } = useContext(OrganizationsContext);

	const adminOrgs = organizations.filter(organization =>
		user?.adminOrgIds?.includes(organization.id)
	);

	// Ensure displayed teams are unique – no duplicates
	const filteredTeams = teams
		.filter(team => team.name?.toLowerCase().includes(searchQuery.toLowerCase()))
		.reduce<TeamData[]>((acc, team) => {
			const found = acc.find(t => t.id === team.id);
			if (!found) {
				acc.push(team);
			}
			return acc;
		}, []);

	const userIsTeamMember = (teamId: string) => {
		if (firebaseAuthData) {
			const team = teams.find(t => t.id === teamId);
			const orgId = team?.organizationId;

			const isTeamMember = Boolean(team?.userIds?.includes(firebaseAuthData.uid));
			const isOrgAdmin = Boolean(orgId ? user?.adminOrgIds?.includes(orgId) : false);

			return isTeamMember || isOrgAdmin;
		} else {
			return false;
		}
	};

	const handleUnauthorizedAccess = () => {
		setSnackbarProps({
			open: true,
			message: `Permission denied. Contact your organization's admin to obtain access to this team.`,
			severity: 'warning',
			hideDuration: null,
		});
	};

	return (
		<>
			<AddTeamDialog
				open={openTeamDialog}
				setOpen={setOpenTeamDialog}
				organizations={adminOrgs}
				onSave={() => {
					if (firebaseAuthData) refreshTeams(firebaseAuthData.uid);
				}}
			/>
			<ContainerBorder>
				<TeamsWrapper>
					<SectionHeader
						onSearch={setSearchQuery}
						onRefresh={() => {
							if (firebaseAuthData) refreshTeams(firebaseAuthData.uid);
						}}
						headerComponents={
							user?.isAdmin || user?.adminOrgIds?.length ? (
								<IconButton onClick={() => setOpenTeamDialog(true)}>
									<Add color="primary" />
								</IconButton>
							) : null
						}>
						Teams
					</SectionHeader>
					<TeamsContainer
						style={{ minHeight: 140, alignItems: 'center', justifyContent: 'center' }}>
						{loadingTeams ? (
							<Center>
								<Loading />
							</Center>
						) : (
							<>
								{filteredTeams.length ? (
									<div className="grid-container">
										<TeamGrid
											teams={filteredTeams}
											inSelectionMode={false}
											selectedTeams={[]}
											onSelectTeam={() => {}}
											userIsTeamMember={userIsTeamMember}
											onUnauthorizedAccess={handleUnauthorizedAccess}
											isCondensed={true}
											style={{
												display: 'flex',
												overflowX: 'auto',
												scrollSnapType: 'x mandatory',
											}}
										/>
									</div>
								) : (
									<EmptyStateMessage text={'No displayable teams'} />
								)}
							</>
						)}
					</TeamsContainer>
				</TeamsWrapper>
			</ContainerBorder>
		</>
	);
};

export default UserTeams;
