import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import TeamCard from './TeamCard';
import { Team } from '../../../../types';

export interface TeamGridProps {
	teams: Team[];
	inSelectionMode: boolean;
	selectedTeams: string[];
	onSelectTeam: (teamId: string) => void;
	userIsAdmin?: boolean;
	userIsOrgAdmin?: boolean;
	userIsTeamMember: (teamId: string) => boolean;
	onUnauthorizedAccess: () => void;
	style?: CSSProperties;
	isCondensed?: boolean;
}

const TeamGrid: React.FC<React.PropsWithChildren<TeamGridProps>> = ({
	teams,
	inSelectionMode,
	selectedTeams,
	onSelectTeam,
	userIsAdmin,
	userIsOrgAdmin,
	userIsTeamMember,
	onUnauthorizedAccess,
	style,
	isCondensed,
}) => {
	return (
		<GridContainer style={style}>
			{teams.map(team => (
				<TeamCard
					key={team.id}
					team={team}
					userIsAdmin={userIsAdmin}
					userIsOrgAdmin={userIsOrgAdmin}
					inSelectionMode={inSelectionMode}
					isSelected={selectedTeams.includes(team.id)}
					onSelect={() => onSelectTeam(team.id)}
					userIsTeamMember={userIsTeamMember(team.id)}
					onUnauthorizedAccess={onUnauthorizedAccess}
					isCondensed={isCondensed}
				/>
			))}
		</GridContainer>
	);
};

export default TeamGrid;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	width: 100%;
	grid-gap: 20px;
	padding: 20px;
	place-items: center center;

	@media (max-width: 400px) {
		padding: 0px;
	}
`;
