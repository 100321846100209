import { ProjectStatus } from '../../../types';
import {
	Frame,
	Block,
	BidPendingIcon,
	BidSentIcon,
	ToBeScheduledIcon,
	OnSiteScheduledIcon,
	CaptureCompleteIcon,
	RegistrationIcon,
	ModelingInProgressIcon,
	Icon8,
} from './styles';
import { useEffect, useState } from 'react';
import { statusLabels } from './utils/utils';
import BidPending from './assets/BidPending2.svg';
import ToBeScheduled from './assets/ToBeScheduled.svg';
import OnSiteScheduled from './assets/OnSiteScheduled.svg';
import CaptureComplete from './assets/CaptureComplete.svg';
import Registration from './assets/Registration.svg';
import ModelingInProgress from './assets/ModelingInProgress.svg';
import { Tooltip } from '@mui/material';

// Props for ProjectTracker
type ProjectTrackerProps = {
	projectStatus?: ProjectStatus;
	nav?: boolean;
	style?: React.CSSProperties;
};

const NewTracker: React.FC<React.PropsWithChildren<ProjectTrackerProps>> = ({
	projectStatus,
	nav = true,
	style,
}) => {
	const [activeStep, setActiveStep] = useState(1);

	// Set active step based on project status
	useEffect(() => {
		let step = 0;

		switch (projectStatus) {
			case 'Proposal Pending':
				step = 1;
				break;
			case 'Proposal Sent':
				step = 2;
				break;
			case 'To Be Scheduled':
				step = 3;
				break;
			case 'On-Site Scheduled':
				step = 4;
				break;
			case 'On-Site Capture Complete':
				step = 5;
				break;
			case 'Registration':
				step = 6;
				break;
			case 'Ready For Modeling':
			case 'Modeling In Progress':
			case 'QA/QC':
				step = 7;
				break;
			case 'Pending Payment / Delivered':
			case 'Delivered':
			case 'Pending Payment':
				step = 8;
				break;
			default:
				step = 0;
				break;
		}
		setActiveStep(step);
	}, [projectStatus]);

	const tooltipTitle = projectStatus
		? statusLabels[projectStatus] || 'Unknown status'
		: 'Status unavailable';

	return (
		<Tooltip title={tooltipTitle} placement="top" arrow>
			<div>
				<Frame step={activeStep} nav={nav} style={style}>
					<Block step={activeStep} nav={nav}>
						<BidPendingIcon src={BidPending} step={activeStep} $nav={nav} />
					</Block>
					<Block step={activeStep} nav={nav}>
						<BidSentIcon step={activeStep} $nav={nav} />
					</Block>
					<Block step={activeStep} nav={nav}>
						<ToBeScheduledIcon src={ToBeScheduled} step={activeStep} $nav={nav} />
					</Block>
					<Block step={activeStep} nav={nav}>
						<OnSiteScheduledIcon src={OnSiteScheduled} step={activeStep} $nav={nav} />
					</Block>
					<Block step={activeStep} nav={nav}>
						<CaptureCompleteIcon src={CaptureComplete} step={activeStep} $nav={nav} />
					</Block>
					<Block step={activeStep} nav={nav}>
						<RegistrationIcon src={Registration} step={activeStep} $nav={nav} />
					</Block>
					<Block step={activeStep} nav={nav}>
						<ModelingInProgressIcon
							src={ModelingInProgress}
							step={activeStep}
							$nav={nav}
						/>
					</Block>
					<Block step={activeStep} nav={nav}>
						<Icon8 step={activeStep} $nav={nav} />
					</Block>
				</Frame>
			</div>
		</Tooltip>
	);
};

export default NewTracker;
