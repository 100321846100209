import { useContext, useEffect, useCallback, useRef, useState, memo } from 'react';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';

import { ProjectContext } from '../../../../../context/ProjectProvider';

import { db } from '../../../../../firebase';
import { storage } from '../../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getMetadata, ref } from 'firebase/storage';

import { getGeocode, getLatLng } from 'use-places-autocomplete';

import styled from 'styled-components';
import { ProjectStatus } from '../../../../../types';
import { MapContext } from '../../../../../context/MapProvider';
import LoadingScreen from '../../../../../components/reusable-components/LoadingScreen';
import { AuthContext } from '../../../../../context/AuthProvider';
import {
	getCameraIcon,
	getPinSrc,
	startingLatLng,
} from '../../../../../screens/Map/components/MapComponent/utils';
import { createMapOptions } from '../../../../../screens/Map/components/MapComponent/google-map-options';
import ProjectCard from './ProjectCard';
import ContractorCard from './ContractorCard';
import theme from '../../../../../styles/theme';

type ProjectMapProps = {
	initialAddress: string;
};

type ProjectMarker = any;

const ProjectMap = ({ initialAddress }: ProjectMapProps) => {
	const { user } = useContext(AuthContext);
	const { contractors } = useContext(ProjectContext);
	const { mapProjects, fetchingMapProjects, googleMap } = useContext(MapContext);

	const [mapCenter, setMapCenter] = useState(startingLatLng);
	const [zoom, setZoom] = useState(8);

	const [projectMarkers, setProjectMarkers] = useState<any[]>([]);
	const [contractorMarkers, setContractorMarkers] = useState<any[]>([]);
	const [zoomLevel, setZoomLevel] = useState(8);

	const [activeMarker, setActiveMarker] = useState<string | null>(null);
	const [hoverMarker, setHoverMarker] = useState(null);
	let positionDict: any = {};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_google_maps_apiKey as string,
	});

	useEffect(() => {
		if (isLoaded && initialAddress && mapProjects?.length) {
			getGeocode({ address: initialAddress })
				.then(results => getLatLng(results[0]))
				.then(async ({ lat, lng }) => {
					const marker = mapProjects?.find(proj => proj.address === initialAddress);
					const coords = await getPosition(marker);
					const newMarker: ProjectMarker = {
						id: marker?.id,
						name: marker?.address,
						position: coords ?? undefined,
						project: marker,
					};
					setMapCenter({ lat, lng });
					setProjectMarkers([newMarker]);
					setZoom(8); // Zoom in closer to address that was passed
				})
				.catch(error => console.error('Error: ', error));
		}
	}, [initialAddress, isLoaded, mapProjects]);

	const handleActiveMarker = (
		markerId: string,
		position: google.maps.LatLng | google.maps.LatLngLiteral
	) => {
		if (markerId !== activeMarker) {
			setActiveMarker(markerId);
			googleMap.current?.panTo(position);
		}
	};

	const getPosition = (marker: any) => {
		return new Promise(resolve => {
			if (marker.address === '' || marker.lat === '' || marker.lng === '') {
				resolve(null);
			} else if (marker.lat && marker.lng) {
				resolve({
					lat: marker.lat,
					lng: marker.lng,
				});
			} else {
				getGeocode({ address: marker.address })
					.then(async results => {
						const { lat, lng } = await getLatLng(results[0]);
						updateDoc(doc(db, 'projects', marker.id), {
							lat: lat,
							lng: lng,
						});
						resolve({
							lat,
							lng,
						});
					})
					.catch(err => {
						console.error(err);
						if (err === 'ZERO_RESULTS') {
							updateDoc(doc(db, 'projects', marker.id), {
								lat: '',
								lng: '',
							});
						}
						resolve(null);
					});
			}
		});
	};

	const getContractorPosition = useCallback((marker: any) => {
		return new Promise((resolve, reject) => {
			// Already has lat/lng
			if (marker.lat && marker.lng) {
				resolve({
					...marker,
					position: {
						lat: marker.lat,
						lng: marker.lng,
					},
				});
			}
			// Needs to geocode lat/lng
			else {
				getGeocode({ address: marker.Address })
					.then(async results => {
						const { lat, lng } = await getLatLng(results[0]);
						updateDoc(doc(db, 'matterport_directory', marker.id), {
							lat: lat,
							lng: lng,
						});
						resolve({
							...marker,
							position: {
								lat: lat,
								lng: lng,
							},
						});
					})
					.catch(err => {
						console.error(`Error grabbing coords for ${marker.Name}:`, err);
						resolve({
							...marker,
							position: {
								lat: 0,
								lng: 0,
							},
						});
					});
			}
		});
	}, []);

	const formatContractor = useCallback(
		(marker: any) => {
			return new Promise((resolve, reject) => {
				(async () => {
					const withPos: any = await getContractorPosition(marker);
					// Contractor has files
					if (
						withPos.hasOwnProperty('files') &&
						Array.isArray(withPos.files) &&
						withPos.files.length > 0
					) {
						const fileLinks: any[] = [];
						// Iterate through files to format them into file links
						withPos.files.forEach((file: string, i: number) => {
							(async () => {
								const fileRef = ref(storage, `/contractors/${withPos.id}/` + file);
								const metaData = await getMetadata(fileRef);
								const url = await getDownloadURL(fileRef);
								fileLinks.push({
									...metaData,
									url,
									path: file,
									idx: i,
								});
								resolve({
									...withPos,
									fileLinks,
								});
							})();
						});
					}
					// Contractor has no files
					else resolve(withPos);
				})();
			});
		},
		[getContractorPosition]
	);

	const createContractorMarkers = useCallback(async () => {
		if (contractors.length && user?.isAdmin) {
			const newCMarkers: any[] = [];

			for (const contractor of contractors) {
				if (contractor && contractor.Address) {
					const formatted = (await formatContractor(contractor)) as any;
					checkDistance(mapCenter, formatted.position) && newCMarkers.push(formatted);
				}
			}

			setContractorMarkers(newCMarkers);
		}
	}, [contractors, formatContractor, user?.isAdmin, mapCenter]);

	// Create contractor markers
	useEffect(() => {
		isLoaded && createContractorMarkers();
	}, [isLoaded, projectMarkers, createContractorMarkers]);

	// Repaint clusters when amount of markers changes
	const clustererRef = useRef<any | null>(null);
	useEffect(() => {
		clustererRef.current?.repaint();
	}, [projectMarkers.length]);

	// Reset map hooks after component unmounts
	useEffect(() => {
		return () => {
			setActiveMarker(null);
		};
	}, [setActiveMarker]);

	return (
		!!googleMap && (
			<MapContainer>
				{fetchingMapProjects || !isLoaded ? (
					<LoadingScreen message="Loading projects..." />
				) : (
					<GoogleMap
						zoom={zoom}
						center={mapCenter}
						options={{
							styles: createMapOptions,
							mapTypeControl: false,
						}}
						onClick={() => setActiveMarker(null)}
						mapContainerStyle={{
							width: '100%',
							height: `405px`,
							position: 'relative',
							display: 'flex',
						}}
						onZoomChanged={() => {
							if (googleMap.current?.getZoom())
								setZoomLevel(googleMap.current.getZoom()!);
						}}
						onLoad={map => {
							googleMap.current = map;
						}}>
						{projectMarkers.length &&
							projectMarkers.map((marker: any, idx) => {
								let pos = marker.position ? { ...marker.position } : undefined;
								const posStr = `${marker.position?.lat} ${marker.position?.lng}`;

								if (!positionDict[posStr]) positionDict[posStr] = 1;
								else {
									if (pos) pos.lng = pos.lng + 0.00005 * positionDict[posStr];
									positionDict[posStr] += 1;
								}

								const defaultSize = { width: 30, height: 42 };
								const modifier = 0.2 + zoomLevel / 30;

								const scaledIconSize = new google.maps.Size(
									defaultSize.width * modifier,
									defaultSize.height * modifier
								);

								return (
									<Marker
										key={idx}
										zIndex={idx + 1}
										position={pos}
										onClick={() => handleActiveMarker(marker.project.id, pos)}
										options={{
											icon: {
												url: getPinSrc(marker.project.projectStatus as ProjectStatus),
												scaledSize: scaledIconSize,
											},
										}}
										title={
											marker.project.address || marker.project.name || 'Unnamed Project'
										}
										onMouseOver={() => setHoverMarker(marker.project.id)}
										onMouseOut={() => setHoverMarker(null)}>
										{activeMarker === marker.project.id ||
										hoverMarker === marker.project.id ? (
											<InfoWindow
												onCloseClick={() => {
													if (activeMarker === marker.project.id) setActiveMarker(null);
												}}
												options={{
													disableAutoPan: hoverMarker === marker.project.id,
												}}>
												<ProjectCard project={marker.project} />
											</InfoWindow>
										) : null}
									</Marker>
								);
							})}

						{user?.isAdmin &&
							contractorMarkers.length &&
							contractorMarkers.map((marker: any, idx) => {
								let pos = marker.position ? { ...marker.position } : undefined;
								const posStr = `${marker.position.lat} ${marker.position.lng}`;

								if (!positionDict[posStr]) positionDict[posStr] = 1;
								else {
									if (pos) pos.lng = pos.lng + 0.00005 * positionDict[posStr];
									positionDict[posStr] += 1;
								}
								let cameraIconUrl, iconSize;
								const modifier = 0.2 + zoomLevel / 30;

								[cameraIconUrl, iconSize] = getCameraIcon(marker?.['Cameras']);
								const mapsIconsSize = iconSize as google.maps.Size;
								const scaledIconSize = new google.maps.Size(
									mapsIconsSize.width * modifier,
									mapsIconsSize.height * modifier
								);

								return (
									<Marker
										key={marker.id}
										zIndex={idx + 1}
										position={pos}
										onClick={() => handleActiveMarker(marker.id, pos)}
										options={{
											icon: {
												url: cameraIconUrl as string,
												scaledSize: scaledIconSize,
											},
										}}
										visible={true}
										title={marker.Name}
										onMouseOver={() => setHoverMarker(marker.id)}
										onMouseOut={() => setHoverMarker(null)}>
										{activeMarker === marker.id || hoverMarker === marker.id ? (
											<InfoWindow
												onCloseClick={() => {
													if (activeMarker === marker.id) setActiveMarker(null);
												}}
												options={{ disableAutoPan: hoverMarker === marker.id }}>
												<ContractorCard
													capture={marker}
													setCMarkers={setContractorMarkers}
												/>
											</InfoWindow>
										) : null}
									</Marker>
								);
							})}
					</GoogleMap>
				)}
			</MapContainer>
		)
	);
};

export default memo(ProjectMap);

const MapContainer = styled.div`
	width: 100%;
	height: 100%;
	max-height: 405px;
	overflow: hidden;

	.gm-style-iw-d {
		overflow: visible !important;
	}

	.gm-style-iw.gm-style-iw-c {
		background-color: ${theme.palette.secondary.main};
	}

	.gm-style-iw-chr button span {
		filter: invert(100%);
	}

	.gm-style-iw-tc::after {
		background-color: ${theme.palette.secondary.main}!important;
	}
`;

const checkDistance = (mapCenter: any, contractor: any) => {
	const { lat: lat1, lng: lon1 } = mapCenter;
	const { lat: lat2, lng: lon2 } = contractor;
	const maxDistanceMiles = 100;
	const toRadians = (angle: number) => angle * (Math.PI / 180);

	const R = 6371; // Radius of the Earth in kilometers
	const dLat = toRadians(lat2 - lat1);
	const dLon = toRadians(lon2 - lon1);
	const lat1Rad = toRadians(lat1);
	const lat2Rad = toRadians(lat2);

	const a =
		Math.sin(dLat / 2) ** 2 +
		Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) ** 2;
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const distanceKm = R * c;

	const distanceMiles = distanceKm * 0.621371;
	return maxDistanceMiles > distanceMiles;
};
