import { useContext, useEffect, useState } from 'react';
import { Button, Switch } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import AppLink from '../../components/reusable-components/AppLink';
import AppText from '../../components/reusable-components/AppText';
import FormikTextField from '../../components/reusable-components/FormikTextField';
import { AuthContext } from '../../context/AuthProvider';
import BackgroundVideo from '../../components/reusable-components/BackgroundVideo';
import { ContractorRegisterValues } from '../../types/auth';
import { AuthActions, AuthContainer, AuthForm } from './Login';
import styled from 'styled-components';
import logoSrc from '../../assets/circleLogo.png';
import LoadingScreen from '../../components/reusable-components/LoadingScreen';
import { FirebaseError } from 'firebase/app';

const phoneRegExp =
	/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const validationsForm = {
	fullName: yup.string().required('Name is Required'),
	email: yup.string().email('Enter a Valid Email').required('Email is Required'),
	password: yup
		.string()
		.min(6, 'Password Must Contain At Least 6 Characters')
		.required('Password is Required'),
	passwordRepeat: yup
		.string()
		.oneOf([yup.ref('password')], 'Password Does Not Match')
		.required('Confirm Your password'),
};
const checkedValidationsForm = {
	fullName: yup.string().required('Name is Required'),
	email: yup.string().email('Enter a Valid email').required('Email is required'),
	address: yup.string().required('Address is required'),
	phone: yup
		.string()
		.matches(phoneRegExp, 'Enter a Valid Phone Number')
		.required('Phone Number is Required'),
	password: yup
		.string()
		.min(6, 'Password Must Contain At Least 6 Characters')
		.required('Password is Required'),
	passwordRepeat: yup
		.string()
		.oneOf([yup.ref('password')], 'Password Does Not Match')
		.required('Confirm Your Password'),
};

export default function Register() {
	const { handleOnRegister, loadingUser, authLoadingText } = useContext(AuthContext);

	const [checked, toggleChecked] = useState(false);
	const [errMessage, setErrMessage] = useState('');

	const handleRegister = ({
		fullName,
		email,
		password,
		passwordRepeat,
		address,
		phone,
	}: ContractorRegisterValues) => {
		const obj = {
			fullName,
			email,
			password,
			passwordRepeat,
		};
		return checked ? { ...obj, address, phone } : obj;
	};

	useEffect(() => {
		document.title = 'Register | Robotic Imaging';
	}, []);

	return (
		<>
			<Nav>
				<a href="https://www.roboticimaging.com/">
					<img
						src={logoSrc}
						alt=""
						style={{
							height: '40px',
							marginTop: '15px',
						}}
					/>
				</a>
			</Nav>

			<BackgroundVideo />

			<AuthContainer>
				{!loadingUser ? (
					<Formik
						validateOnBlur={true}
						validateOnChange={false}
						initialValues={{
							fullName: '',
							email: '',
							password: '',
							passwordRepeat: '',
							address: '',
							phone: '',
						}}
						validationSchema={yup
							.object()
							.shape(checked ? checkedValidationsForm : validationsForm)}
						onSubmit={async (values, { setFieldError, setSubmitting }) => {
							setErrMessage('');
							const obj = handleRegister(values);

							handleOnRegister(obj, checked).catch((err: FirebaseError) => {
								setSubmitting(false);
								if (err.code === 'functions/already-exists') {
									console.log(err.message);
									setErrMessage(err.message);
								}
							});
						}}>
						{({ errors, touched, handleSubmit, isSubmitting }) => (
							<AuthForm onSubmit={handleSubmit}>
								<AppText variant={'h5'} text="Create an Account" />

								<SwitchContainer>
									<StyledSwitch
										color="primary"
										checked={checked}
										onChange={() => toggleChecked(prev => !prev)}
										name="checkedA"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
									<span
										style={{
											color: checked ? '#ffb130' : 'white',
										}}>
										Contractor Account
									</span>
								</SwitchContainer>

								<FormikTextField
									label="Full Name"
									name="fullName"
									type="text"
									helperText={touched.fullName ? errors.fullName : null}
									error={touched.fullName && Boolean(errors.fullName)}
								/>
								<FormikTextField
									label="Email"
									name="email"
									type="email"
									helperText={touched.email ? errors.email : null}
									error={touched.email && Boolean(errors.email)}
									autoComplete="username"
								/>
								{checked ? (
									<FormikTextField
										label="Address"
										name="address"
										type="address"
										helperText={touched.address ? errors.address : null}
										error={touched.address && Boolean(errors.address)}
									/>
								) : null}
								{checked ? (
									<FormikTextField
										label="Phone Number"
										name="phone"
										type="phone"
										helperText={touched.phone ? errors.phone : null}
										error={touched.phone && Boolean(errors.phone)}
									/>
								) : null}
								<FormikTextField
									label="Password"
									name="password"
									type="password"
									helperText={touched.password ? errors.password : null}
									error={touched.password && Boolean(errors.password)}
									autoComplete="new-password"
								/>
								<FormikTextField
									label="Retype Password"
									name="passwordRepeat"
									type="password"
									helperText={touched.passwordRepeat ? errors.passwordRepeat : null}
									error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
									autoComplete="new-password"
								/>

								{errMessage ? <ErrorMessage>{errMessage}</ErrorMessage> : null}

								<AuthActions>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										disabled={isSubmitting}>
										Login
									</Button>
									<AppLink to="/login" text="Already have an account?" />
								</AuthActions>
							</AuthForm>
						)}
					</Formik>
				) : (
					<LoadingScreen message={authLoadingText} />
				)}
			</AuthContainer>
		</>
	);
}

const Nav = styled.nav`
	background: transparent;
	height: 50px;
	display: flex;
	padding-left: 20px;
	align-items: left;
	position: fixed;
`;

const SwitchContainer = styled.div``;

const StyledSwitch = styled(Switch)`
	> .MuiSwitch-track {
		background-color: white;
	}
`;

const ErrorMessage = styled.span`
	color: red;
`;
