import { FC, useState } from 'react';
import { BlockMenuButton } from '../BlockMenuButton';
import { AddElementDialog } from '../AddElementDialog';

const FormFooter: FC = () => {
	const [showDialog, setShowDialog] = useState<boolean>(false);

	const handleClose = () => setShowDialog(false);

	return (
		<>
			<BlockMenuButton onChange={() => setShowDialog(true)} />
			<AddElementDialog
				open={showDialog}
				handleClose={handleClose}
				excludedTypes={['input', 'area', 'select', 'switch', 'date', 'datetime']}
			/>
		</>
	);
};

export default FormFooter;
