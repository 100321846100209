import { FieldLabel } from '../../../../../../screens/ProjectInfo';
import { AuthContext } from '../../../../../../context/AuthProvider';
import { ProjectData } from '../../../../../../types';
import { fetchEquipment } from '../../../../../../utils/fetchEquipment';
import React, { useContext, useEffect, useState } from 'react';
import EquipmentChips from './EquipmentChips';

interface EquipmentProps {
	isActive: boolean;
	project: ProjectData;
	partialFormState: Partial<ProjectData>;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

export default function Equipment({
	isActive,
	project,
	partialFormState,
	setPartialFormState,
}: EquipmentProps) {
	const { user } = useContext(AuthContext);
	const [editEquipment, setEditEquipment] = useState(false);
	const [equipmentOptions, setEquipmentOptions] = useState<string[]>([]);

	useEffect(() => {
		const getEquipmentOptions = async () => {
			const options = await fetchEquipment();
			setEquipmentOptions(options);
		};
		getEquipmentOptions();
	}, []);

	return (
		<div className="infoRow">
			<FieldLabel label={'Equipment'} />
			{user?.isAdmin && isActive ? (
				editEquipment ? (
					<EquipmentChips
						form={partialFormState}
						field="equipment"
						setForm={setPartialFormState}
						label={false}
						onBlur={() => setEditEquipment(false)}
						options={equipmentOptions}
					/>
				) : (
					<span
						className="fieldInfo fieldInfo-edit"
						title="Click to edit equipment"
						onClick={() => {
							setEditEquipment(true);
						}}
						tabIndex={0}>
						{partialFormState?.equipment?.join(', ') || 'N/A'}
					</span>
				)
			) : (
				<div className="fieldInfo">{project?.equipment?.join(', ')}</div>
			)}
		</div>
	);
}
