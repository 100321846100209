import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import StringTextField from '../StringTextField';
import { addCommasToNumber, parseNumFromStr } from '../../../../../../../utils';

export default function SqftCell({
	row,
	rowEditing,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
}) {
	const projectId = row.original.id as string;
	const sqft = parseNumFromStr(row.original.buildingSize);

	return !rowEditing ? (
		<span>{sqft ? `${addCommasToNumber(sqft)} sqft` : '-'}</span>
	) : (
		<StringTextField
			projectId={projectId}
			defaultValue={row.original.buildingSize}
			field="buildingSize"
		/>
	);
}
