import { useContext, useState } from 'react';
import styled from 'styled-components';
import ProjectStatusTables from '../../../components/reusable-components/ProjectStatusTables';
import { ProjectStatus, UserObj } from '../../../types';
import { YellowButton } from '../../../components/styled-components/styledReactTable';
import Searchbar from '../../../components/reusable-components/Searchbar';
import { LoadingContext } from '../../../context/LoadingProvider';
import { AuthContext } from '../../../context/AuthProvider';
import { MenuItem, TextField } from '@mui/material';

export default function LeadsStatusTable() {
	const {
		loadingProjects,
		setLoadingProjects,
		loadingProjectStatuses,
		setLoadingProjectStatuses,
	} = useContext(LoadingContext);
	const { projectRoleOptions } = useContext(AuthContext);
	const [showStatusTables, setShowStatusTables] = useState(false);
	const [projectQuery, setProjectQuery] = useState('');
	const [accManFilter, setAccManFilter] = useState<UserObj | null>(null);

	const projectStatuses: ProjectStatus[] = ['Proposal Pending', 'Proposal Sent'];

	return (
		<Container>
			<TableHeader>
				<YellowButton onClick={() => setShowStatusTables(prev => !prev)}>
					{!showStatusTables ? 'Show Bid Table' : 'Hide Bid Table'}
				</YellowButton>

				{showStatusTables ? (
					<>
						<StyledSearchbar
							query={projectQuery}
							setQuery={setProjectQuery}
							showSpinner={loadingProjects || loadingProjectStatuses}
							onDebounceStart={() => {
								setLoadingProjects(true);
								setLoadingProjectStatuses(true);
							}}
							onDebounceEnd={() => {
								setLoadingProjects(false);
								setLoadingProjectStatuses(false);
							}}
							placeholder="Search projects..."
						/>

						<AccManSelect
							select
							SelectProps={{ displayEmpty: true }}
							value={accManFilter ? accManFilter.id : ''}
							onChange={e => {
								const selectedId = e.target.value as string;
								if (selectedId) {
									const selectedManager = projectRoleOptions['accountManager'].find(
										userWithData => userWithData.id === selectedId
									);

									selectedManager
										? setAccManFilter({
												email: selectedManager.email,
												name: selectedManager.fullName,
												id: selectedManager.id,
										  })
										: setAccManFilter(null);
								} else {
									setAccManFilter(null);
								}
							}}>
							<MenuItem value={''}>None</MenuItem>
							{projectRoleOptions['accountManager'].map(userWithData => (
								<MenuItem value={userWithData.id} key={userWithData.id}>
									{userWithData.fullName}
								</MenuItem>
							))}
						</AccManSelect>
					</>
				) : null}
			</TableHeader>

			{showStatusTables ? (
				<ProjectStatusTables
					statuses={projectStatuses}
					fullHeight={false}
					query={projectQuery}
					padTableContainer={false}
					accountManagerFilter={accManFilter}
				/>
			) : null}
		</Container>
	);
}

const TableHeader = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	max-width: 100%;

	@media (max-width: 1023px) {
		width: 100%;
	}

	@media (max-width: 679px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: flex-start;
	width: 100%;
`;

const StyledSearchbar = styled(Searchbar)`
	@media (max-width: 1023px) {
		flex-grow: 1;
	}

	@media (max-width: 679px) {
		min-width: 100%;
	}
`;

const AccManSelect = styled(TextField)`
	.MuiSelect-select {
		color: #ffb310;
	}

	.MuiSvgIcon-root {
		fill: #ffb310;
	}

	fieldset {
		border-color: #ffb310;
	}

	.MuiInputBase-root:hover {
		fieldset {
			border-color: white;
		}
	}
`;
