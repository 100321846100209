import styled from 'styled-components';
import { GroupedStatusTableProvider } from './GroupedStatusTableProvider';
import GroupedTable from './components/GroupedTable';

export default function GroupedStatusTable() {
	return (
		<GroupedStatusTableProvider>
			<Container>
				<GroupedTable />
			</Container>
		</GroupedStatusTableProvider>
	);
}

const Container = styled.div`
	padding: 0 20px;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
`;
