import { useState, useEffect, useContext } from 'react';
import { useOrganizationsColumns } from '../components/screen-components/Organizations/OrganizationsColumns';
import { AuthContext } from '../context/AuthProvider';
import { checkPermissions } from '../utils';
import { Organization } from '../types';
import { OrganizationsContext } from '../context/OrganizationsProvider';
import Layout from '../components/reusable-components/Layout';
import AddOrganizationDialog from '../components/screen-components/Organizations/AddOrganizationDialog';
import DeleteOrganizationDialog from '../components/screen-components/Organizations/DeleteOrganizationDialog';
import DataTable from '../components/reusable-components/DataTable';

import SkeletonTable from '../components/reusable-components/SkeletonTable';
import styled from 'styled-components';

export default function OrganizationsList() {
	const { loadingOrganizations, organizations } = useContext(OrganizationsContext);
	const [organizationToBeDeleted, setOrganizationToBeDeleted] = useState<
		Organization | undefined
	>();
	const [openOrganizationDialog, setOpenOrganizationDialog] = useState(false);
	const { user } = useContext(AuthContext);
	const isAdmin = checkPermissions(user, 'admin');

	const { organizationsColumns } = useOrganizationsColumns({
		onDeleteOrganization: organization => setOrganizationToBeDeleted(organization),
		isAdmin,
	});

	useEffect(() => {
		document.title = 'Organizations | Robotic Imaging';
	}, []);

	return (
		<Layout title="Organizations">
			<>
				{loadingOrganizations ? (
					<SkeletonContainer>
						<SkeletonTable rows={10} />
					</SkeletonContainer>
				) : (
					<TableWrapper>
						<DataTable
							columns={organizationsColumns}
							data={organizations}
							name="organization"
							onAdd={() => setOpenOrganizationDialog(true)}
							searchbar
							children={
								<DeleteOrganizationDialog
									organizationToBeDeleted={organizationToBeDeleted}
									setOrganizationToBeDeleted={setOrganizationToBeDeleted}
								/>
							}
							showButton={isAdmin}
								queryFields={['name']}
						/>
					</TableWrapper>
				)}
				<AddOrganizationDialog
					open={openOrganizationDialog}
					setOpen={setOpenOrganizationDialog}
				/>
			</>
		</Layout>
	);
}

const SkeletonContainer = styled.div`
	max-width: 800px;
	width: 100%;
	margin: auto;
	margin-top: 80px;
`;

const TableWrapper = styled.div`
	display: block;
	max-width: 100%;
	min-width: 600px;
	width: 70%;
	overflow: hidden;
	border-radius: 20px;
	margin: 0 auto;
	padding: 1vw 0;
`;
