import styled from 'styled-components';

export const TermsAndPrivacy = () => {
	return (
		<Terms>
			By continuing, you agree to our{' '}
			<ClickableText
				onClick={() =>
					window.open('https://www.roboticimaging.com/terms-and-conditions', '_blank')
				}>
				Terms of Service
			</ClickableText>{' '}
			and acknowledge our{' '}
			<ClickableText
				onClick={() =>
					window.open('https://www.roboticimaging.com/privacy-policy', '_blank')
				}>
				Privacy Notice
			</ClickableText>
			.
		</Terms>
	);
};

const Terms = styled.span`
	color: #ffb310;
	background-color: black;
	padding: 30px;
	border-radius: 20px;
`;

const ClickableText = styled.span`
	cursor: pointer;
	font-weight: bold;
	color: #ffb310;
	&:hover {
		text-decoration: underline;
	}
`;
