import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Box,
} from '@mui/material';
import { FC } from 'react';
import useTemplates from './useTemplates';
import GrayButton from './GrayButton';

type PickTemplateDialogProps = {
	handleClose: () => void;
	handlePickTemplate: (templateId: string) => void;
	open: boolean;
};

const PickTemplateDialog: FC<React.PropsWithChildren<PickTemplateDialogProps>> = ({
	handleClose,
	handlePickTemplate,
	open,
}) => {
	const { addToHistory, history, onChangeQuery, templates } = useTemplates(open);

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Choose a template</DialogTitle>
			<DialogContent style={{ alignItems: 'center', width: 400, paddingBottom: 30 }}>
				<Box
					height={360}
					width="100%"
					alignItems="center"
					display="flex"
					flexDirection="column">
					<TextField
						label="Search for a template"
						name="Template"
						variant="outlined"
						onChange={onChangeQuery}
						style={{ width: 300, margin: 20 }}
					/>
					{templates?.map(template => (
						<GrayButton
							key={template.id}
							color="primary"
							onClick={() => {
								handlePickTemplate(template.id);
								addToHistory(template);
							}}>
							{template.name}
						</GrayButton>
					))}
				</Box>
				{history?.length > 0 && <Box style={{ marginTop: 30 }}>Last used templates</Box>}
				{history?.map(template => (
					<GrayButton
						key={template.id}
						color="primary"
						onClick={() => handlePickTemplate(template.id)}>
						{template.name}
					</GrayButton>
				))}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PickTemplateDialog;
