import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/AuthProvider';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { PendingUserObj } from '../../../screens/TeamPage';

const useTeamUsers = (teamId: string, organizationId: string) => {
	const [pendingUsers, setPendingUsers] = useState<PendingUserObj[]>([]);
	const { user } = useContext(AuthContext);

	const loadTeamUsers = useCallback(async () => {
		const teamIds = user?.teamIds as string[];

		// Only fetch pending shares is the user is an admin or if the user's 'teamIds' includes
		// the current 'teamId' (which should always be the case if the user can access the team page).
		if (
			user?.isAdmin ||
			(teamIds && Array.isArray(teamIds) && teamIds.includes(teamId))
		) {
			const coll = collection(db, 'pending_project_shares');
			const q = query(coll, where('teamIds', 'array-contains', teamId));

			getDocs(q)
				.then(res => {
					const resArr: PendingUserObj[] = [];
					res.docs.forEach(d => {
						const docData = d.data();
						if (docData.email && docData.lastUpdated) {
							resArr.push({
								email: docData.email as string,
								lastShared: new Date(docData.lastUpdated.seconds * 1000),
								teamIds: docData.teamIds as string[],
								organizationIds: docData.organizationIds as string[],
							});
						}
					});
					if (resArr.length) setPendingUsers(resArr);
				})
				.catch(err => console.error(`Could not fetch pending shares`, err));
		}

		if (user?.adminOrgIds?.includes(organizationId)) {
			const coll = collection(db, 'pending_project_shares');
			const q = query(coll, where('organizationIds', 'array-contains', organizationId));

			getDocs(q)
				.then(res => {
					const resArr: PendingUserObj[] = [];
					res.docs.forEach(d => {
						const docData = d.data();
						if (docData.email && docData.lastUpdated) {
							resArr.push({
								email: docData.email as string,
								lastShared: new Date(docData.lastUpdated.seconds * 1000),
								teamIds: docData.teamIds as string[],
								organizationIds: docData.organizationIds as string[],
							});
						}
					});
					if (resArr.length)
						setPendingUsers(resArr.filter(p => p.teamIds?.includes(teamId)));
				})
				.catch(err => console.error(`Could not fetch pending shares`, err));
		}
	}, [organizationId, teamId, user]);

	// Fetches pending shares that include the 'teamId'.
	useEffect(() => {
		loadTeamUsers();
	}, [loadTeamUsers]);

	return { loadTeamUsers, pendingUsers };
};

export default useTeamUsers;
