import React, { ReactNode, useContext } from 'react';
import styled from 'styled-components';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvider';

type SidebarProps = {
	backButtonTo: string;
	backButtonTitle: string;
	children: ReactNode;
	photoComponent?: ReactNode;
};

export const Sidebar = ({
	backButtonTo,
	backButtonTitle,
	children,
	photoComponent,
}: SidebarProps) => {
	const { user } = useContext(AuthContext);

	return (
		<SidebarContainer>
			<ContentContainer>
				{user?.isAdmin ? (
					<BackButton to={backButtonTo}>
						<ArrowBack style={{ color: '#ffb310' }} />
						<span>{backButtonTitle}</span>
					</BackButton>
				) : null}

				{children}
			</ContentContainer>

			{photoComponent}
		</SidebarContainer>
	);
};

const SidebarContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 250px;
	padding: 20px;
	background-color: black;
	border-right: 1px solid rgba(89, 89, 89, 0.4);
	height: 100%;

	@media (max-width: 768px) {
		display: none;
	}
`;

const ContentContainer = styled.div``;

const BackButton = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 20px 0;
	transition: all 0.2s;

	> span {
		padding-left: 5px;
		color: white;
		font-size: 1.1rem;

		@media (max-width: 768px) {
			font-size: 0.9rem;
		}
	}

	&:hover {
		background-color: rgba(255, 179, 16, 0.04);
		> span {
			color: #ffb310;
			text-decoration: underline;
		}
	}
`;
