import { useContext, useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
} from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { Organization, Team } from '../../../types';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import styled from 'styled-components';
import InfoIcon from '../../reusable-components/InfoIcon/InfoIcon';

const defaultTeam: Team = {
	id: '',
	isAdmin: false,
	name: '',
	organizationId: '',
	projectIds: [],
	userIds: [],
};

export default function AddTeamDialog({
	open,
	organizationId,
	organizations,
	setOpen,
	onSave,
}: {
	open: boolean;
	organizationId?: string;
	organizations?: Organization[];
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSave?: () => void;
}) {
	const { addingTeam, addTeam } = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [form, setForm] = useState(defaultTeam);
	const [selectedOrganizationId, setSelectedOrganizationId] = useState<
		string | undefined
	>();

	useEffect(() => {
		if (organizations && organizations.length > 0) {
			setSelectedOrganizationId(organizations[0].id);
		}
	}, [organizations]);

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const field = e.target.name;
		setForm(prev => ({
			...prev,
			[field]: e.target.value,
		}));
	};

	const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const field = e.target.name;
		setForm(prev => ({
			...prev,
			[field]: e.target.checked,
		}));
	};

	const handleClose = () => setOpen(false);

	const handleSubmit = async () => {
		if (!form.name) return;

		const org = organizationId || selectedOrganizationId;
		if (!org) {
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Failed to submit new team!`,
			});
			return;
		}

		try {
			const success = await addTeam({
				id: '',
				name: form.name,
				organizationId: org,
				isAdmin: form.isAdmin,
			});
			handleClose();
			setForm(defaultTeam);

			if (success) {
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `Successfully submitted new team!`,
				});
				onSave?.();
			} else {
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Failed to submit new team!`,
				});
			}
		} catch (err) {
			console.error(err);
			handleClose();
			setForm(defaultTeam);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Create A New Team</DialogTitle>
			{!addingTeam ? (
				<DialogContent>
					{organizations && organizations.length > 0 && (
						<>
							{organizations.length > 1 ? (
								<>
									<InputLabel>Organization</InputLabel>
									<Select
										value={selectedOrganizationId}
										onChange={e =>
											setSelectedOrganizationId(
												organizations.find(org => org.id === e.target.value)?.id
											)
										}>
										{organizations.map((org, i) => (
											<MenuItem key={i} value={org.id}>
												{org.name}
											</MenuItem>
										))}
									</Select>
								</>
							) : (
								<>
									<InputLabel>Organization</InputLabel>
									<br />
									{organizations[0].name}
								</>
							)}
						</>
					)}
					<TextField
						variant="standard"
						label="Team Name"
						name="name"
						value={form.name}
						onChange={onChange}
					/>
					<SwitchRow>
						<SwitchContainer
							control={<Switch value={form.isAdmin} onChange={onChangeSwitch} />}
							label="Admin"
							name="isAdmin"
						/>
						<InfoIcon
							title={'What is an Admin Team?'}
							message={`Admin Teams allow you to manage your organization. \n\nOnce an Admin Team is created, any user added to the team will be able to: \n • make new teams \n • delete existing teams \n • add users and/or projects to any teams in the organization \n • remove users and/or projects from any teams in the organization. \n \nAdmin teams are only visible to the users on the team.`}
						/>
					</SwitchRow>
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Creating team..." textColor="black" />
				</DialogContent>
			)}

			<DialogActions>
				{!addingTeam ? (
					<>
						<Button onClick={handleClose} sx={{ textTransform: 'none' }}>
							Cancel
						</Button>
						<Button onClick={handleSubmit} sx={{ textTransform: 'none' }}>
							Create Team
						</Button>
					</>
				) : null}
			</DialogActions>
		</Dialog>
	);
}

const SwitchContainer = styled(FormControlLabel)`
	color: #0d0d0c;
	align-items: center;

	.MuiSwitch-track {
		background-color: rgba(0, 0, 0, 0.5);
	}
`;

const SwitchRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	padding: 20px 0;
`;
