import { IconButton, Menu, MenuItem } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const StyledIconButton = styled(IconButton)`
	padding: 0;
	transition: 200ms;

	&:hover {
		opacity: 0.8;
	}

	> svg {
		height: 28px;
		width: 28px;
	}
`;

export const ButtomMenu = styled(Menu)`
	.MuiPaper-root {
		background-color: ${theme.palette.secondary.light};
	}
`;

export const MenuOption = styled(MenuItem)`
	display: flex;
	gap: 10px;
	align-items: center;
	color: white;

	> svg {
		fill: ${theme.palette.primary.main};
	}
`;
