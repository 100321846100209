import { useContext, useState } from 'react';
import AddContractorForm from './components/AddContractorForm';
import AddProjectForm from '../../components/reusable-components/AddProjectForm';
import Layout from '../../components/reusable-components/Layout';
import ProjectMap from './components/MapComponent';
import Searchbar from '../../components/reusable-components/Searchbar';
import MapCameraFilterButton from './components/MapCameraFilterButton';
import { MapContext, mapFilters } from '../../context/MapProvider';
import { AuthContext } from '../../context/AuthProvider';
// import ExportContractorsButton from '../components/screen-components/Map/ExportContractorsButton';

export default function MapScreen({ address }: { address?: string }) {
	const { user } = useContext(AuthContext);
	const { query, setQuery, filter, setFilter } = useContext(MapContext);

	const [showingAddProject, setShowingAddProject] = useState(false);
	const [showingAddContractor, setShowingAddContractor] = useState(false);

	return (
		<Layout
			LeftNavComponent={
				<>
					<Searchbar
						query={query}
						setQuery={setQuery}
						filterOptions={mapFilters}
						filter={user?.isAdmin ? filter : undefined}
						setFilter={setFilter as React.Dispatch<React.SetStateAction<string>>}
					/>
					{user?.isAdmin ? <MapCameraFilterButton /> : null}
				</>
			}
			// RightNavComponent={<ExportContractorsButton />}
		>
			<ProjectMap
				setShowingDialog={setShowingAddProject}
				setShowingCDialog={setShowingAddContractor}
				initialAddress={address}
			/>

			<AddProjectForm
				showingDialog={showingAddProject}
				setShowingDialog={setShowingAddProject}
			/>
			<AddContractorForm
				showingDialog={showingAddContractor}
				setShowingDialog={setShowingAddContractor}
			/>
		</Layout>
	);
}
