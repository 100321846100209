import { sendEmail } from '../../firebase/auth';
import { AuthContext } from '../../context/AuthProvider';
import { ProjectContext } from '../../context/ProjectProvider';
import { bccMailingList } from '../../firebase/auth';
import { TextField, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import { OrganizationsContext } from '../../context/OrganizationsProvider';
import { Organization, ProjectBase, UserObj } from '../../types';
import { projectStatusText, projectStatuses, skuOptions } from '../../utils';
import PlacesAutocompleteWrapper from './PlacesAutocomplete/PlacesAutocompleteWrapper';
import MultiAutoCompleteInput from './MultiAutoCompleteInput';
import OrgTeamSelector from './OrgTeamSelector';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useOrgTeams from '../screen-components/ProjectInfo/components/field-components/SharedTeams/hooks/useOrgTeams';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

const emptyProject = (isClient = false, clients: string[] = []) => {
	const projectBase: ProjectBase = {
		projectStatus: !isClient ? 'Proposal Pending' : 'To Be Scheduled',
		clients: clients,
		address: '',
		buildingSize: '',
		sku: [],
		subTotal: '',
		name: '',
		cameras: [],

		designSoftware: [],
		modelers: [],
		registrationManagers: [],
		qaQcManagers: [],

		bimTechnicians: [],
		modelingScope: '',

		description: '',
		internalNotes: '',
		owner: '',
		lastContacted: '',
		signedProposal: false,

		matterport: [],
		twoDLinks: [],
		threeDLinks: [],
		pointCloudLinks: [],
		invoiceLinks: [],

		proposal: [],
		pointCloud: [],
		photos: [],
		pdf: [],
		autodesk: [],

		accountsPayable: [],
	};
	return projectBase;
};

/**
 * Functional component for displaying a dialog box to add a new project
 * @param showingDialog boolean useState that determines whether or not the dialog is shown
 * @param setShowingDialog useState updater for showingDialog
 */
export default function AddProjectForm({
	showingDialog,
	setShowingDialog,
	isClient = false,
	clients = [],
	accountManager,
	leadsAddition,
	orgRequired,
}: {
	showingDialog: boolean;
	setShowingDialog: React.Dispatch<React.SetStateAction<boolean>>;
	isClient?: boolean;
	clients?: string[];
	accountManager?: UserObj;
	leadsAddition?: boolean;
	orgRequired?: boolean;
}) {
	const { handleAddProject, cachedClients, loadingAutofillData } =
		useContext(ProjectContext);
	const { user, projectRoleOptions } = useContext(AuthContext);
	const { organizations } = useContext(OrganizationsContext);
	const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
	const [formState, setFormState] = useState(emptyProject(isClient, clients));
	const [selectedTeams, addTeam, removeTeam] = useOrgTeams([]);

	const handleClose = () => {
		setFormState(emptyProject(isClient, clients));
		setShowingDialog(false);
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		const teamIds: string[] = selectedTeams.map(team => team.id);

		const updatedFormState = {
			...formState,
			sharedTeams: teamIds,
		};
		console.log(updatedFormState);

		handleAddProject(updatedFormState);

		if (isClient && user) {
			sendEmail({
				destination: process.env.REACT_APP_firebase_info_email,
				cc: [],
				bcc: process.env.REACT_APP_firebase_env === 'prod' ? bccMailingList : [],
				subject: 'Client Has Created a New Project',
				text: `${user.fullName} (${user.email}) has created a project, ${
					formState.address || formState.name || 'Unnamed Project'
				}, with a status of "To Be Scheduled".`,
				html: `<div>
				${user.fullName} (${user.email}) has created a project, ${
					formState.address || formState.name || 'Unnamed Project'
				}, with a status of "To Be Scheduled".
				</div>`,
			});
		}

		handleClose();
	};

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const value = e.target.value;
		setFormState({
			...formState,
			[e.target.name]: value,
		});
	};

	return (
		<Dialog open={showingDialog} onClose={handleClose} maxWidth="xs" fullWidth>
			<form onSubmit={handleSubmit}>
				<DialogTitle>Create A New Project</DialogTitle>
				<DialogContent>
					<TextField
						select
						name="projectStatus"
						label="Status"
						InputLabelProps={{
							shrink: true,
						}}
						value={!isClient ? formState.projectStatus : 'To Be Scheduled'}
						disabled={isClient}
						onChange={handleChange}
						fullWidth
						variant="standard"
						required>
						{projectStatuses.map(status => (
							<MenuItem value={status} key={status}>
								{projectStatusText(status)}
							</MenuItem>
						))}
					</TextField>

					<OrgTeamSelector
						organizations={organizations}
						selectedOrg={selectedOrg}
						setSelectedOrg={setSelectedOrg}
						selectedTeams={selectedTeams}
						addTeam={addTeam}
						removeTeam={removeTeam}
						orgRequired={orgRequired}
					/>

					{leadsAddition &&
						(!isClient && user?.isAdmin ? (
							<MultiAutoCompleteInput
								label="Client(s)"
								name="clients"
								value={formState.clients}
								options={cachedClients ?? []}
								onChange={(e, newValue) => {
									setFormState(prev => ({
										...prev,
										clients: Array.from(newValue),
									}));
								}}
								onBlur={inputValue => {
									setFormState(prev => ({
										...prev,
										clients: [...prev.clients, inputValue],
									}));
								}}
								autoFocus={false}
								variant="standard"
								required
							/>
						) : null)}

					<PlacesAutocompleteWrapper
						formState={formState}
						setFormState={
							setFormState as React.Dispatch<React.SetStateAction<{ [key: string]: any }>>
						}
						addressField="address"
						required
					/>

					{!isClient && user?.isAdmin ? (
						<>
							<TextField
								variant="standard"
								label="Account Manager"
								name="accountManager"
								select
								fullWidth
								value={formState.accountManager?.id}
								onChange={e => {
									const selectedId = e.target.value as string;
									if (selectedId) {
										const selectedUser = projectRoleOptions['accountManager'].find(
											userWithData => userWithData.id === selectedId
										);
										if (selectedUser) {
											setFormState(prev => ({
												...prev,
												accountManager: {
													email: selectedUser.email,
													name: selectedUser.fullName,
													id: selectedUser.id,
												},
											}));
										}
									} else {
										setFormState(prev => ({
											...prev,
											accountManager: undefined,
										}));
									}
								}}
								required>
								{projectRoleOptions['accountManager'].map(userWithData => (
									<MenuItem value={userWithData.id} key={userWithData.id}>
										{userWithData.fullName}
									</MenuItem>
								))}
							</TextField>

							<TextField
								label="SQFT"
								name="buildingSize"
								value={formState.buildingSize}
								onChange={handleChange}
								fullWidth
								variant="standard"
								required
							/>

							<MultiAutoCompleteInput
								label="Client Accounting Email"
								name="accountsPayable"
								value={formState.accountsPayable}
								options={[]}
								onChange={(e, newValue) => {
									setFormState(prev => ({
										...prev,
										accountsPayable: Array.from(newValue),
									}));
								}}
								onBlur={inputValue => {
									setFormState(prev => ({
										...prev,
										accountsPayable: [...prev.accountsPayable, inputValue],
									}));
								}}
								autoFocus={false}
								variant="standard"
								required
							/>

							<MultiAutoCompleteInput
								label="SKU"
								name="sku"
								value={formState.sku}
								options={skuOptions}
								onChange={(e, newValue) => {
									setFormState(prev => ({
										...prev,
										sku: Array.from(newValue),
									}));
								}}
								onBlur={inputValue => {
									setFormState(prev => ({
										...prev,
										sku: [...prev.sku, inputValue],
									}));
								}}
								autoFocus={false}
								variant="standard"
								required
								loading={loadingAutofillData}
								loadingText="Loading SKU options..."
							/>

							<TextField
								label="Sub-Total"
								name="subTotal"
								value={formState.subTotal}
								onChange={handleChange}
								fullWidth
								variant="standard"
								required
							/>

							<TextField
								label="BIM Notes"
								name="modelingScope"
								value={formState.modelingScope}
								onChange={handleChange}
								fullWidth
								multiline
								maxRows={5}
								variant="standard"
								required
							/>

							<TextField
								label="Internal Notes"
								name="internalNotes"
								value={formState.internalNotes}
								onChange={handleChange}
								fullWidth
								multiline
								maxRows={5}
								variant="standard"
							/>

							{/* <TextField
							id="name"
							name="name"
							value={formState.name}
							onChange={handleChange}
							fullWidth
							variant="standard"
						/> */}

							{/* <CameraChips
							form={formState}
							cameraField="cameras"
							setForm={setFormState}
						/> */}

							{/* <Autocomplete
							name="designSoftware"
							freeSolo={true}
							fullWidth
							options={cachedDesignSoftware}
							multiple
							onChange={(e, newValue) => {
								setFormState({
									...formState,
									designSoftware: newValue,
								});
							}}
							renderInput={params => (
								<TextField {...params} label="Design Software" variant="standard" />
							)}
						/> */}

							{/* <Autocomplete
							name="modelers"
							freeSolo={true}
							fullWidth
							options={cachedModelers}
							multiple
							onChange={(e, newValue) => {
								setFormState({
									...formState,
									modelers: newValue,
								});
							}}
							renderInput={params => (
								<TextField {...params} label="Modeler(s)" variant="standard" />
							)}
						/> */}
						</>
					) : (
						<TextField
							label="Project Notes / Access"
							name="description"
							value={formState.description}
							onChange={handleChange}
							fullWidth
							multiline
							maxRows={5}
							variant="standard"
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button type="submit" color="primary">
						Submit
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
