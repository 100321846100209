import { ProjectCategory } from '../../../utils';
import { useContext, useState } from 'react';
import {
	ScopeContainer,
	ScopeContent,
	// ScopeEditButton,
	ScopeTitle,
	ScopeTitleContainer,
} from '../styledComponents';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import Section, { ArrowContainer } from './Section';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type MultiSectionProps = {
	category: ProjectCategory;
	showCheckboxes: boolean;
	allowEdits?: boolean;
};

export default function MultiSection({
	category,
	showCheckboxes,
	allowEdits,
}: MultiSectionProps) {
	const { cachedProjectScopes } = useContext(ProjectContext);

	const [showContent, setShowContent] = useState(false);

	const scopeObj = cachedProjectScopes[category];

	// Handles click event on section title
	const handleClick = () => {
		setShowContent(prev => !prev);
	};

	return (
		<ScopeContainer>
			<ScopeTitleContainer>
				<ScopeTitle onClick={handleClick} $isInnerSection={false}>
					{scopeObj.title}
				</ScopeTitle>
				<ArrowContainer $isInnerSection={false}>
					{showContent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</ArrowContainer>
			</ScopeTitleContainer>

			{showContent ? (
				<ScopeContent>
					{scopeObj.sections.map((section, i) => {
						return (
							<Section
								key={section.title + i}
								category={category}
								cachedSection={section}
								multiTitle={scopeObj.title}
								showCheckboxes={showCheckboxes}
								allowEdits={allowEdits}
								isInnerSection
							/>
						);
					})}
				</ScopeContent>
			) : null}
		</ScopeContainer>
	);
}
