import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import { PendingUser, SharedTeam, SharedUser } from '../../types';
import { useState } from 'react';
import SharedUsers from './SharedUsers';
import SharedTeams from './SharedTeams';

interface SharedUsersAndTeamsProps {
	sharedUsers: SharedUser[];
	pendingUsers: PendingUser[];
	sharedTeams: SharedTeam[];
	getSetMembers: () => Promise<void>;
}

export default function SharedUsersAndTeams({
	sharedUsers,
	pendingUsers,
	sharedTeams,
	getSetMembers,
}: SharedUsersAndTeamsProps) {
	const [tabValue, setTabValue] = useState(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	return (
		<Container>
			<Tabs value={tabValue} onChange={handleChange}>
				<Tab label="Shared Users" value={0} />
				<Tab label="Shared Teams" value={1} />
			</Tabs>

			{tabValue === 0 ? (
				<SharedUsers
					sharedUsers={sharedUsers}
					pendingUsers={pendingUsers}
					getSetMembers={getSetMembers}
				/>
			) : tabValue === 1 ? (
				<SharedTeams sharedTeams={sharedTeams} />
			) : null}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;
