import { useContext, useState } from 'react';
import Loading from '../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';
import { ReportData } from '../../../types/reports';
import ListItem from '../ListItem';
import SectionHeader from '../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import { Border, Center, ContentList, Scroller } from '../TeamTables';
import { UserHomeContext } from '../../../context/UserHomeProvider';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import TeamSelectionDialog from './TeamSelectionDialog';
import EmptyStateMessage from '../EmptyStateMessage';

const UserReports = () => {
	const navigate = useNavigate();
	const { loadingReports, refreshReports, reports, teams } = useContext(UserHomeContext);
	const [filteredReports, setFilteredReports] = useState<ReportData[] | undefined>(
		reports
	);
	const [teamSelectionDialogOpen, setTeamSelectionDialogOpen] = useState(false);

	const filterReports = (query: string) => {
		if (!reports) return;

		if (!query) {
			setFilteredReports(reports);
			return;
		}

		setFilteredReports(
			reports.filter(report => {
				const date = report.createdAt?.toDate().toLocaleString() ?? '';
				return (report.name || date).toLowerCase().includes(query.toLowerCase());
			})
		);
	};

	const selectReport = (report: ReportData) => {
		const organizationId = teams?.find(team => team.id === report.teamId)?.organizationId;
		navigate(`/teams/${organizationId}/${report.teamId}/reports/${report.id}`);
	};

	const onRefreshHandler = (value: string) => {
		refreshReports();
		filterReports(value);
	};

	return (
		<Border>
			<ContentList>
				<SectionHeader
					onSearch={filterReports}
					onRefresh={onRefreshHandler}
					headerComponents={
						<IconButton
							size="small"
							onClick={() => setTeamSelectionDialogOpen(!teamSelectionDialogOpen)}>
							<Add color="primary" />
						</IconButton>
					}>
					Reports
				</SectionHeader>
				<Scroller>
					{loadingReports && (
						<Center>
							<Loading />
						</Center>
					)}
					{!loadingReports && filteredReports?.length ? (
						filteredReports.map(report => (
							<ListItem
								tooltip={`Created by ${report.createdBy?.fullName}`}
								key={report.id}
								onClick={() => selectReport(report)}
								title={
									report.name
										? `${report.name} (${report.createdAt?.toDate().toLocaleString()})`
										: report.createdAt?.toDate().toLocaleString() || ''
								}
								detail={teams?.find(team => team.id === report.teamId)?.name || ''}
								id={report.id!}
							/>
						))
					) : (
						<EmptyStateMessage text={'No displayable reports'} />
					)}
				</Scroller>
			</ContentList>
			<TeamSelectionDialog
				open={teamSelectionDialogOpen}
				setOpen={setTeamSelectionDialogOpen}
			/>
		</Border>
	);
};

export default UserReports;
