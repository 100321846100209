import { db } from '../../../firebase';
import { collection, getDocs, where, query, doc, getDoc } from 'firebase/firestore';
import { ProjectData, UserWithData } from '../../../types';

// Function to fetch projects shared directly with the user
export const queryProjectsSharedWithUser = async (userId: string) => {
	const projectsRef = collection(db, 'projects');
	const q = query(projectsRef, where('sharedUsers', 'array-contains', userId));
	const snapshot = await getDocs(q);
	return snapshot.docs.map(doc => doc.data() as ProjectData);
};

//Function to fetch projects via teams
export const queryProjectsByTeams = async (userId: string) => {
	const teamsRef = collection(db, 'teams');
	const q = query(teamsRef, where('userIds', 'array-contains', userId));
	const teamsSnapshot = await getDocs(q);
	const teamIds = teamsSnapshot.docs.map(doc => doc.id);

	// fetch projects that contain any of the team IDs in their sharedTeams array
	const projectsRef = collection(db, 'projects');
	const projectsQueries = teamIds.map(teamId =>
		query(projectsRef, where('sharedTeams', 'array-contains', teamId))
	);

	const projectsSnapshots = await Promise.all(projectsQueries.map(getDocs));
	const projects = projectsSnapshots.flatMap(snapshot =>
		snapshot.docs.map(doc => doc.data() as ProjectData)
	);

	return projects;
};

export const fetchUserDocument = async (userId: string) => {
	const userRef = doc(db, 'users', userId);
	const userSnapshot = await getDoc(userRef);
	if (userSnapshot.exists()) {
		return userSnapshot.data() as UserWithData;
	} else {
		console.log('No user found');
		return null;
	}
};
