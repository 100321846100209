import { useContext, useEffect, useMemo, useState } from 'react';
import { FieldLabel } from '../../../../../../screens/ProjectInfo';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import TeamSharingDialog from './components/TeamSharingDialog';
import { ProjectData } from '../../../../../../types';
import { isEqual } from 'lodash';

type SharedTeamsProps = {
	isActive: boolean;
	partialFormState: Partial<ProjectData>;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
};

export default function SharedTeams({
	isActive,
	partialFormState,
	setPartialFormState,
}: SharedTeamsProps) {
	const { project } = useContext(ProjectContext);

	const projTeams = useMemo(() => project?.sharedTeams || [], [project?.sharedTeams]);

	const [open, setOpen] = useState(false);
	const [teamArr, setTeamArr] = useState(projTeams);

	useEffect(() => {
		if (!isEqual(projTeams, teamArr)) {
			setPartialFormState(prev => ({
				...prev,
				sharedTeams: teamArr,
			}));
		}
	}, [projTeams, setPartialFormState, teamArr]);

	useEffect(() => {
		if (isActive) setTeamArr(projTeams);
	}, [isActive, projTeams]);

	const handleClick = () => {
		if (isActive) setOpen(true);
	};

	const displayText = `${!isActive ? projTeams.length : teamArr.length} shared team(s)`;

	return (
		<>
			<div className="infoRow">
				<FieldLabel label="Organization(s)" />

				<div className="fieldInfo">
					{project?.orgs?.length ? project.orgs.map(org => org.name).join(', ') : null}
				</div>
			</div>

			<div className="infoRow">
				<FieldLabel label="Team(s)" />

				<div
					className={`fieldInfo ${isActive ? 'fieldInfo-edit' : ''}`}
					onClick={handleClick}>
					{displayText}
				</div>
			</div>

			<TeamSharingDialog
				open={open}
				setOpen={setOpen}
				teamArr={teamArr}
				setTeamArr={setTeamArr}
			/>
		</>
	);
}
