import styled from 'styled-components';
import { PhotoV2 } from '../../../components/screen-components/ProjectUtilityFormV2/utils/types';
import PanAndZoom from '../../../components/reusable-components/PanAndZoom';

export default function GalleryImg({
	photo,
	photoIndex,
	currentIndex,
}: {
	photo: PhotoV2;
	photoIndex: number;
	currentIndex: number;
}) {
	return (
		<Container>
			<PanAndZoom src={photo.url} reset={currentIndex !== photoIndex} />
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;
	background-color: #161616;
`;
