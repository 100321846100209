import { createContext } from 'react';
import {
	FormNode,
	FormNodeWithChildren,
	ItemType,
	LocalPhoto,
	NodeType,
	PhotoV2,
	QueuedPhotoV2,
	UtilityFormV2,
	UtilityFormV2Status,
} from '../utils/types';

export interface FormV2ContextProps {
	children: React.ReactNode;
}

/**
 * Types for the V2 Form Context Provider
 */
type FormV2ContextType = {
	formId: string;
	rootNodes: FormNode[];

	orderNodes: (props: {
		nodesToReorder: FormNodeWithChildren[];
		allFormNodes: FormNodeWithChildren[];
		nodeTypeFilter?: string;
	}) => Promise<FormNodeWithChildren[]>;
	addPhotosToDownloadQueue: (nodeId: string, images: LocalPhoto[]) => void;
	addPhotoToFirestore: (photo: QueuedPhotoV2) => Promise<void>;
	addToNodeStack: (nodeId: string) => void;
	popFromNodeStack: () => void;
	nodeStack: string[];
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
	isSubmitting: boolean;
	isUploading: boolean;
	utilityForm?: UtilityFormV2;
	resetForm: () => void;
	updateValue: (nodeId: string, value: any) => Promise<void>;
	removeFocusedInput: (nodeId: string) => void;
	setFocusedInput: (nodeId: string) => void;
	buildDropDownItems: (items: string[]) => { label: string; value: string }[];
	currentNode: FormNode | undefined;
	setCurrentNode: React.Dispatch<React.SetStateAction<FormNode | undefined>>;
	deleteNodePhoto: (nodeId: string, photo: PhotoV2) => void;
	updatePhotos: (nodeId: string, photos: PhotoV2[]) => void;
	photoUploadQueue: QueuedPhotoV2[];
	getItemType: (type: NodeType) => ItemType | undefined;
	deleteNodeFromFirestore: (nodeId: string) => Promise<void>;
	updateDisplayTitle: (nodeId: string, displayTitle: string) => Promise<void>;
	updateFormName: (name: string) => Promise<void>;
	submitForm: () => Promise<void>;
	submitLetterForm: () => Promise<void>;
	changeFormStatus: (status: UtilityFormV2Status, formId: string) => Promise<void>;
	setIsSubmitting: (isSubmitting: boolean) => void;
	fetchNodesAsTree: () => Promise<FormNodeWithChildren[] | undefined>;
	addNewNode: (
		displayTitle: string,
		type: NodeType,
		options?: string[],
		parentId?: string
	) => Promise<string | null | undefined>;
	copiedNodeData: FormNodeWithChildren | null;
	setCopiedNodeData: (node: FormNodeWithChildren | null) => void;
};

export const FormV2Context = createContext<FormV2ContextType>({} as FormV2ContextType);
