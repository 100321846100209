import {
	collection,
	getDocs,
	getFirestore,
	limit,
	onSnapshot,
	orderBy,
	query,
	where,
} from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';
import { AuthContext } from '../../../context/AuthProvider';
import {
	addTemplatesToTeam as addToTeam,
	removeTemplateFromTeam as removeFromTeam,
} from '../../../firebase';

type UseTemplatesProps = {
	teamId: string;
	organizationId: string;
};

const useTemplates = ({ teamId, organizationId }: UseTemplatesProps) => {
	const [templates, setTemplates] = useState<FormTemplate[] | undefined>();
	const [allTemplates, setAllTemplates] = useState<FormTemplate[] | undefined>();
	const [loading, setLoading] = useState(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (
			!user ||
			(!user.isAdmin &&
				!user.teamIds?.includes(teamId) &&
				!user.adminOrgIds?.includes(organizationId))
		) {
			setTemplates([]);
			setLoading(false);
			return;
		}

		const ref =
			user?.isAdmin || user?.teamIds?.includes(teamId)
				? query(
						collection(getFirestore(), 'utility_forms_v2_templates'),
						where('teamIds', 'array-contains', teamId),
						orderBy('name')
				  )
				: query(
						collection(getFirestore(), 'utility_forms_v2_templates'),
						where('organizationIds', 'array-contains', organizationId),
						orderBy('name')
				  );

		const unsubscribe = onSnapshot(ref, snapshot => {
			const allTemplatesList = snapshot.docs.map(
				doc => ({ ...doc.data(), id: doc.id } as FormTemplate)
			);
			const templateList = [...allTemplatesList].filter(template =>
				template.teamIds.includes(teamId)
			);

			setAllTemplates(allTemplatesList);
			setTemplates(templateList);
			setLoading(false);
		});

		return () => unsubscribe();
	}, [organizationId, teamId, user]);

	const searchTemplates = async (searchQuery: string) => {
		if (user?.isAdmin) {
			const ref = query(
				collection(getFirestore(), 'utility_forms_v2_templates'),
				where('searchableName', 'array-contains', searchQuery),
				orderBy('name'),
				limit(10)
			);

			const templatesCollection = await getDocs(ref);

			const templatesList = templatesCollection.docs.map(
				doc => ({ ...doc.data(), id: doc.id } as FormTemplate)
			);

			return templatesList;
		}

		return (
			allTemplates?.filter(template => template.searchableName.includes(searchQuery)) ||
			[]
		);
	};

	const addTemplatesToTeam = async (templateIds: string[]) => {
		try {
			await addToTeam({ templateIds, teamId });
			return true;
		} catch (error) {
			console.error(error);
		}
	};

	const removeTemplateFromTeam = async (templateId: string) => {
		try {
			await removeFromTeam({ templateId, teamId });
			return true;
		} catch (error) {
			console.error(error);
		}
	};

	return {
		templates,
		loading,
		searchTemplates,
		addTemplatesToTeam,
		removeTemplateFromTeam,
	};
};

export default useTemplates;
