import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { useState, useContext } from 'react';
import { addLeadComments } from '../../../../../../../firebase';
import { Row } from 'react-table';
import { YellowButton } from '../../../../../../../components/styled-components/styledReactTable';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { Lead } from '../../../../../types';

export default function MassComments({
	selectedRows,
	setLeads,
}: {
	selectedRows: Row<Lead>[];
	setLeads: React.Dispatch<React.SetStateAction<Lead[]>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);

	const [massCommentDialog, setMassCommentDialog] = useState(false);
	const [massComment, setMassComment] = useState('');
	const [disabled, setDisabled] = useState(false);

	const handleFocus = () => {
		setMassCommentDialog(true);
	};
	const handleBlur = () => {
		setMassCommentDialog(false);
	};

	const updateComments = async () => {
		setDisabled(true);
		setSnackbarProps({
			open: true,
			message: 'Submitting changes...',
			severity: 'warning',
			hideDuration: null,
		});

		const selectedLead = selectedRows.map(row => row.original);
		const selectedLeadIds: string[] = [];
		for (const lead of selectedLead) {
			if (lead.id) selectedLeadIds.push(lead.id);
		}

		setLeads(prev => {
			const tmp = [...prev];
			let idx = 0;
			const newDate = new Date();

			for (const id of selectedLeadIds) {
				idx = tmp.findIndex(lead => lead.id === id);
				const updatedLead: Lead = {
					...tmp[idx],
					lastComment: massComment,
					lastActivity: newDate,
				};
				tmp.splice(idx, 1, updatedLead);
			}
			return tmp;
		});
		await addLeadComments({ ids: selectedLeadIds, comment: massComment });
		setMassComment('');

		setDisabled(false);
		setSnackbarProps({
			open: true,
			message: 'Changes submitted!',
			severity: 'success',
		});
	};

	return (
		<>
			<Dialog
				open={massCommentDialog}
				onClose={() => handleBlur()}
				maxWidth="xs"
				fullWidth>
				<DialogTitle>Add Comment</DialogTitle>
				<DialogContent style={{ backgroundColor: '#f6f6f6' }}>
					<TextField
						value={massComment}
						onChange={e => setMassComment(e.target.value)}
						onKeyUp={async e => {
							if (e.code === 'Enter') {
								if (massComment) {
									updateComments();
								}
							}
						}}
						disabled={disabled}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleBlur()}>Close</Button>
				</DialogActions>
			</Dialog>
			<YellowButton onClick={handleFocus}>Mass Change Comments</YellowButton>
		</>
	);
}
