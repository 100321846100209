import { FC } from 'react';
import { MapProvider } from '../../../../../context/MapProvider';
import ProjectMap from './ProjectMap';
import AddContractorForm from './AddContractorForm';

const ProjectInfoMap: FC<{ address: string }> = ({ address }) => {
	return (
		<MapProvider>
			<ProjectMap initialAddress={address} />
			<AddContractorForm />
		</MapProvider>
	);
};

export default ProjectInfoMap;
