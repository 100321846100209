import { useState, useContext, useEffect } from 'react';
import DeliverableDropzone from '../components/DeliverableDropzone';
import { ProjectContext } from '../../../../context/ProjectProvider';
import styled from 'styled-components';
import Carousel from '../../Carousel';
import DeliverableButtonsOverlay from '../components/DeliverableButtonsOverlay';
import { CarouselPdf, CarouselPdfPlaceholder } from '../../Carousel/Styled';
import { useWindowSize } from '../../../../screens/Welcome';

export default function PdfBox({
	downloadOnly,
	anonUpload,
}: {
	downloadOnly: boolean;
	anonUpload: boolean;
}) {
	const { project } = useContext(ProjectContext);

	const [validPdfLinks, setValidPdfLinks] = useState<{ link: string; type: string }[]>(
		[]
	);
	const [showPdfFilesDialog, setShowPdfFilesDialog] = useState(false);
	const { width } = useWindowSize();

	const locked = downloadOnly && project?.projectStatus === 'Pending Payment';

	useEffect(() => {
		function getValidPdfLinks() {
			if (project?.pdf?.length && width >= 1024) {
				const pdfArr: { link: string; type: 'pdf' | 'doc' }[] = [];
				for (const file of project?.pdf) {
					pdfArr.push({
						link: file.downloadUrl,
						type: file.filepath.endsWith('pdf') ? 'pdf' : 'doc',
					});
				}
				setValidPdfLinks(pdfArr);
			} else {
				setValidPdfLinks([]);
			}
		}

		getValidPdfLinks();
	}, [project?.pdf, width]);

	return downloadOnly && !validPdfLinks.length && !project?.pdf?.length ? null : (
		<DeliverableDropzone
			deliverableKey="pdf"
			showingDialog={showPdfFilesDialog}
			setShowingDialog={setShowPdfFilesDialog}
			downloadOnly={downloadOnly}
			anonUpload={anonUpload}>
			{validPdfLinks.length && !locked ? (
				<CadContainer id="deliverable-pdf">
					<Carousel lazy>
						{validPdfLinks.map(({ link, type }, i: number) => {
							if (type === 'pdf') {
								return (
									<CarouselPdf data={link} type="application/pdf" key={i}>
										<CarouselPdfPlaceholder>
											<span>Could not load PDF Viewer:</span>
											<a href={link}>Click to view PDF</a>
										</CarouselPdfPlaceholder>
									</CarouselPdf>
								);
							} else {
								return (
									<iframe
										title={link}
										key={i}
										src={`https://docs.google.com/gview?url=${encodeURIComponent(
											link
										)}&embedded=true`}
										style={{ width: '100%', height: '100%' }}>
										<span>Could not load Word Document Viewer:</span>
										<a href={link}>Click to download Word Document</a>
									</iframe>
								);
							}
						})}
					</Carousel>
					<DeliverableButtonsOverlay
						deliverableKey="pdf"
						downloadOnly={downloadOnly}
						setShowingDialog={setShowPdfFilesDialog}
					/>
				</CadContainer>
			) : null}
		</DeliverableDropzone>
	);
}

const CadContainer = styled.div`
	width: 100%;
	height: 100%;
`;
