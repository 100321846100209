import { Button, Checkbox, FormControlLabel, ListItem, styled } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	EmptyContent,
	IconContainer,
	IconsContainer,
	ListItemContainer,
} from './PhotoUploadManager.styles';
import useDownloadPhotos from './useDownloadPhotos';
import {
	FormNode,
	PhotoV2,
} from '../../../screen-components/ProjectUtilityFormV2/utils/types';
import { FormV2Context } from '../../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { Dialog } from '../../Dialog';
import Loading from '../../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';
import theme from '../../../../styles/theme';

interface PhotoUploadManagerProps {
	open: boolean;
	onClose: () => void;
	itemNode: FormNode;
	uploadedPhotos: PhotoV2[];
}

const PhotoUploadManager: React.FC<React.PropsWithChildren<PhotoUploadManagerProps>> = ({
	open,
	onClose,
	itemNode,
	uploadedPhotos,
}) => {
	const { photoUploadQueue, deleteNodePhoto } = useContext(FormV2Context);
	const { downloadPhotos, downloadZip } = useDownloadPhotos();

	const [combinedPhotos, setCombinedPhotos] = useState<PhotoV2[]>([]);
	const [downloadMessage, setDownloadMessage] = useState<string>('');
	const [selectedPhotos, setSelectedPhotos] = useState<PhotoV2[]>([]);

	useEffect(() => {
		setCombinedPhotos([
			...uploadedPhotos,
			...(!!photoUploadQueue
				? photoUploadQueue?.filter(p => p?.nodeId === itemNode.id).map(p => p.photo)
				: []),
		]);
	}, [itemNode.id, photoUploadQueue, uploadedPhotos]);

	const handleDownloadAll = async () => {
		await downloadPhotos(combinedPhotos, itemNode, setDownloadMessage);
		setDownloadMessage('');
		handleClose();
	};

	const handleZip = async () => {
		await downloadZip(combinedPhotos, itemNode, setDownloadMessage);
		setDownloadMessage('');
		handleClose();
	};

	const handleDelete = async () => {
		selectedPhotos.forEach(async photo => {
			await deleteNodePhoto(itemNode.id, photo);
		});
		await setSelectedPhotos([]);
		onClose();
	};

	const handleCheckbox = (photo: PhotoV2) => {
		if (selectedPhotos.includes(photo))
			setSelectedPhotos(selectedPhotos.filter(p => p.name !== photo.name));
		else setSelectedPhotos([...selectedPhotos, photo]);
	};

	const handleSelectAll = () => {
		if (selectedPhotos.length === combinedPhotos.length) setSelectedPhotos([]);
		else setSelectedPhotos(combinedPhotos);
	};

	const handleClose = () => {
		setSelectedPhotos([]);
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			title="Manage Photos"
			bottomActions={
				<>
					<span style={{ padding: 8 }}>{downloadMessage.split('...')[1]}</span>
					{downloadMessage === '' && (
						<>
							{!!selectedPhotos.length && (
								<Button
									color="primary"
									onClick={handleDelete}
									sx={{ textTransform: 'none' }}>
									Delete Selected Photos ({selectedPhotos.length})
								</Button>
							)}

							<Button color="primary" onClick={handleZip} sx={{ textTransform: 'none' }}>
								Download Zip
							</Button>
							<Button
								color="primary"
								onClick={handleDownloadAll}
								sx={{ textTransform: 'none' }}>
								Download All Files
							</Button>
						</>
					)}
				</>
			}>
			<StyledFormControlLabel
				onChange={handleSelectAll}
				control={
					<StyledCheckbox checked={selectedPhotos.length === combinedPhotos.length} />
				}
				label={`${
					selectedPhotos.length === combinedPhotos.length ? 'Unselect' : 'Select'
				} all
				photos`}
			/>
			{combinedPhotos.length > 0 ? (
				combinedPhotos.map((photo, index) => (
					<ListItemContainer key={index}>
						<IconContainer>
							<StyledCheckbox
								onClick={() => handleCheckbox(photo)}
								inputProps={{ 'aria-label': 'controlled' }}
								checked={selectedPhotos.includes(photo)}
							/>
						</IconContainer>
						<ListItem>{photo.originalName}</ListItem>
						<IconsContainer>
							{!photo.url && (
								<IconContainer>
									<Loading size={36} />
								</IconContainer>
							)}
						</IconsContainer>
					</ListItemContainer>
				))
			) : (
				<EmptyContent>No photos uploaded or queued.</EmptyContent>
			)}
		</Dialog>
	);
};

const StyledCheckbox = styled(Checkbox)`
	color: ${theme.palette.primary.main};

	&.Mui-checked {
		color: ${theme.palette.primary.main};
	}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
	align-self: flex-start;
	margin-left: -5px;
	gap: 30px;
	.MuiTypography-root {
		font-size: 0.875rem;
	}
`;

export default PhotoUploadManager;
