import { Box, Dialog, Grid, Typography } from '@mui/material';
import { CropOriginal } from '@mui/icons-material';
import { CoverImageDialogProps } from './CoverImage.types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PhotoUploader } from '../../screen-components/ProjectUtilityFormV2/components/FormContainer/components/FormFill/FormFillV2.styles';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { ProjectContext } from '../../../context/ProjectProvider';
import {
	checkExistingFiles,
	deleteClientLogo,
	deleteCoverImage,
	uploadClientLogo,
	uploadCoverImages,
} from '../../../utils/uploadCoverImages';
import {
	getFileExtension,
	getFileName,
} from '../../screen-components/ProjectUtilityFormV2/utils/utils';
import {
	StyledButtonContained,
	StyledButtonError,
	StyledButtonText,
	StyledCircularProgress,
	StyledDialogActions,
	StyledDialogContent,
	StyledDialogTitle,
	StyledTextField,
} from './CoverImageStyles';
import theme from '../../../styles/theme';

const CoverImageDialog: React.FC<CoverImageDialogProps> = ({
	openDialog,
	image,
	hasLogo = false,
	handleClose,
}) => {
	const { formId }: any = useParams();
	const { project } = useContext(ProjectContext);
	const projectId = project?.id ?? '';
	const dialogTitle = hasLogo ? 'Add company logo' : 'Add cover image';
	const filepath = `utility_forms_v2/${formId}/cover${hasLogo ? '/client_logo' : ''}`;
	const [extension, setExtension] = useState<string>('');
	const [file, setFile] = useState<any>([]);
	const [imageTitle, setImageTitle] = useState<string>('');
	const [imagePreview, setImagePreview] = useState<string>('');
	const [uploadProgress, setUploadProgress] = useState<number | null>(null);
	const [uploadError, setUploadError] = useState<boolean>(false);
	const [disableButtons, setDisableButtons] = useState<{
		upload: boolean;
		all: boolean;
	}>({
		upload: !hasLogo,
		all: false,
	});
	const handleImageTitle = (title: string) => {
		setImageTitle(title);
		setDisableButtons({
			upload: !!title.length,
			all: false,
		});
		setUploadError(false);
	};

	const { getRootProps, getInputProps, open } = useDropzone({
		accept: ['image/png', 'image/jpeg'],
		minSize: 0,
		maxFiles: 1,
		multiple: false,
		onDrop: acceptedFiles => {
			setDisableButtons({
				upload: true,
				all: false,
			});
			setExtension(getFileExtension(acceptedFiles[0].name));
			setFile(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
			setImagePreview(URL.createObjectURL(acceptedFiles[0]));
		},
	});

	const handleUploadImage = async () => {
		const uploadData = {
			oldURL: image?.downloadURL ?? '',
			name: hasLogo
				? `${project?.name.length ? project.name + '-logo' : 'companyLogo'}${extension}`
				: `${imageTitle}${extension}`,
			formId,
			projectId,
			storageFilepath: filepath,
			onProgress: (progress: number) => {
				setUploadProgress(progress);
			},
			newFile: file[0],
		};
		setDisableButtons({
			upload: true,
			all: true,
		});
		setUploadProgress(0);
		if (hasLogo) {
			await uploadClientLogo(uploadData).finally(() => {
				handleCancel();
			});
		} else {
			const error = await checkExistingFiles({
				name: `${imageTitle}${extension}`,
				formId,
			}).then(res => {
				return res;
			});
			if (error && !file.length) {
				setUploadError(true);
				setDisableButtons({
					upload: false,
					all: false,
				});
				return;
			}
			await uploadCoverImages(uploadData).finally(() => {
				handleCancel();
			});
		}
	};

	const handleDeleteImage = async () => {
		const deleteData = { downloadURL: image?.downloadURL ?? '', formId };
		setDisableButtons({
			upload: true,
			all: true,
		});
		hasLogo
			? await deleteClientLogo(deleteData).finally(() => {
					handleCancel();
			  })
			: await deleteCoverImage(deleteData).finally(() => {
					handleCancel();
			  });
	};

	const handleCancel = useCallback(() => {
		setFile([]);
		setImageTitle('');
		setImagePreview('');
		setUploadProgress(null);
		setDisableButtons({
			upload: true,
			all: false,
		});
		setUploadError(false);
		handleClose();
	}, [handleClose]);

	useEffect(() => {
		if (uploadProgress === 100) {
			handleCancel();
		}
	}, [handleCancel, uploadProgress]);

	useEffect(() => {
		if (image && image.downloadURL.length && openDialog) {
			setImagePreview(image.downloadURL);
			setImageTitle(getFileName(image.name));
		}
	}, [image, openDialog]);

	useEffect(() => {
		if (hasLogo) {
			setDisableButtons({
				upload: !(!!file.length || !hasLogo),
				all: false,
			});
		} else {
			setDisableButtons({
				upload: !(
					(!!file.length || imageTitle !== getFileName(image?.name ?? '')) &&
					!!imageTitle.length
				),
				all: false,
			});
		}
	}, [file, imageTitle, image?.name, hasLogo]);

	return (
		<Dialog
			fullScreen={false}
			open={openDialog}
			onClose={() =>
				(uploadProgress === null || uploadProgress === 100) &&
				!disableButtons.all &&
				handleCancel()
			}
			aria-labelledby="responsive-dialog-title"
			maxWidth="md"
			PaperProps={{
				sx: {
					maxHeight: 'unset',
				},
			}}>
			<StyledDialogTitle id="responsive-dialog-title" variant="h4">
				{dialogTitle}
			</StyledDialogTitle>
			<StyledDialogContent
				sx={{ p: '32px', backgroundColor: theme.palette.common.black }}>
				<PhotoUploader {...getRootProps()} hidden={!!imagePreview.length}>
					<input {...getInputProps()} />
					<CropOriginal color="primary" fontSize="small" />
					<Box display={'flex'} gap={0.5}>
						<Typography variant="body2" fontWeight={700} color="newText.primary">
							Drop image here or
						</Typography>
						<Typography
							variant="body2"
							fontWeight={700}
							color="primary.main"
							sx={{ textDecoration: 'underline' }}>
							click to upload
						</Typography>
					</Box>
				</PhotoUploader>
				{!!imagePreview.length && (
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<img
								src={imagePreview}
								alt={imageTitle}
								style={{ width: '100%', borderRadius: '6px' }}
							/>
						</Grid>
						<Grid item xs={6}>
							{!hasLogo && (
								<StyledTextField
									label="Image title"
									value={imageTitle}
									placeholder="Enter image title"
									InputLabelProps={{ shrink: true }}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										handleImageTitle(event.target.value);
									}}
									error={uploadError}
									helperText={
										uploadError ? 'An image with this name already exists.' : ''
									}
								/>
							)}
							<StyledButtonContained
								colortype="dark"
								onClick={open}
								variant="outlined"
								disabled={disableButtons.all}>
								Replace Image
							</StyledButtonContained>
						</Grid>
					</Grid>
				)}
			</StyledDialogContent>
			<StyledDialogActions>
				<StyledButtonText onClick={handleCancel} disabled={disableButtons.all}>
					Cancel
				</StyledButtonText>
				<Box sx={{ position: 'relative' }}>
					<StyledButtonContained
						onClick={handleUploadImage}
						variant="contained"
						size="medium"
						disabled={disableButtons.upload}
						colortype="primary">
						Save and upload
					</StyledButtonContained>
					{uploadProgress !== null && disableButtons.upload && (
						<StyledCircularProgress
							size={24}
							variant="indeterminate"
							value={uploadProgress}
						/>
					)}
				</Box>
				{!!image?.name.length && (
					<Box sx={{ position: 'relative' }}>
						<StyledButtonError
							onClick={handleDeleteImage}
							variant="contained"
							color="error"
							size="medium"
							disabled={disableButtons.all}>
							Delete
						</StyledButtonError>
						{disableButtons.all && uploadProgress === null && (
							<StyledCircularProgress size={24} variant="indeterminate" />
						)}
					</Box>
				)}
			</StyledDialogActions>
		</Dialog>
	);
};

export default CoverImageDialog;
