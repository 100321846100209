import { useContext } from 'react';
import { AuthContext } from '../../../../../../../context/AuthProvider';
import {
	StatusWrapper,
	statusColor,
} from '../../../../../../styled-components/styledReactTable';
import { MenuItem, Select } from '@mui/material';
import { ProjectData, ProjectStatus } from '../../../../../../../types';
import {
	confirmProjectStatusChange,
	updateSingleProjectField,
} from '../../../../../../../firebase/firestore/projects';
import { projectStatusText, projectStatuses } from '../../../../../../../utils';
import styled from 'styled-components';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import {
	checkPermissions,
	copyToClipboard,
	getAnonDownloadLink,
} from '../../../../../../../utils';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { Row } from 'react-table';
// const lockedStatuses: ProjectStatus[] = [
// 	'Proposal Pending',
// 	'Proposal Sent',
// 	'Archive',
// 	'Sample',
// ];

export default function StatusCell({
	row,
	projectData,
	rowEditing,
	setRowEditing,
}: {
	row: Row<ProjectData>;
	projectData: ProjectData;
	rowEditing: boolean;
	setRowEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { user } = useContext(AuthContext);
	const { projectStatusCount, setProjectStatusCount } = useContext(ProjectContext);
	const { setStatusProjects } = useContext(StatusTableContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const shareableLink = getAnonDownloadLink(row.original);
	const projectName = row.original.address || row.original.name || 'Unnamed Project';

	const handleChange = (projectData: ProjectData, newStatus: ProjectStatus) => {
		if (confirmProjectStatusChange(newStatus)) {
			updateSingleProjectField(
				projectData.id as string,
				newStatus,
				'projectStatus'
			).catch(e => console.error(e));
			setStatusProjects(prev => {
				// Update status count.
				const oldStatus = projectData.projectStatus;
				setProjectStatusCount({
					...projectStatusCount,
					[newStatus]: (projectStatusCount[newStatus] || 0) + 1,
					[oldStatus]: (projectStatusCount[oldStatus] || 0) - 1,
				});

				// Remove project from state after status changes
				return prev.filter(proj => proj.id !== projectData.id);
			});
		}
	};

	return user?.isAdmin ? (
		<>
			<StatusWrapper
				$projectStatus={projectData.projectStatus}
				style={{ position: 'relative', zIndex: '1' }}>
				<StyledSelect
					onChange={e => {
						e.preventDefault();
						e.stopPropagation();
						handleChange(projectData, e.target.value as ProjectStatus);
					}}
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: '30px',
							},
						},
					}}
					value={projectData.projectStatus}
					displayEmpty
					onClose={e => e.stopPropagation()}>
					{projectStatuses.map(status => (
						<MenuItem value={status} key={status}>
							{projectStatusText(status)}
						</MenuItem>
					))}
				</StyledSelect>
			</StatusWrapper>
			<div style={{ display: 'flex' }}>
				<ContentCopyIcon
					onClick={e => {
						e.stopPropagation();
						copyToClipboard(shareableLink);
						setSnackbarProps({
							open: true,
							message: `${projectName} public shareable link copied!`,
							severity: 'success',
						});
					}}
					className="CopyLinkButton CopyLinkButton-black"
					titleAccess="Copy public shareable link"
					tabIndex={0}
				/>
				{checkPermissions(user, 'admin') ? (
					!rowEditing ? (
						<EditIcon
							onClick={e => [e.stopPropagation(), setRowEditing(true)]}
							className="CopyLinkButton CopyLinkButton-black"
							titleAccess={`Edit ${projectName}`}
							tabIndex={0}
						/>
					) : (
						<CancelIcon
							onClick={e => [e.stopPropagation(), setRowEditing(false)]}
							className="CopyLinkButton CopyLinkButton-black"
							tabIndex={0}
						/>
					)
				) : null}
			</div>
		</>
	) : (
		<NonAdminStatusChip $projectStatus={projectData.projectStatus}>
			{projectData.projectStatus}
		</NonAdminStatusChip>
	);
}

const NonAdminStatusChip = styled.div<{ $projectStatus: ProjectStatus }>`
	color: black;
	background-color: ${({ $projectStatus }) => statusColor($projectStatus)};
	border-radius: 5px;
	padding: 5px;
`;

const StyledSelect = styled(Select)`
	border-radius: 30px;
`;
