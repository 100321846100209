import { useContext, useEffect, useMemo, useState } from 'react';
import { ProjectData } from '../../../../../types';
import ClickableSpan from '../../../../reusable-components/ClickableSpan';
import MultiDateTimeInput from '../../../../reusable-components/field-components/MultiDateTimeInput';
import { isEqual } from 'lodash';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ProjectContext } from '../../../../../context/ProjectProvider';

dayjs.extend(utc);
dayjs.extend(timezone);

interface MultiTimestampFieldProps {
	field: 'captureTimestamps';
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

export default function MultiTimestampField({
	field,
	isActive,
	setPartialFormState,
}: MultiTimestampFieldProps) {
	const { project } = useContext(ProjectContext);

	const [showDialog, setShowDialog] = useState(false);

	const projDates = useMemo(() => project?.[field] || [], [field, project]);
	const [dateArr, setDateArr] = useState(projDates);

	useEffect(() => {
		if (!isEqual(projDates, dateArr)) {
			const updatedDates = dateArr.length
				? dateArr.map(date => dayjs(date).hour(12).minute(0).second(0).toDate())
				: undefined;
			setPartialFormState(prev => ({
				...prev,
				[field]: updatedDates,
			}));
		}
	}, [dateArr, field, projDates, setPartialFormState]);

	useEffect(() => {
		if (isActive) setDateArr(projDates);
	}, [isActive, projDates]);

	const displayStr =
		projDates.map(ts => dayjs(ts).tz(dayjs.tz.guess()).format('MM/DD/YYYY')).join(', ') ||
		'';
	const editingStr =
		dateArr.map(ts => dayjs(ts).tz(dayjs.tz.guess()).format('MM/DD/YYYY')).join(', ') ||
		'N/A';

	const handleClick = () => {
		setShowDialog(true);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<div className="infoRow">
			<FieldLabel label={fieldTitles[field]} />

			{!isActive ? (
				<span>{displayStr}</span>
			) : (
				<>
					<ClickableSpan onClick={handleClick}>{editingStr}</ClickableSpan>

					<Dialog open={showDialog} onClose={handleClose}>
						<DialogTitle>{`Editing ${fieldTitles[field]}`}</DialogTitle>

						<DialogContent>
							<MultiDateTimeInput dates={dateArr} setDates={setDateArr} />
						</DialogContent>

						<DialogActions>
							<Button onClick={handleClose}>Close</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</div>
	);
}

const fieldTitles = {
	captureTimestamps: 'Site Visit Date(s)',
};
