import { ArrowBack } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { YellowButton } from '../styled-components/styledReactTable';

export const BackButton: React.FC<{
	url?: any;
	buttonText?: string;
	noMargin?: boolean;
}> = ({ url = '/', buttonText = 'Return Home', noMargin }) => {
	return (
		<StyledBox nomargin={noMargin ? 1 : 0}>
			<StyledLink to={url}>
				<YellowButton
					variant="contained"
					sx={{ textTransform: 'none', mb: '24px', padding: '9px 16px', lineHeight: '1' }}
					startIcon={<ArrowBack sx={{ mt: '-2px' }} />}>
					{buttonText}
				</YellowButton>
			</StyledLink>
		</StyledBox>
	);
};

const StyledBox = styled(Box)<{ nomargin?: number }>`
	display: flex;
	margin-top: ${({ nomargin }) => (nomargin ? '0' : '32px')};
	margin-left: ${({ nomargin }) => (nomargin ? '0' : '24px')};
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	color: inherit;
	align-self: flex-start;
`;
