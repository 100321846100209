import styled from 'styled-components';
import { StatusGroupColor, StatusGroupName, statusGroups } from '../utils';
import { useContext } from 'react';
import { GroupedStatusTableContext } from '../GroupedStatusTableProvider';
import { Tab, Tabs } from '@mui/material';
import { LoadingContext } from '../../../../context/LoadingProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';

export default function GroupedStatuses() {
	const { loadingProjectStatuses } = useContext(LoadingContext);
	const { selectedStatusGroup, setSelectedStatusGroup } = useContext(ProjectContext);
	const { statusGroupCounts } = useContext(GroupedStatusTableContext);

	const handleChange = (event: React.SyntheticEvent, newValue: StatusGroupName) => {
		setSelectedStatusGroup(newValue);
	};

	return (
		<Container>
			<Tabs
				value={selectedStatusGroup}
				onChange={handleChange}
				variant="fullWidth"
				indicatorColor="primary"
				textColor="primary"
				TabIndicatorProps={{
					style: {
						backgroundColor: StatusGroupColor[selectedStatusGroup],
					},
				}}>
				{Object.keys(statusGroups).map(groupName => {
					const group = groupName as StatusGroupName;
					const count = statusGroupCounts?.[group];
					const tabLabel =
						typeof count !== 'number' || loadingProjectStatuses
							? group
							: `${group} (${count})`;

					return (
						<Tab
							key={group}
							label={tabLabel}
							value={group}
							sx={{
								color: 'white',
								textTransform: 'none',
								minWidth: '180px',
								'&.Mui-selected': {
									color: StatusGroupColor[group],
								},
							}}
						/>
					);
				})}
			</Tabs>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	gap: 20px;
`;
