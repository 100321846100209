import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { YellowIconButton } from '../../../../components/styled-components/buttons';
import MapIcon from './MapIcon.svg';
import { ProjectContext } from '../../../../context/ProjectProvider';

export default function MapButton({
	address,
	newTab = false,
}: {
	address?: string;
	newTab?: boolean;
}) {
	const { setProjectsSelected } = useContext(ProjectContext);

	const linkProps = {
		to: address ? `/map?address=${encodeURIComponent(address)}` : '/map',
		...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}),
	};

	return (
		<Link {...linkProps}>
			<YellowIconButton
				title="Navigate to map screen"
				sx={{ padding: '8px' }}
				onClick={() => {
					setProjectsSelected([]);
				}}>
				<img src={MapIcon} alt="" width={35} />
			</YellowIconButton>
		</Link>
	);
}
