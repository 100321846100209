import { TextField, styled } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { InputFieldProps } from './MultilineField.types';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { AuthContext } from '../../../context/AuthProvider';
import { TemplateContext } from '../../../context/TemplateProvider';

const debouncedSave = debounce(async (save: () => void) => save(), 200);

const MultilineField: React.FC<React.PropsWithChildren<InputFieldProps>> = ({
	node,
	size = 'medium',
}) => {
	const nodeValue = node.value as string;
	const { firebaseAuthData } = useContext(AuthContext);
	const [isFocused, setIsFocused] = useState(false);
	const [value, setValue] = useState<string>(nodeValue || '');
	const { updateValue } = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);

	const onFocus = () => {
		setIsFocused(true);
	};

	const onBlur = () => {
		setIsFocused(false);
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setValue(event.target.value);
	};

	const debounceSave = useCallback(
		(value: string) => {
			debouncedSave.cancel();
			debouncedSave(() => updateValue(node.id, value));
		},
		[node.id, updateValue]
	);

	useEffect(() => {
		if (!isFocused && node.value) setValue(node.value as string);
	}, [node.value, isFocused]);

	useEffect(() => {
		if (isFocused) debounceSave(value);
	}, [value, isFocused, debounceSave]);

	const editable = !node.focusedBy || node.focusedBy.id === firebaseAuthData?.uid;

	return (
		<>
			<StyledTextField
				placeholder="Enter text"
				value={value}
				onChange={handleInputChange}
				onBlur={onBlur}
				onFocus={onFocus}
				disabled={!editable || isTemplate}
				variant="outlined"
				size={size}
				multiline
				fullWidth
				minRows={5}
				id={node.id}
			/>
			{node.focusedBy && node.focusedBy.id !== firebaseAuthData?.uid ? (
				<span>{node.focusedBy.name} is typing...</span>
			) : (
				<span />
			)}
		</>
	);
};

const StyledTextField = styled(TextField)`
	textarea {
		color: #fbfbfb;

		&::placeholder {
			line-height: 1.6;
		}
	}

	fieldset {
		border-color: ${({ theme }) => theme.palette.secondary.light};
	}

	label {
		color: ${({ theme }) => theme.palette.grey[300]};
		line-height: 1.6;
	}

	&:hover {
		> :not(.Mui-focused) {
			fieldset {
				border-color: ${({ theme }) => theme.palette.common.white};
			}
		}
	}
	.MuiInputBase-root {
		&.Mui-disabled {
			border: 1px solid rgba(118, 118, 118, 0.3);
			border-radius: 5px;
		}
	}
`;

export default MultilineField;
