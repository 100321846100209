import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';
import { StyledDialogContent } from '../../../../../../styled-components/dialog';
import { useContext, useState } from 'react';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import MultiAutoCompleteInput from '../../../../../MultiAutoCompleteInput';
import ClickableSpan from '../../../../../ClickableSpan';
import Spinner from '../../../../../Spinner';

export default function MultiStringCell({
	row,
	rowEditing,
	field,
	title,
	options = [],
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
	field: 'sku' | 'equipment' | 'accountsPayable' | 'modelers' | 'sku' | 'designSoftware';
	title: string;
	options?: string[];
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);
	const { cachedModelers, cachedDesignSoftware, loadingAutofillData } =
		useContext(ProjectContext);

	let inputOptions = [];
	const projectId = row.original.id as string;
	const displayValue = row.original[field]?.length
		? row.original[field]?.join(', ')
		: '-';

	const [editing, setEditing] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [value, setValue] = useState<string[]>(row.original[field] ?? []);

	switch (field) {
		case 'modelers':
			inputOptions = cachedModelers;
			break;
		case 'designSoftware':
			inputOptions = cachedDesignSoftware;
			break;
		default:
			inputOptions = options;
			break;
	}

	const handleOpen = () => {
		setValue(row.original[field] ?? []);
		setEditing(true);
	};

	const handleClose = () => {
		setEditing(false);
	};

	const handleSubmit = async () => {
		setDisabled(true);
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Saving changes...',
			hideDuration: null,
		});

		await updateSingleProjectField(projectId, value, field);
		setStatusProjects(prev => {
			if (prev) {
				return prev.map(proj => {
					if (proj.id === projectId) {
						return {
							...proj,
							[field]: value,
						};
					} else return proj;
				});
			} else return prev;
		});

		setDisabled(false);
		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});
	};

	return !rowEditing ? (
		<span>{displayValue}</span>
	) : (
		<>
			<ClickableSpan onClick={handleOpen}>{displayValue}</ClickableSpan>

			<Dialog
				open={editing}
				onClose={handleClose}
				onClick={event => event.stopPropagation()}>
				<DialogTitle>{`Editing ${title}`}</DialogTitle>

				<StyledDialogContent>
					<MultiAutoCompleteInput
						value={value}
						onChange={(e, newValue) => setValue([...newValue])}
						onBlur={inputValue => setValue([...value, inputValue])}
						options={inputOptions}
						disabled={disabled}
						loading={loadingAutofillData}
						loadingText="Loading options..."
					/>
				</StyledDialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
					{!disabled ? (
						<Button onClick={handleSubmit}>Submit</Button>
					) : (
						<Spinner size={50} />
					)}
				</DialogActions>
			</Dialog>
		</>
	);
}
