import { useContext, useState } from 'react';
import ListItem from '../ListItem';
import { FormTemplate } from '../../../components/screen-components/ProjectUtilityFormV2/utils/types';
import Loading from '../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';
import SectionHeader from '../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import { Border, Center, ContentList, Scroller } from '../TeamTables';
import { UserHomeContext } from '../../../context/UserHomeProvider';
import { useNavigate } from 'react-router-dom';
import EmptyStateMessage from '../EmptyStateMessage';

const UserTemplates = () => {
	const navigate = useNavigate();

	const { loadingTemplates, refreshTemplates, teams, templates } =
		useContext(UserHomeContext);

	const [filteredTemplates, setFilteredTemplates] = useState<FormTemplate[] | undefined>(
		templates
	);

	const filterTemplates = (query: string) => {
		if (!templates) return;

		if (!query) {
			setFilteredTemplates(templates);
			return;
		}

		setFilteredTemplates(
			templates.filter(template =>
				(template.name || '').toLowerCase().includes(query.toLowerCase())
			)
		);
	};
	const selectTemplate = (template: FormTemplate) =>
		navigate(`/templates/${template.id}`);

	const onRefreshHandler = (value: string) => {
		refreshTemplates();
		filterTemplates(value);
	};

	return (
		<Border>
			<ContentList>
				<SectionHeader onSearch={filterTemplates} onRefresh={onRefreshHandler}>
					Templates
				</SectionHeader>
				<Scroller>
					{loadingTemplates && (
						<Center>
							<Loading />
						</Center>
					)}
					{!loadingTemplates && filteredTemplates?.length ? (
						filteredTemplates.map(template => (
							<ListItem
								tooltip={`Created by ${template.createdBy.name} at ${template.createdAt
									?.toDate()
									.toLocaleString()}\nUpdated by ${
									template.updatedBy.name
								} at ${template.updatedAt?.toDate().toLocaleString()}`}
								key={template.id}
								onClick={() => selectTemplate(template)}
								title={template.name}
								detail={
									teams
										?.filter(team => template.teamIds.includes(team.id))
										.map(team => team.name)
										.join(', ') || ''
								}
								id={template.id!}
							/>
						))
					) : (
						<EmptyStateMessage text={'No displayable templates'} />
					)}
				</Scroller>
			</ContentList>
		</Border>
	);
};

export default UserTemplates;
