import { useContext, useState } from 'react';
import { CachedScopeSection } from '../../../../../../types/firestore';
import {
	ScopeContainer,
	ScopeContent,
	ScopeTitle,
	ScopeTitleContainer,
} from '../styledComponents';
import styled from 'styled-components';
import { setDoc, updateDoc } from 'firebase/firestore';
import { ProjectCategory } from '../../../utils';
import { ProjectScopeContext } from '../context/ProjectScopeProvider';
import ScopeInput from './ScopeInput';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import theme from '../../../../../../styles/theme';

type SectionProps = {
	category: ProjectCategory;
	cachedSection: CachedScopeSection;
	defaultOpen?: boolean;
	multiTitle?: string;
	showCheckboxes: boolean;
	allowEdits?: boolean;
	isInnerSection?: boolean;
};

export default function Section({
	category,
	cachedSection,
	defaultOpen = false,
	multiTitle,
	showCheckboxes,
	allowEdits,
	isInnerSection = false,
}: SectionProps) {
	const { projectScopeDocRef, projectScopeData, projectScopeDoc } =
		useContext(ProjectScopeContext);

	const [showContent, setShowContent] = useState(defaultOpen);

	// Cached section data.
	const cachedSectionTitle = cachedSection.title;

	// Project section data.
	const projectSections = projectScopeData?.[category] || {};
	const projectSection =
		cachedSectionTitle in projectSections ? projectSections[cachedSectionTitle] : [];

	// Handles click event on section title
	const handleTitleClick = () => {
		setShowContent(prev => !prev);
	};

	// Handle checkbox change event
	const handleClick = async (scopeName: string, newValue: boolean) => {
		if (projectScopeDocRef) {
			const updatedScope = { scopeName, value: newValue };
			const tmpArr = [...projectSection];
			const idx = tmpArr.findIndex(scope => scope.scopeName === updatedScope.scopeName);

			if (projectScopeDoc.current) {
				if (idx !== -1) {
					tmpArr.splice(idx, 1, updatedScope);
					await updateDoc(projectScopeDocRef, {
						[`${category}.${cachedSectionTitle}`]: tmpArr,
					});
				} else {
					await updateDoc(projectScopeDocRef, {
						[`${category}.${cachedSectionTitle}`]: [...tmpArr, updatedScope],
					});
				}
			} else {
				await setDoc(projectScopeDocRef, {
					[category]: {
						[cachedSectionTitle]: [...tmpArr, updatedScope],
					},
				});
			}
		}
	};

	return (
		<ScopeContainer>
			<ScopeTitleContainer>
				<ScopeTitle onClick={handleTitleClick} $isInnerSection={isInnerSection}>
					{cachedSectionTitle}
				</ScopeTitle>
				<ArrowContainer $isInnerSection={isInnerSection}>
					{showContent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</ArrowContainer>
			</ScopeTitleContainer>

			{showContent && showCheckboxes ? (
				<SectionContent>
					{cachedSection.scopes.map((cachedScope, idx) => {
						const checked = Boolean(
							projectSection.find(scope => scope.scopeName === cachedScope)?.value
						);
						return (
							<ScopeRow key={idx}>
								<ScopeInput checked={checked} text={cachedScope} onChange={handleClick} />
							</ScopeRow>
						);
					})}
				</SectionContent>
			) : null}
		</ScopeContainer>
	);
}

const SectionContent = styled(ScopeContent)`
	align-items: baseline;
	padding: 32px;
`;

const ScopeRow = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
	overflow: hidden;

	> span {
		line-height: normal;
	}
`;

export const ArrowContainer = styled.div<{ $isInnerSection: boolean }>`
	display: flex;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	color: ${({ $isInnerSection }) =>
		$isInnerSection ? theme.palette.primary.main : theme.palette.secondary.main};
`;
