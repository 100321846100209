import { IconButton, InputAdornment, Menu } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SnackContext } from '../../../../../context/SnackProvider';
import { AuthContext } from '../../../../../context/AuthProvider';
import { Timestamp, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, getSharedTeamMembers, getSharedUsers } from '../../../../../firebase';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	CommentBubble,
	CommentDetails,
	DeleteIconButton,
	InputContainer,
	MessageContainer,
	SubmitCommentIcon,
	UserMenuItem,
	UsersEmail,
	UsersName,
} from './CommentsDialog.styles';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { sendEmail } from '../../../../../firebase/auth';
import fetchStaticUsers from './utils/FetchStaticUsers';
import {
	CommentType,
	FormNode,
} from '../../../../screen-components/ProjectUtilityFormV2/utils/types';
import { SharedUser } from '../../../../screen-components/ProjectInfo/components/RightNavButtons/types';
import { Dialog } from '../../../Dialog';
import { ConfirmDialog } from '../../../dialogs';
import { StyledTextField } from '../../../StyledTextField';
import theme from '../../../../../styles/theme';

interface CommentsDialogProps {
	open: boolean;
	node: FormNode;
	onClose: () => void;
	updateCommentsCount: (newCount: number) => void;
}

interface GetSharedUsersResponse {
	sharedUsers: SharedUser[];
}

interface GetSharedTeamMembersResponse {
	users: SharedUser[];
}

const CommentsDialog = ({
	open,
	node,
	onClose,
	updateCommentsCount,
}: CommentsDialogProps) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user, firebaseAuthData } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);
	const [newComment, setNewComment] = useState('');
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [commentToDelete, setCommentToDelete] = useState<string | null>(null);
	const [availableUsers, setAvailableUsers] = useState<SharedUser[]>([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [filteredUsers, setFilteredUsers] = useState<SharedUser[]>([]);
	const [taggedUsers, setTaggedUsers] = useState<SharedUser[]>([]);
	const inputRef = useRef<HTMLInputElement>(null);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const baseUrl = 'https://roboticimaging.ai';

	const fetchUsers = useCallback(async () => {
		try {
			const projectId = project?.id;
			if (!projectId) throw new Error('Project ID is undefined');

			const sharedUsersResponsePromise = getSharedUsers({ projectId });
			const sharedTeamMembersResponsePromise = getSharedTeamMembers({ projectId });
			const staticUsersPromise = fetchStaticUsers();
			const [sharedUsersResponse, sharedTeamMembersResponse, staticUsers] =
				await Promise.all([
					sharedUsersResponsePromise,
					sharedTeamMembersResponsePromise,
					staticUsersPromise,
				]);

			if ('data' in sharedUsersResponse && 'data' in sharedTeamMembersResponse) {
				const sharedUsers: SharedUser[] =
					(sharedUsersResponse.data as GetSharedUsersResponse).sharedUsers ?? [];
				const sharedTeamMembers: SharedUser[] =
					(sharedTeamMembersResponse.data as GetSharedTeamMembersResponse).users ?? [];

				const allUsers: SharedUser[] = [
					...sharedUsers,
					...sharedTeamMembers.filter(
						(teamMember: SharedUser) =>
							!sharedUsers.some(
								(sharedUser: SharedUser) => sharedUser.id === teamMember.id
							)
					),
					...staticUsers,
				].filter(sharedUser => sharedUser.userType !== 'Contractor');

				setAvailableUsers(allUsers);
			} else {
				throw new Error('Invalid response structure from callable functions');
			}
		} catch (error) {
			console.error('Error fetching users: ', error);
		} finally {
			// setLoadingUsers(false);
		}
	}, [project?.id]);

	useEffect(() => {
		if (project?.id) {
			fetchUsers();
		}
	}, [fetchUsers, project?.id]);

	const generateUniqueId = () => {
		return Date.now().toString(36) + Math.random().toString(36).substr(2);
	};

	const renderCommentText = (commentText: string): JSX.Element[] => {
		const regex = /\[([^\]]+)\]/g; // Matches text within []
		const parts = commentText.split(regex);

		return parts.map((part, index) => {
			if (index % 2 === 1) {
				// odd parts are the tagged names
				return (
					<span key={index} style={{ color: '#007AFF' }}>
						{part}
					</span>
				);
			} else {
				return <span key={index}>{part}</span>;
			}
		});
	};

	const handleCommentSubmit = async () => {
		if (!newComment.trim()) return; //make sure comment is not empty
		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
		const itemSnap = await getDoc(itemRef); // get doc snapshot

		// make sure item exists before submitting comment
		if (!itemSnap.exists()) {
			onClose(); // Close the dialog
			setSnackbarProps({
				open: true,
				message: 'Another user deleted this item.',
				severity: 'error',
				hideDuration: 6000,
			});
			return;
		}

		const commentToAdd = {
			id: generateUniqueId(),
			comment: newComment,
			authorName: user?.fullName,
			createdAt: Timestamp.now(),
			authorId: firebaseAuthData?.uid,
			taggedUsers: taggedUsers,
		};

		try {
			await updateDoc(itemRef, { comments: arrayUnion(commentToAdd) })
				.then(() => console.log('Comment added successfully'))
				.catch(error => console.error('Error adding comment:', error));
			setSnackbarProps({
				open: true,
				message: 'Comment submitted successfully!',
				severity: 'success',
				hideDuration: 6000,
			});

			const directLinkUrl = `${baseUrl}/projects/${project?.id}/capture-form-v2/${node.formId}?itemId=${node.id}&commentId=${commentToAdd.id}`;
			const recipientEmails = taggedUsers
				.map(taggedUser => taggedUser.email)
				.filter(email => email);

			if (commentToAdd.taggedUsers && recipientEmails.length > 0) {
				sendEmail({
					destination: recipientEmails.join(','),
					cc: [],
					bcc: [],
					subject: `${commentToAdd.authorName} tagged you in a comment.`,
					text: `${commentToAdd.authorName} tagged you in a comment: 
					${commentToAdd.comment}`,
					html: `
					<div style="font-family: Arial, sans-serif; color: #333; text-align: center; max-width: 600px; margin: 20px auto; padding: 20px; border: 1px solid #ddd; border-radius: 8px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);">
            			<h1 style="font-family: 'Epilogue', Arial, sans-serif; color: #ffb310; font-weight: 400;">Robotic Imaging</h1>
            			<h2 style="font-size: 16px; font-family: 'Epilogue', Arial, sans-serif; font-weight: 400; color: black;">${commentToAdd.authorName} tagged you in a comment.</h2>
            				<div style="text-align: left; background-color: #f9f9f9; padding: 15px; font-family: 'Epilogue', Arial, sans-serif; border-radius: 8px; margin: 20px 0;">
                				<strong>Author:</strong> ${commentToAdd.authorName}<br>
								<strong>Item:</strong> ${node.displayTitle}<br>
                				<strong>Comment:</strong> ${commentToAdd.comment}
            				</div>
            				<a href="${directLinkUrl}" target="_blank" style="display: inline-block; background-color: #ffb310; color: black; padding: 12px 24px; font-family: 'Epilogue', Arial, sans-serif; font-size: 18px; border-radius: 8px; text-decoration: none; font-weight: 400;">
                				View Tagged Comment
            				</a>
        			</div>
					`,
				});
			}
			setNewComment(''); //clear comment input field
			setTaggedUsers([]);
		} catch (error) {
			setSnackbarProps({
				open: true,
				message: 'Error submitting comment. Please try again!',
				severity: 'error',
				hideDuration: 6000,
			});
		}
		const newCommentsCount = (await getDoc(itemRef)).data()?.comments?.length ?? 0;
		updateCommentsCount(newCommentsCount);
	};

	const handleDeleteComment = async (commentId: string) => {
		if (!commentToDelete) return;
		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);

		try {
			const itemSnap = await getDoc(itemRef);

			if (itemSnap.exists()) {
				const updatedComments = itemSnap
					.data()
					.comments.filter((comment: CommentType) => comment.id !== commentId);

				await updateDoc(itemRef, { comments: updatedComments });

				setSnackbarProps({
					open: true,
					message: 'Comment deleted successfully!',
					severity: 'success',
					hideDuration: 6000,
				});
			} else {
				console.log('document does not exist');
			}
		} catch (error) {
			setSnackbarProps({
				open: true,
				message: 'Error deleting comment. Please try again',
				severity: 'error',
				hideDuration: 6000,
			});
		}
		setCommentToDelete(null);

		const newCommentsCount = (await getDoc(itemRef)).data()?.comments?.length ?? 0;
		updateCommentsCount(newCommentsCount);
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			handleCommentSubmit();
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setNewComment(value);

		// Check for tagged user removal
		const currentlyTaggedUserIds = taggedUsers.map(taggedUser => taggedUser.id);
		const remainingTaggedUsers = taggedUsers.filter(taggedUser =>
			value.includes(`[${taggedUser.fullName}]`)
		);
		setTaggedUsers(remainingTaggedUsers);

		const lastTagPos = value.lastIndexOf('@');
		if (lastTagPos > -1) {
			setAnchorEl(inputRef.current);

			const tagPrefix = value.substring(lastTagPos + 1);
			// setTagPrefix(tagPrefix);

			const matchingUsers = availableUsers.filter(
				availableUser =>
					availableUser.fullName.toLowerCase().includes(tagPrefix.toLowerCase()) &&
					!currentlyTaggedUserIds.includes(availableUser.id)
			);

			setFilteredUsers(matchingUsers);

			setShowSuggestions(true);
		} else {
			setShowSuggestions(false);
			setAnchorEl(null);
		}
	};

	const handleTagUser = (userId: string) => {
		const selectedUser = availableUsers.find(
			availableUser => availableUser.id === userId
		);
		if (!selectedUser || taggedUsers.find(taggedUser => taggedUser.id === userId)) return; // Prevent re-tagging if already tagged

		if (inputRef.current) {
			const currentCursorPos = inputRef.current.selectionStart;
			if (currentCursorPos === null) return;

			const tagInsertionPoint = newComment.lastIndexOf('@');
			const taggedFullName = `[${selectedUser.fullName}] `;
			const newCommentText =
				newComment.substring(0, tagInsertionPoint) +
				taggedFullName +
				newComment.substring(currentCursorPos);

			setNewComment(newCommentText);
			setTaggedUsers(prevUsers => [...prevUsers, selectedUser]);
			setFilteredUsers(filteredUsers.filter(filteredUser => filteredUser.id !== userId));
			setShowSuggestions(false);

			setTimeout(() => {
				if (inputRef.current) {
					const newCursorPos = tagInsertionPoint + taggedFullName.length;
					inputRef.current.focus();
					inputRef.current.setSelectionRange(newCursorPos, newCursorPos); // Set cursor position
				}
			}, 0);
		}
	};

	return (
		<>
			<Dialog open={open} onClose={onClose} title="Comments">
				<MessageContainer hasComments={Boolean(node.comments && node.comments.length)}>
					{node.comments &&
						node.comments.length > 0 &&
						node.comments.map((comment, index) => (
							<CommentBubble
								key={index}
								isCurrentUser={comment.authorId === firebaseAuthData?.uid}>
								<p>{renderCommentText(comment.comment)}</p>
								<CommentDetails>
									<span>
										{comment.authorId === firebaseAuthData?.uid
											? 'You'
											: comment.authorName}
									</span>
									<span>{comment.createdAt?.toDate().toLocaleString()}</span>
									{comment.authorId === firebaseAuthData?.uid && (
										<DeleteIconButton
											onClick={() => {
												setCommentToDelete(comment.id);
												setIsConfirmOpen(true);
											}}
											size="small"
											aria-label="delete comment">
											<DeleteIcon fontSize="small" color="primary" />
										</DeleteIconButton>
									)}
								</CommentDetails>
							</CommentBubble>
						))}
				</MessageContainer>
				<InputContainer>
					<StyledTextField
						multiline
						value={newComment}
						inputRef={inputRef}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						variant="outlined"
						autoFocus
						style={{ paddingTop: '20px', position: 'sticky' }}
						inputProps={{
							style: { fontSize: '0.875rem', color: theme.palette.newText.primary },
						}}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleCommentSubmit} edge="end" color="primary">
										<SubmitCommentIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{showSuggestions && (
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}>
							{filteredUsers.map(filteredUser => (
								<UserMenuItem
									key={filteredUser.id}
									onClick={() => handleTagUser(filteredUser.id)}>
									<UsersName>{filteredUser.fullName}</UsersName>
									<UsersEmail>{filteredUser.email}</UsersEmail>
								</UserMenuItem>
							))}
						</Menu>
					)}
				</InputContainer>
			</Dialog>
			<ConfirmDialog
				open={isConfirmOpen}
				handleClose={() => setIsConfirmOpen(false)}
				handleConfirm={() => {
					if (commentToDelete) {
						handleDeleteComment(commentToDelete);
						setCommentToDelete(null);
					}
					setIsConfirmOpen(false);
				}}
				title="Are you sure?"
				label="Are you sure you want to delete your comment? It will be permanently deleted."
			/>
		</>
	);
};

export default CommentsDialog;
