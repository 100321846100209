import { useContext, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import DeliverableContent from './DeliverableContent';
import { FileType } from '../types';
import { SnackContext } from '../../../../context/SnackProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { AuthContext } from '../../../../context/AuthProvider';
import {
	// checkPermissions,
	deliverableNames,
} from '../../../../utils';
import ConfirmDialog from '../../ConfirmDialog';
import { UploadTask } from 'firebase/storage';
import { uploadFile } from '../../../../utils/project-file-upload';
import { downloadUploadedFiles } from '../../../../utils/project-file-download';
import ProjectFilesDialog from './ProjectFilesDialog.tsx';
import LoadingScreen from '../../LoadingScreen';
// import DeliverableIcon from './DeliverableIcon';

export default function DeliverableDropzone({
	children,
	deliverableKey,
	showingDialog,
	setShowingDialog,
	downloadOnly,
	anonUpload = false,
	style = {},
}: {
	children?: React.ReactNode;
	deliverableKey: FileType;
	showingDialog: boolean;
	setShowingDialog: React.Dispatch<React.SetStateAction<boolean>>;
	downloadOnly: boolean;
	anonUpload?: boolean;
	style?: React.CSSProperties;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { project, setProject } = useContext(ProjectContext);
	const { user } = useContext(AuthContext);

	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
	const [fileUploadQueue, setFileUploadQueue] = useState<File[]>([]);
	const [currentUpload, setCurrentUpload] = useState<UploadTask | null>(null);

	const [loadingTitle, setLoadingTitle] = useState('');
	const [loadingDetails, setLoadingDetails] = useState('line1\nline2');

	const [dialog, setDialog] = useState<boolean>(false);
	const [dialogContent, setDialogContent] = useState<JSX.Element>();
	const [dragCounter, setDragCounter] = useState(0);

	const isContractor = !!user?.isContractor;

	const getDialogContent = (files: File[]) => {
		const fileList: Array<JSX.Element> = [];
		files.forEach((file: File) => {
			fileList.push(<li key={file.name}>{file.name}</li>);
		});
		return (
			<Dropzone validator={acceptedFiles} noClick onDrop={onDrop} disabled={downloadOnly}>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<div
						{...getRootProps()}
						style={{
							transition: '0.3s',
							border: isDragActive
								? `dashed 3px ${downloadOnly ? 'red' : 'green'}`
								: 'solid 3px white',
							backgroundColor: isDragActive ? 'lightgrey' : 'white',
						}}
						className="dropzone">
						<p>Upload following files?</p>
						<ul>{fileList}</ul>
						<input
							{...getInputProps()}
							accept={
								deliverableKey === 'pdf'
									? '.pdf,application/pdf,.doc,.docx,application/msword'
									: ''
							}
						/>
					</div>
				)}
			</Dropzone>
		);
	};

	const promptDialog = (acceptedFiles: File[]) => {
		setDialogContent(getDialogContent(acceptedFiles));
		setDialog(true);
	};

	const clearFilesToUpload = () => {
		setFilesToUpload([]);
		setDragCounter(0);
	};

	const handleClose = async (result: boolean) => {
		if (result && deliverableKey !== 'proposal') {
			setFileUploadQueue(prev => [...prev, ...filesToUpload]);
		}
		clearFilesToUpload();
	};

	const onDrop = (
		acceptedFiles: File[],
		fileRejections: FileRejection[],
		event: DropEvent
	) => {
		event.stopPropagation();
		if (fileRejections.length) {
			const rejects = fileRejections.map(x => x.file.name);
			setSnackbarProps({
				open: true,
				message: `${rejects.join(', ')} ${
					fileRejections.length > 1 ? 'have' : 'has'
				} an incorrect file type`,
				severity: 'error',
			});
		} else {
			let totalFiles: File[] = [];
			setFilesToUpload(prev => {
				totalFiles = [...prev, ...acceptedFiles];
				return totalFiles;
			});
			promptDialog(totalFiles);
		}
	};

	const acceptedFiles = (file: File) => {
		const pdfTypes = ['application/pdf', 'application/msword'];
		if (deliverableKey === 'pdf' && !pdfTypes.includes(file.type)) {
			return {
				code: 'incorrect-file-type',
				message: `${file.name} is not a pdf`,
			};
		}
		return null;
	};

	const downloadFiles = async () => {
		if (project) {
			setLoadingTitle(`Downloading ${deliverableNames[deliverableKey]} files...`);

			const fileArr = project[deliverableKey] || [];
			await downloadUploadedFiles(fileArr, setLoadingDetails);

			setLoadingTitle('');
		}
	};

	const uploadFiles = useCallback(
		async (files: File[]) => {
			for (const file of files) {
				setLoadingTitle(`Uploading ${file.name}...`);
				await uploadFile(
					file,
					project?.id as string,
					deliverableKey,
					setCurrentUpload,
					setLoadingDetails,
					setProject,
					undefined,
					anonUpload || isContractor ? 'InProgress' : ''
				);
			}
			setLoadingTitle('');
			setCurrentUpload(null);
		},
		[anonUpload, deliverableKey, isContractor, project?.id, setProject]
	);

	useEffect(() => {
		if (!currentUpload && fileUploadQueue.length) {
			uploadFiles(fileUploadQueue);
			setFileUploadQueue([]);
		}
	}, [currentUpload, fileUploadQueue, fileUploadQueue.length, uploadFiles]);

	return (
		<>
			<Dropzone noClick validator={acceptedFiles} onDrop={onDrop} disabled={downloadOnly}>
				{({ getRootProps, getInputProps, isDragActive, open }) => (
					<Container
						{...getRootProps()}
						style={style}
						onDragEnter={() => setDragCounter(prev => (prev += 1))}
						onDragLeave={() => setDragCounter(prev => (prev -= 1))}
						onMouseLeave={() => setDragCounter(0)}
						tabIndex={-1}
						dragCounter={dragCounter}
						downloadOnly={downloadOnly}>
						{loadingTitle ? (
							<LoadingOverlay>
								<LoadingScreen title={loadingTitle} message={loadingDetails} />
							</LoadingOverlay>
						) : (
							<>
								{children ?? (
									<DeliverableContent
										deliverableKey={deliverableKey}
										downloadOnly={downloadOnly}
										anonUpload={anonUpload}
										setShowingDialog={setShowingDialog}
										promptUpload={open}
										downloadFunction={downloadFiles}
									/>
								)}

								{/* {!checkPermissions(user, 'admin') || downloadOnly ? (
									<DeliverableOverlay
										className="DeliverableOverlay"
										style={{
											opacity: dragCounter !== 0 ? 0 : 1,
											visibility: dragCounter !== 0 ? 'hidden' : 'visible',
										}}>
										<DeliverableIcon deliverableKey={deliverableKey} />
										<span>{deliverableNames[deliverableKey]}</span>
									</DeliverableOverlay>
								) : null} */}
							</>
						)}

						<input
							{...getInputProps()}
							accept={
								deliverableKey === 'pdf'
									? '.pdf,application/pdf,.doc,.docx,application/msword'
									: ''
							}
						/>
					</Container>
				)}
			</Dropzone>

			<ProjectFilesDialog
				deliverableKey={deliverableKey}
				showingDialog={showingDialog}
				setShowingDialog={setShowingDialog}
				downloadOnly={downloadOnly}
				anonUpload={anonUpload}
				downloadFunction={downloadFiles}
				uploadFunction={files => setFileUploadQueue(prev => [...prev, ...files])}
			/>

			<ConfirmDialog
				open={dialog}
				setOpen={setDialog}
				onClose={handleClose}
				title="File Upload"
				content={dialogContent}
			/>
		</>
	);
}

export const Container = styled.div<{
	dragCounter?: number;
	downloadOnly?: boolean;
}>`
	width: calc((100%) / 3);
	height: calc((100%) / 2);

	@media (max-width: 1214px) {
		width: 50%;
		height: 33.5%;
	}
	@media (max-width: 1023px) {
		width: 100%;
		min-height: 200px;
	}

	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	text-align: center;
	border-top: ${({ downloadOnly }) => (downloadOnly ? '2px solid #ffb310' : 'none')};
	border-left: 2px solid #ffb310;
	outline: 2px solid #ffb310;
	border-bottom: 2px solid #ffb310;
	border-right: 2px solid #ffb310;
	background-color: ${({ dragCounter, downloadOnly }) =>
		dragCounter && !downloadOnly ? 'rgb(40, 40, 40)' : 'black'};

	transition: 0.3s;

	&:hover {
		& > .DeliverableOverlay {
			opacity: 0 !important;
			visibility: hidden !important;
		}
	}
`;

const LoadingOverlay = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	> * {
		line-height: normal;
		max-width: 100%;
		overflow-wrap: anywhere;
		white-space: pre-line;
	}
`;
// const DeliverableOverlay = styled.div`
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	flex-direction: column;

// 	width: 100%;
// 	height: 100%;
// 	position: absolute;
// 	background-color: rgba(0, 0, 0, 0.9);
// 	z-index: 9;

// 	visibility: visble;
// 	opacity: 1;
// 	transition: visibility 0.1s, opacity 0.1s linear;
// `;
