import styled from 'styled-components';
import { steps } from './utils/utils';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import DoneAllIcon from '@mui/icons-material/DoneAll';

interface IconProps {
	step: number;
	$nav: boolean;
}

export const MainContainer = styled.div`
	width: 100%;
	max-width: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const StepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;

	&:last-child {
		> div {
			margin-right: 0px;
		}
	}
`;

/* Styles for the tracking not available label */
export const TrackingNotAvailableLabel = styled.span`
	font-size: 15px;
	color: white;
	display: flex;
	text-align: center;
	@media (max-width: 600px) {
		font-size: 16px;
	}
`;

export const StatusLabel = styled.span`
	font-family: 'Epilogue';
	font-size: 15px;
	font-weight: 300;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 10px;
`;

export const StepStyle = styled.div<{ step: string }>`
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background-color: black;
	border: 3px solid ${({ step }) => (step === 'completed' ? '#5BC236' : '#F3E7F3')};
	transition: 0.4s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-right: 30px;
	transition: width 1s ease;

	@media (max-width: 500px) {
		width: 40px;
		height: 40px;
	}
	@media (max-width: 470px) {
		width: 35px;
		height: 35px;
		margin-right: 20px;
	}
`;

export const Frame = styled.div<{ step: number; nav: boolean }>`
	width: ${({ nav }) => (nav ? '369px' : '480px')};
	height: ${({ nav }) => (nav ? '50px' : '100px')};
	margin-left: 10px;
	border: 1px solid;
	border-color: ${({ step }) => (step >= 8 ? 'black' : 'white')};
	border-radius: ${({ nav }) => (nav ? '30px' : '40px')};
	display: grid;
	grid-template-columns: repeat(8, auto);
	gap: 1px;
	background-color: ${({ step }) => (step >= 8 ? 'black' : 'white')};
	transition: background-color, border-color;
	transition-delay: 4s;
	position: relative;
	box-sizing: border-box;

	@media (max-width: 500px) {
		width: ${({ nav }) => (nav ? '385px' : '480px')};
	}

	&:hover::after {
		content: attr(content);
		position: absolute;
		top: -35px;
		left: 50%;
		transform: translateX(-50%);
		padding: 5px 10px;
		color: white;
		background-color: black;
		border-radius: 6px;
		font-size: 14px;
		white-space: nowrap;
		z-index: 1000;
		display: block;
		min-width: 120px;
		text-align: center;
	}
`;

export const Block = styled.div<{ step: number; nav: boolean }>`
	width: ${({ nav }) => (nav ? '80px' : '75px')};
	height: '100%';
	transition: all 1s ease 1s;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;

	:nth-child(1) {
		clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
		border-bottom-left-radius: 40px;
		border-top-left-radius: 40px;
		justify-content: flex-start;
		padding-left: ${({ nav }) => (nav ? '20px' : '40px')};
		background-color: ${({ step }) => (step >= 1 ? '#5bc236' : 'black')};

		@media (max-width: 500px) {
			padding-left: 20px;
		}
	}
	:nth-child(2) {
		clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
		transform: translateX(-50%);
		background-color: ${({ step }) => (step >= 2 ? '#5bc236' : 'black')};
		transition-delay: 0.5s;
	}
	:nth-child(3) {
		clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
		transform: translateX(-100%);
		background-color: ${({ step }) => (step >= 3 ? '#5bc236' : 'black')};
		transition-delay: 1s;
	}
	:nth-child(4) {
		clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
		transform: translateX(-150%);
		background-color: ${({ step }) => (step >= 4 ? '#5bc236' : 'black')};
		transition-delay: 1.5s;
	}
	:nth-child(5) {
		clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
		transform: translateX(-200%);
		background-color: ${({ step }) => (step >= 5 ? '#5bc236' : 'black')};
		transition-delay: 2s;
	}
	:nth-child(6) {
		clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
		transform: translateX(-250%);
		background-color: ${({ step }) => (step >= 6 ? '#5bc236' : 'black')};
		transition-delay: 2.5s;
	}
	:nth-child(7) {
		clip-path: polygon(50% 0, 100% 0, 50% 100%, 0% 100%);
		transform: translateX(-300%);
		background-color: ${({ step }) => (step >= 7 ? '#5bc236' : 'black')};
		transition-delay: 3s;
	}
	:nth-child(8) {
		clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
		border-bottom-right-radius: 40px;
		border-top-right-radius: 40px;
		transform: translateX(-350%);
		justify-content: flex-end;
		padding-right: ${({ nav }) => (nav ? '20px' : '40px')};
		background-color: ${({ step }) => (step >= 8 ? '#5bc236' : 'black')};
		transition-delay: 3.5s;

		@media (max-width: 500px) {
			padding-right: 20px;
		}
	}

	@media (max-width: 500px) {
		width: 83.5px;
	}
`;

export const StepContainer = styled.div<{ width: string; step: number }>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		background: white;
		height: 4px;
		width: 300px;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		@media (max-width: 470px) {
			width: 250px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		background: #5bc236;
		height: 4px;
		width: ${props => (props.width ? props.width : 0)};

		top: 50%;
		transition: 1s ease;
		transform: translateY(-50%);
		left: 0;
		@media (max-width: 470px) {
			width: ${props => (props.step - 1) * (250 / (steps.length - 1))}px;
		}
	}
`;

export const BidPendingIcon = styled.img<{ step: number; $nav: boolean }>`
	filter: ${({ step }) => (step >= 1 ? 'invert(1)' : 'none')};
	transition: filter;
	transition-delay: 0.2s;
	width: ${({ $nav }) => ($nav ? '16px' : '32px')};
`;

export const BidSentIcon = styled(ScheduleSendIcon)<{ step: number; $nav: boolean }>`
	color: ${({ step }) => (step >= 2 ? 'black' : 'white')};
	transition: color;
	transition-delay: 1s;
	width: ${({ $nav }) => ($nav ? '16px' : '32px')};
	margin-bottom: 2px;
`;

export const ToBeScheduledIcon = styled.img<IconProps>`
	filter: ${({ step }) => (step >= 3 ? 'invert(1)' : 'none')};
	transition: filter;
	transition-delay: 1.5s;
	width: ${({ $nav }) => ($nav ? '16px' : '32px')};
`;

export const OnSiteScheduledIcon = styled.img<IconProps>`
	filter: ${({ step }) => (step >= 4 ? 'invert(1)' : 'none')};
	transition: filter;
	transition-delay: 2s;
	width: ${({ $nav }) => ($nav ? '16px' : '32px')};
`;

export const CaptureCompleteIcon = styled.img<IconProps>`
	filter: ${({ step }) => (step >= 5 ? 'invert(1)' : 'none')};
	transition: filter;
	transition-delay: 2.5s;
	width: ${({ $nav }) => ($nav ? '16px' : '32px')};
`;

export const RegistrationIcon = styled.img<IconProps>`
	filter: ${({ step }) => (step >= 6 ? 'invert(1)' : 'none')};
	transition: filter;
	transition-delay: 3s;
	width: ${({ $nav }) => ($nav ? '16px' : '32px')};
`;

export const ModelingInProgressIcon = styled.img<IconProps>`
	filter: ${({ step }) => (step >= 7 ? 'invert(1)' : 'none')};
	transition: filter;
	transition-delay: 3.5s;
	width: ${({ $nav }) => ($nav ? '16px' : '32px')};
`;

export const Icon8 = styled(DoneAllIcon)<IconProps>`
	color: ${({ step }) => (step >= 8 ? 'black' : 'white')};
	transition: color;
	transition-delay: 4s;
	width: ${({ $nav }) => ($nav ? '16px' : '32px')};
`;
