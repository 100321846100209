import { Select, MenuItem, styled } from '@mui/material';

export const StyledSelect = styled(Select)`
	background-color: rgb(18, 18, 18);

	&.Mui-focused fieldset,
	fieldset {
		border-color: #242424;
	}

	&:hover:not(.Mui-focused) {
		fieldset {
			border-color: #fff;
		}
	}

	&[aria-checked='true'] {
		background-color: 'blue';
	}

	.MuiSvgIcon-root {
		fill: #838383;
	}
`;

export const StyledMenuItem = styled(MenuItem)`
	color: #fbfbfb;
	background-color: rgb(18, 18, 18);

	&.Mui-selected,
	&:hover {
		background-color: rgba(144, 202, 249, 0.16);
	}
`;
